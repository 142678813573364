import { connect } from "react-redux";
import "./style.css";
import React from "react";
import {useTranslation} from "react-i18next";
const SearchSection = (props) => {
    const { content = {}, handleSetSearchParams } = props;
    const {t} = useTranslation()
    return (
        <div className="search__container">
            <input type="text" placeholder={t('search')} onChange={handleSetSearchParams}/>
        </div>
    );
};
export default connect(null, null)(SearchSection);
