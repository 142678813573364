import React, {memo, useEffect} from 'react';
import styles from "./toolbox.module.css"
import {decimalToDMS} from "./utils";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const Toolbox = ({height, coords, scale, className, attachTimeline}) => {
    const {t} = useTranslation()
    const isShow = !!height || !!coords || !!scale;
    const [bottom, setBottom] = React.useState(0);

    useEffect(() => {
        let timelineNode = document.getElementById("mapSlider");
        const timelineHeight = timelineNode?.offsetHeight;
        const OFFSET = 6;

        if (attachTimeline) {
            if (!timelineNode) {
                timelineNode = document.getElementsByClassName("timeline")[0];
            }

            const resizeObserver = new ResizeObserver((e) => {
                const timelineHeight = e[0]?.target.offsetHeight;
                setBottom(timelineHeight + OFFSET);
            });
            if (!timelineNode) return;

            resizeObserver.observe(timelineNode);
            return () => resizeObserver.disconnect();
        }
        setBottom(timelineHeight + OFFSET);

    }, [attachTimeline]);


    if (!isShow) {
        return null;
    }

    return (
        <div className={`${styles.toolboxContainer} ${className || ''}`} style={{bottom}}>
            {
                scale &&
                <div title="Մասշտաբ" className={`${styles.toolboxItem} ${styles.toolboxScaleItem}`}>
                    <span>{scale}</span>
                </div>
            }
            {
                coords &&
                <div title="Կորդինատներ" className={styles.toolboxItem}>
                    <span>{decimalToDMS(coords.lng)}N</span>
                    <span>{decimalToDMS(coords.lat)}E</span>
                </div>
            }
            {
                height !== undefined &&
                <div title="Բարձրություն" className={styles.toolboxItem}>{height} {t ('m')}</div>
            }
        </div>
    );
};

Toolbox.prototype = {
    height: PropTypes.number,
    coords: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    scale: PropTypes.number,
    className: PropTypes.string,
}

export default memo(Toolbox);
