import {
  SET_LESSONS,
  LOADING_LESSONS,
  SET_LESSONS_TITLE,
  SET_LESSON_DATA,
  SET_LESSON_NAVIGATION_DATA
} from "../constants";

const initialState = {
  data: [],
  isLoading: false,
  navigation : [],
  lessonTitle: "",
  lessonData:[]
};

export const lessonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_LESSONS: {
      return { ...state, isLoading: true };
    }
    case SET_LESSONS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case SET_LESSON_NAVIGATION_DATA:
      return {
        ...state,
        navigation: action.payload,
      };
    case SET_LESSONS_TITLE:
      return {
        ...state,
        lessonTitle: action.payload,
      };
       case SET_LESSON_DATA:
        return {
          ...state,
          lessonData: action.payload,
        };
    default:
      return state;
  }
};
