import React, {useEffect} from 'react';
import './style.css';
import globeVideo from '../../assets/videos/globe.mp4';

const UnderConstruction = (props) => {
    const {onAccessPassed} = props;

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                (event.ctrlKey || event.metaKey) && // Check for Ctrl or Meta (Command) key
                event.shiftKey &&
                event.altKey && // or option on Mac
                event.code === 'KeyB'
            ) {
                localStorage.setItem('acc_date', new Date().toDateString());
                onAccessPassed?.(new Date().toDateString());
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="under-construction--container">
            <video src={globeVideo} autoPlay loop muted className="under-construction--video"/>
             <h1>Կատարվում են տեխնիկական աշխատանքներ։ Խնդրում ենք փորձել մի փոքր ուշ</h1>
        </div>
    );
};

export default UnderConstruction;
