import {useEffect, useState} from "react";
import ReactDOM from "react-dom";

const CustomPortal = ({children,cssText}) => {
    const [container] = useState(()=>document.createElement('div'))
    container.style.cssText = 'z-index:1;position:fixed;' + cssText

    useEffect(()=>{
        document.querySelector('#root').appendChild(container)

        return ()=> document.querySelector('#root').removeChild(container)

    },[])

    return ReactDOM.createPortal(children,container)
}
export default CustomPortal;
