import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import * as turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import {isMobile} from "react-device-detect";

import CustomCursor from "../components/CustomCursor";
import CustomPortal from "../components/CustomCursor/CustomPortal";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import EraserIcon from "../../../assets/imgs/PaintBar/eraser-icon.svg";
import {DRAWER_TOOLBOX_LAYER, ERASER_TOOLBOX_LAYER, MINIMAL_COORDINATES_LENGTH_COUNT} from "../DrawerToolbox/constants";
import toolboxStatesWithRedux from "../mapper";


const EraserToolbox = (props) => {
    const {
        getPainterStartData,
        setDrawerToolboxClickedState,
        setPainterGeoJsonDataST,
        setCommentToolboxState,
        setLineDrawToolboxState,
        setRulerClickedState,
        setEraserClickedState,
        setStickyNotesClickedState,
        setPolygonDrawToolboxState,
        setTextToolboxState,
        dispatchUndoRedoData,
        getEraserClickedState,
        map,
    } = props

    const radiusOfCircle = useRef(40);
    const lastMousePositionRef = useRef(null); // Store the last mouse position
    const [isVisibleCursor, setIsVisibleCursor] = useState(false);

    const getDrawingSource = () => {
        return map.getSource(DRAWER_TOOLBOX_LAYER);
    };

    const getEraserSource = () => {
        return map.getSource(ERASER_TOOLBOX_LAYER);
    };

    const mouseMoveEraserHandler = useCallback((e) => {
        const { lng, lat } = e.lngLat;

        const currentPosition = map.project([lng, lat]);

        // Check the last mouse position and calculate the distance
        if (lastMousePositionRef.current) {
            const { x: lastX, y: lastY } = lastMousePositionRef.current;
            const deltaX = currentPosition.x - lastX;
            const deltaY = currentPosition.y - lastY;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

            // If the mouse has moved less than 10 pixels, return early
            if (distance < 7) return;
        }

        // Update the last mouse position
        lastMousePositionRef.current = currentPosition;

        // create threshold for the event to be handled
        if (Math.abs(lng) > 180 || Math.abs(lat) > 90) return

        const sizeInPixels = radiusOfCircle.current; // Adjust this size as needed

        const centerMercator = map.project([lng, lat]);
        const nwMercator = [centerMercator.x - sizeInPixels / 2, centerMercator.y - sizeInPixels / 2];
        const seMercator = [centerMercator.x + sizeInPixels / 2, centerMercator.y + sizeInPixels / 2];

        const nw = map.unproject(nwMercator);
        const se = map.unproject(seMercator);
        const radiusLngLat = [(nw.lng + se.lng) / 2, nw.lat];
        const radius = turf.distance([lng, lat], radiusLngLat, { units: 'meters' });

        const circleOptions = {
            steps: 64,
            units: 'meters',
        };

        // Create or update the circle polygon used for erasing
        if (!circleFeatureRef.current) {
            circleFeatureRef.current = turf.circle([lng, lat], radius, circleOptions);
        } else {
            circleFeatureRef.current.geometry.coordinates = turf.circle([lng, lat], radius, circleOptions).geometry.coordinates;
        }

        const drawingSource = getDrawingSource();
        const linesSource = drawingSource._data;
        const newLineFeatures = [];

        // Query features in the bounding box defined by the circle's extents
        const intersectingFeatures = map.queryRenderedFeatures([nwMercator, seMercator], {
            layers: [DRAWER_TOOLBOX_LAYER],
        });

        if (!intersectingFeatures.length) {
            return;
        }

        // Loop through each line feature and check for intersections with the circle
        linesSource.features.forEach((lineFeature) => {
            if (lineFeature.geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) return;

            const currentLine = turf.lineString(lineFeature.geometry.coordinates, lineFeature.properties);

            // Check if the line intersects with the circle polygon
            if (!turf.booleanDisjoint(currentLine, circleFeatureRef.current)) {
                // Split the line into segments where it intersects with the circle
                const splitLines = turf.lineSplit(currentLine, circleFeatureRef.current);

                // Process each split line
                splitLines.features.forEach((splitLine) => {
                    // Only keep parts of the line that are outside the circle polygon
                    if (!turf.booleanWithin(splitLine, circleFeatureRef.current)) {
                        if (splitLine.geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) return;
                        splitLine.id = uuidv4();
                        splitLine.properties = { ...lineFeature.properties, id: splitLine.id };
                        newLineFeatures.push(splitLine);
                    }
                });
            } else {
                // If the line does not intersect, keep it as is
                newLineFeatures.push(lineFeature);
            }
        });

        // Update the drawing source with the new set of line features
        drawingSource.setData(turf.featureCollection(newLineFeatures));
    }, [radiusOfCircle.current]);


    const mouseDownEraserHandler = (e) => {
        map.on('mouseout', documentOverHandler);
        map.on(isMobile ? 'touchmove' : 'mousemove', mouseMoveEraserHandler)
        setIsVisibleCursor(true);
    }
    const mouseUpEraserHandler = (e) => {
        map.off('mouseout', documentOverHandler);
        map.off(isMobile ? 'touchmove' : 'mousemove', mouseMoveEraserHandler)
        setPainterGeoJsonDataST({...getDrawingSource()._data});
        dispatchUndoRedoData({...JSON.parse(JSON.stringify(getDrawingSource()._data))});
        setIsVisibleCursor(false);
    }

    const mouseDownEraserHandlerRef = useRef(mouseDownEraserHandler); // function referral link
    const mouseUpEraserHandlerRef = useRef(mouseUpEraserHandler); // function referral link
    const circleFeatureRef = useRef(null);

    const handleIconClick = () => {
        setEraserClickedState(!getEraserClickedState);
        setDrawerToolboxClickedState(false);
        setStickyNotesClickedState(false);
        setLineDrawToolboxState(false);
        setPolygonDrawToolboxState(false);
        setCommentToolboxState(false);
        setRulerClickedState(false);
        setTextToolboxState(false)
    };

    const documentOverHandler = useCallback(() => {
        function removeListenerFromDocument(){
            document.removeEventListener('mouseup', offMouseOverFunctionOfMap)
        }
        function offMouseOverFunctionOfMap () {
            map.off('mouseover', removeListenerFromDocument)
            mouseUpEraserHandlerRef.current()
        }
        document.addEventListener('mouseup', offMouseOverFunctionOfMap,{ once: true });
        map.once('mouseover', removeListenerFromDocument)
    }, []);

    useEffect(() => {
        if (getEraserClickedState && getPainterStartData) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.ERASER);
                map["dragPan"].disable();
            }, 50);
            // touchstart
            // touchmove
            // touchend
            map.on(isMobile ? 'touchstart' : 'mousedown', mouseDownEraserHandlerRef.current);
            map.on(isMobile ? 'touchend' : 'mouseup', mouseUpEraserHandlerRef.current);
        } else {
            changeCursorIcon();
            map["dragPan"].enable();
            map.off(isMobile ? 'touchstart' : 'mousedown', mouseDownEraserHandlerRef.current);
            map.off(isMobile ? 'touchend' : 'mouseup', mouseUpEraserHandlerRef.current);

            if(getEraserSource()) {
                getEraserSource().setData({
                    type: 'FeatureCollection',
                    features: []
                });
            }

            setEraserClickedState(false);
        }
    }, [getEraserClickedState, getPainterStartData]);

    return (
        <>
            <div
                onClick={handleIconClick}
                className={`pain_items ${getEraserClickedState ? "button_active" : ""}`}
            >
                <img src={EraserIcon} alt="" className="icon_img"/>
            </div>
            {isMobile && getEraserClickedState && getPainterStartData && isVisibleCursor &&
                <CustomPortal cssText={'z-index: 0; position:fixed; top:0; left:0; width:100vw; height:100vh; pointer-events:none;'}>
                    <CustomCursor type={'eraser-cursor'}/>
                </CustomPortal>}
        </>
    );
};
export default toolboxStatesWithRedux(memo(EraserToolbox));
