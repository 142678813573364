import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";
import Slider from "@mui/material/Slider";
import {
  getArticlesData,
  getAudioPlaying,
  getIsArticlesLoading,
} from "../../store/selectors";
import { connect } from "react-redux";
import back from "../../assets/imgs/audio-back-icon.svg";
import forward from "../../assets/imgs/audio-forward-icon.svg";

import { setAudioPlayingFunc } from "../../store/actions/audio";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: this.props?.saveData?.playing || false,
      currentTime: this.props?.saveData?.currentTime || 0,
      duration: 0,
      volume: this.props?.saveData?.volume || 1,
      volumeToggle: this.props?.saveData?.volumeToggle || false,
      isReady: false,
    };

    this.audio = React.createRef(<div/>);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.getAudioPlaying && !this.audio.audioEl.current.paused) {
      this.pause();
    }
  }

  componentDidMount() {
    // this.setState({
    //   currentTime: this.props?.saveData?.currentTime,
    // });
    // const index = this.props.articleData.findIndex((article) => article.show);
    // this.rendArticleAudio(index)
    if (this.props?.saveData?.currentTime) {
      this.audio.audioEl.current.currentTime =
        this.props?.saveData?.currentTime;
    }
  }

  rendArticleAudio(index) {
    // setTimeout(() => {
      if (this.props.articleData[index]) {
        this.audio = new Audio(
          this.props.articleData[index]?.gallery?.find(
            (x) => x.type === 4
          )?.path
        );
      }

    // }, 300);
  }

  play = () => {
    this.setState({ playing: true });
    this.audio.audioEl.current.play();
    this.props.setAudioPlayingFunc(true);
  };
  next = () => {
    if (
      this.audio.audioEl.current.duration <
      this.audio.audioEl.current.currentTime + 15
    ) {
      this.audio.audioEl.current.currentTime = Math.floor(
        this.audio.audioEl.current.duration
      );
      this.setState({
        currentTime: this.audio.audioEl.current.duration,
      });
      this.pause();
      return;
    }
    this.setState((prev) => {
      return {
        ...prev,
        currentTime: prev.currentTime + 15,
      };
    });
    this.audio.audioEl.current.currentTime += 15;
  };
  prev = () => {
    if (0 >= this.audio.audioEl.current.currentTime - 15) {
      this.audio.audioEl.current.currentTime = 0;
      this.setState({
        currentTime: 0,
      });
      return;
    }
    this.setState((prev) => {
      return {
        ...prev,
        currentTime: prev.currentTime - 15,
      };
    });
    this.audio.audioEl.current.currentTime -= 15;
  };
  pause = () => {
    this.setState({ playing: false });
    this.audio.audioEl.current.pause();
  };

  handleLoadMetadata = (meta) => {
    const { duration } = meta.target;
    this.setState({ duration: duration, isReady:true });
  };

  render() {
    const item = this.props.articleData.find((article) => article.show);

    return (
      <div className="hero__audio">
        <ReactAudioPlayer
          ref={(element) => {
            this.audio = element;
          }}
          src={item?.articleData?.gallery?.find((x) => x.type === 4)?.path}
          volume={this.state.volume}
          listenInterval={1000}
          onListen={(e) => {
            this.props.onChangePlayer("currentTime", e);
            this.setState({
              currentTime: e,
            });
          }}
          onLoadedMetadata={(e) => this.handleLoadMetadata(e)}
        />
        <button onClick={() => this.prev()}
                disabled={!this.state.isReady}>
          <img src={back} alt="back" />
        </button>
        <button
          className="hero__audio-play"
          type="button"
          disabled={!this.state.isReady}
          onClick={() => {
            if (this.state.playing) {
              this.props.onChangePlayer("playing", false);
              this.pause();
            } else {
              this.props.onChangePlayer("playing", true);
              this.play();
            }
            // this.state.playing ? this.pause() : this.play();
          }}
        >
          {this.state.playing ? (
            // <svg
            //     width="24px"
            //     height="24px"
            //     viewBox="0 0 24 24"
            //     version="1.1"
            //     xmlns="http://www.w3.org/2000/svg"
            // >
            //     <g
            //         id="player-pausey-passive"
            //         stroke="none"
            //         strokeWidth="1"
            //         fill="none"
            //         fillRule="evenodd"
            //     ><circle
            //             id="Oval-3"
            //             stroke="#FFFFFF"
            //             opacity="0.3"
            //             cx="11.8099492"
            //             cy="11.8099492"
            //             r="11.8099492"
            //         ></circle>
            //         <path
            //             d="M10.0377554,7.63809693 C10.5844008,7.63809693 11.0275445,8.02722232 11.0275445,8.50723282 L11.0275445,15.1126656 C11.0275445,15.5926761 10.5844008,15.9818015 10.0377554,15.9818015 C9.49110994,15.9818015 9.04796626,15.5926761 9.04796626,15.1126656 L9.04796626,8.50723282 C9.04796626,8.02722232 9.49110994,7.63809693 10.0377554,7.63809693 Z M13.5821431,7.63809693 C14.1287885,7.63809693 14.5719322,8.02722232 14.5719322,8.50723282 L14.5719322,15.1126656 C14.5719322,15.5926761 14.1287885,15.9818015 13.5821431,15.9818015 C13.0354976,15.9818015 12.5923539,15.5926761 12.5923539,15.1126656 L12.5923539,8.50723282 C12.5923539,8.02722232 13.0354976,7.63809693 13.5821431,7.63809693 Z"
            //             id="Pause"
            //             fill="#FFFFFF"
            //         ></path>
            //     </g>
            // </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116px"
              height="115px"
              viewBox="0 0 116 115"
              version="1.1"
            >
              <g
                xmlns="http://www.w3.org/2000/svg"
                id="player-pausey-passive"
                transform="translate(50, 48 )"
                fill="#2C476C"
                fillRule="nonzero"
              >
                <path
                  d="M6,2.09375036 C6,0.593750307 5.55882347,0 4.4338236,0 L1.5661764,0 C0.441176532,0 0,0.593750307 0,2.09375036 L0,17.9062496 C0,19.4062497 0.441176532,20 1.5661764,20 L4.41176427,20 C5.55882294,20 6,19.4062497 6,17.9062496 L6,2.09375036 Z"
                  id="Vector"
                />
                <path
                  d="M18,2.09375036 C18,0.593750307 17.5571955,0 16.4280442,0 L13.5719558,0 C12.4428045,0 12,0.593750307 12,2.09375036 L12,17.9062496 C12,19.4062497 12.4428045,20 13.5719558,20 L16.4280442,20 C17.5571955,20 18,19.4062497 18,17.9062496 L18,2.09375036 Z"
                  id="Vector"
                />
              </g>
            </svg>
          ) : (
            <>
              {/*<svg*/}
              {/*    className="audio-pause paused"*/}
              {/*    version="1.1"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*    <rect x="0" y="0" width="4" height="12" />*/}
              {/*    <rect x="6" y="0" width="4" height="12" />*/}
              {/*</svg>*/}
              {/*<svg className="audio-play" viewBox="0 0 100 100">*/}
              {/*    <polygon points="20 100, 100 50, 20 0" />*/}
              {/*</svg>*/}
              <svg
                className="audio-pause"
                xmlns="http://www.w3.org/2000/svg"
                width="116px"
                height="115px"
                viewBox="0 0 116 115"
                version="1.1"
              >
                <title>player-play-passive</title>
                <defs>
                  <filter
                    x="-5.2%"
                    y="-6.1%"
                    width="110.4%"
                    height="112.2%"
                    filterUnits="objectBoundingBox"
                    id="filter-1"
                  >
                    <feOffset
                      dx="0"
                      dy="7"
                      in="SourceAlpha"
                      result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                      stdDeviation="13"
                      in="shadowOffsetOuter1"
                      result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                      values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.1 0"
                      type="matrix"
                      in="shadowBlurOuter1"
                      result="shadowMatrixOuter1"
                    />
                    <feMerge>
                      <feMergeNode in="shadowMatrixOuter1" />
                      <feMergeNode in="SourceGraphic" />
                    </feMerge>
                  </filter>
                </defs>
                <g
                  xmlns="http://www.w3.org/2000/svg"
                  id="player-play-passive"
                  transform="translate(26, 25)"
                >
                  <path
                    d="M42.2138694,33.5742794 L27.2400318,42.1746748 C26.6447449,42.5165846 25.8807197,42.3185155 25.5335339,41.7322749 C25.4222483,41.5443638 25.3636101,41.3307275 25.3636101,41.1131924 L25.3636101,23.9124015 C25.3636101,23.2337408 25.9222629,22.6835776 26.6113961,22.6835776 C26.832288,22.6835776 27.0492209,22.7413247 27.2400318,22.8509191 L42.2138694,31.4513145 C42.8091563,31.7932244 43.0102819,32.5456389 42.663096,33.1318795 C42.5546205,33.3150458 42.3998622,33.4674523 42.2138694,33.5742794 Z"
                    id="Play"
                    fill="#2C476C"
                  />
                </g>
              </svg>
            </>
          )}
        </button>
        <button onClick={() => this.next()}
                disabled={!this.state.isReady}>
          <img src={forward} alt="back" />
        </button>
        <span className="hero__audio-currtime">
          {new Date(this.state.currentTime * 1000).toISOString().substr(14, 5)}
        </span>
        <Slider
          disabled={!this.state.isReady}
          className="hero__audio-track"
          size="small"
          sx={{
            color: "#EB572C",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 8,
              height: 8,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&:before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
              },
              "&.Mui-active": {
                width: 20,
                height: 20,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
              color: "#2C476C",
            },
          }}
          min={0}
          max={this.state.duration}
          value={this.state.currentTime}
          step={1}
          onChange={(_, value) => {
            this.props.onChangePlayer("currentTime", value);

            this.setState({
              currentTime: value,
            });
            this.audio.audioEl.current.currentTime = value;
          }}
        />
        <span className="hero__audio-duration">
          {new Date(this.state.duration * 1000).toISOString().substr(14, 5)}
        </span>
        <div className="hero__audio-volume" tabIndex="0">
          <button
            className="volume-btn"
            type="button"
            onClick={() => {
              this.props.onChangePlayer(
                "volumeToggle",
                !this.state.volumeToggle
              );

              this.setState({
                volumeToggle: !this.state.volumeToggle,
              });
            }}
          >
            {this.state.volume > 0 ? (
              this.state.volume > 0.5 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                >
                  <g
                    id="Group_3026"
                    data-name="Group 3026"
                    transform="translate(17156 11641)"
                  >
                    <rect
                      id="Rectangle_1782"
                      data-name="Rectangle 1782"
                      width="30"
                      height="26"
                      transform="translate(-17156 -11641)"
                      fill="none"
                    />
                    <g id="Page-1" transform="translate(-17156 -11640)">
                      <g id="pop-up" transform="translate(-3761 -1109)">
                        <g id="volume-high" transform="translate(3761 1109)">
                          <path
                            id="Shape"
                            d="M0,7.5H5.964l7.952-6.1V21.737l-7.952-6.1H0Zm29.822,4.068A16.46,16.46,0,0,1,25.1,23.135l-1.988-2.161a13.38,13.38,0,0,0,3.728-9.407,13.38,13.38,0,0,0-3.728-9.407L25.1,0a16.46,16.46,0,0,1,4.722,11.568Zm-6.958,0a9.261,9.261,0,0,1-2.61,6.483l-2.112-2.161a6.239,6.239,0,0,0,0-8.644l2.112-2.161a9.261,9.261,0,0,1,2.61,6.483Z"
                            fill="#2c476c"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                >
                  <g
                    id="Group_3027"
                    data-name="Group 3027"
                    transform="translate(17114 11641)"
                  >
                    <rect
                      id="Rectangle_1783"
                      data-name="Rectangle 1783"
                      width="30"
                      height="26"
                      transform="translate(-17114 -11641)"
                      fill="none"
                    />
                    <g id="Page-1" transform="translate(-17110 -11639.398)">
                      <g id="pop-up" transform="translate(-3761 -1109)">
                        <g id="volume-high" transform="translate(3761 1109)">
                          <path
                            id="Shape"
                            d="M0,7.5H5.964l7.952-6.1V21.737l-7.952-6.1H0Zm22.863,4.068a9.261,9.261,0,0,1-2.61,6.483l-2.112-2.161a6.239,6.239,0,0,0,0-8.644l2.112-2.161a9.261,9.261,0,0,1,2.61,6.483Z"
                            fill="#2c476c"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="21px"
                viewBox="0 0 15 21"
                version="1.1"
              >
                <title>Path</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Artboard"
                    transform="translate(-2095.000000, -1676.000000)"
                    fill="#2C476C"
                    fillRule="nonzero"
                  >
                    <g
                      id="Group-7"
                      transform="translate(964.397476, 1599.173317)"
                    >
                      <g
                        id="player-sound-active"
                        transform="translate(1130.725552, 77.358438)"
                      >
                        <polygon
                          id="Shape"
                          points="0 6.10152717 5.96432187 6.10152717 13.916751 0 13.916751 20.3384239 5.96432187 14.2368967 0 14.2368967"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </button>
          {this.state.volumeToggle ? (
            <div className={"volume-track"}>
              <Slider
                size="small"
                sx={{
                  color: "#2C476C",
                  "& .MuiSlider-thumb": {
                    width: 8,
                    height: 8,
                    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                    "&:before": {
                      boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: `0px 0px 0px 8px ${"rgb(255 255 255 / 16%)"}`,
                    },
                    "&.Mui-active": {
                      width: 15,
                      height: 15,
                    },
                  },
                  "& .MuiSlider-rail": {
                    opacity: 0.28,
                  },
                }}
                orientation="vertical"
                min={0}
                max={1}
                step={0.1}
                value={this.state.volume}
                onChange={(_, value) => {
                  this.props.onChangePlayer("volume", value);
                  this.setState({
                    volume: value,
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => ({
  articleData: getArticlesData(state),
  articleLoading: getIsArticlesLoading(state),
  getAudioPlaying: getAudioPlaying(state),
});

const mapDispatchToProps = {
  setAudioPlayingFunc,
};

export default connect(mapStateTopProps, mapDispatchToProps)(AudioPlayer);
