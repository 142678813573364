import React, { memo } from 'react';
import PrevIcon from "../../../../assets/imgs/PaintBar/prev-icon.png";
import toolboxStatesWithRedux from "../../mapper";
import useUndoRedo from '../useUndoRedo'; // Import the custom hook

const UndoToolbox = (props) => {
    const {
        getSelectedToolUndoRedo,
        getUndoRedoData,
        setUndoRedoData,
        map
    } = props;

    const { currentUndoRedoSelectedComponent, updateSourceData, setGeoJsonData } = useUndoRedo(
        getSelectedToolUndoRedo,
        getUndoRedoData,
        setUndoRedoData,
        map
    );

    const handleUndo = () => {
        if (!currentUndoRedoSelectedComponent.current || currentUndoRedoSelectedComponent.current.index <= 0) return;

        const newValue = currentUndoRedoSelectedComponent.current.history[currentUndoRedoSelectedComponent.current.index - 1];

        setUndoRedoData({
            ...getUndoRedoData,
            [getSelectedToolUndoRedo]: {
                ...currentUndoRedoSelectedComponent.current,
                index: currentUndoRedoSelectedComponent.current.index - 1,
            },
        });

        setGeoJsonData(newValue);
        updateSourceData(newValue);
    };

    return (
        <div className="pain_items" onClick={handleUndo}>
            <img src={PrevIcon} alt="" className="icon_img" />
        </div>
    );
};

export default toolboxStatesWithRedux(memo(UndoToolbox));
