// Slides.js
import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';

const Slides = ({ galleryItems, convertToNoCookie }) => {
    // Function to render each slide based on type
    const renderSlideContent = (slide) => {
        switch (slide.type) {
            case 0:
                return <img src={slide?.path} alt={slide.caption} />;
            case 1:
                return (
                    <video
                        className="is-video"
                        data-item={true}
                        src={slide?.path}
                        controls
                    />
                );
            case 3:
                return (
                    <iframe
                        loading="lazy"
                        className="is-video"
                        src={convertToNoCookie(slide?.path)}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {galleryItems
                ?.filter((x) => x.type !== 4)
                ?.map((slide) => (
                    <SplideSlide
                        key={slide?.path}
                        className={`gallery-item single-gallery ${slide.mediaId}`}
                    >
                        {renderSlideContent(slide)}
                    </SplideSlide>
                ))}
        </>
    );
};

export default Slides;
