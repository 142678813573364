import { getStatesAPI } from "../../service/states";
import { SET_STATES, LOADING_STATES } from "../constants";
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils";
import { getSchoolsFromAPI } from "./schools";

export const loadingStates = () => ({
  type: LOADING_STATES,
});

export const setStates = (states) => ({
  type: SET_STATES,
  payload: states,
});

export const getStatesFromAPI = (lng = 1) => (dispatch, getState) => {
  const state = getState();
  const { url, body } = constructSelectedItemApiParams(
    "state",
    state.user.data,
      lng
  );
  dispatch(loadingStates());
  getStatesAPI(url, body).then((states) => {
    dispatch(setStates(states));
    if (states.length === 1) {
      getSchoolsFromAPI();
    }
  });
};
