const Flag = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="17px" height="22px" viewBox="0 0 17 22" version="1.1">
            <title>Legend-white</title>
            <g id="Legend-white" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group" transform="translate(2.000000, 1.000000)" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="1.5">
                    <line x1="0" y1="0" x2="0" y2="20" id="Vector"/>
                    <path d="M0,2 L11.2000008,2 C13.9000008,2 14.5,3.5 12.6000004,5.4000001 L11.3999996,6.60000038 C10.5999996,7.40000039 10.5999996,8.69999963 11.3999996,9.39999962 L12.6000004,10.6000004 C14.5,12.5 13.8000007,14 11.2000008,14 L0,14" id="Vector"/>
                </g>
            </g>
        </svg>
    )
}
export default Flag;
