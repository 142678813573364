import {useState,useEffect} from "react";
import ReactDOM from "react-dom";

const Portal = ({children,cssText, parentNode = document.body}) => {
    const [container] = useState(()=>document.createElement('div'))
    container.style.cssText = 'z-index:1;position:fixed;' + cssText

    useEffect(()=>{
        (parentNode || document.body).appendChild(container)

        return ()=> (parentNode || document.body).removeChild(container)

    },[])

    return ReactDOM.createPortal(children,container)
}
export default Portal
