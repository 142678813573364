import { useState } from "react";
import { connect } from "react-redux";
import {OutlinedInput, Stack, TextareaAutosize} from "@mui/material";
import Button from "@mui/material/Button";
import "./style.css";
import {useTranslation} from "react-i18next";

const ScreenShotEdit = (props) => {
  const { item, onClose, handleSetEditItem } = props;
  const {t} = useTranslation()
  const [text, setText] = useState(props.title);
  const [description, setDescription] = useState(props.shortDescription);
  const [isAreaFocused, setAreaFocused] = useState(false);

  const [editButtonDisable, setEditButtonDisable] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  let pageLoc = +queryParams.get("page");

  const onScreenShotEdit = () => {
    setEditButtonDisable(false);
    handleSetEditItem(
      pageLoc,
      item.userId,
      item.id,
      text,
      description,
      item,
      onClose
    );
  };

  return (
    <div className="card-body__edit-screenshot-title-form edit-screenshot-title-form">
      <h5>{t('title')}</h5>
      <div className="edit-screenshot-title-form__btn-box">
        <OutlinedInput
          onChange={(e) => setText(e.target.value)}
          defaultValue={item?.title}
          variant="outlined"
          autoFocus={true}
        />
      </div>
      <h5>{t('description')}</h5>
        <div className={`${isAreaFocused ? 'edit__area__focused' : 'edit-screenshot-description-form__btn-box '}`}>
        <TextareaAutosize
            onFocus={() => setAreaFocused(true)}
            onBlur={() => setAreaFocused(false)}
            className='edit__description__area'
            onChange={(e) => setDescription(e.target.value)}
            defaultValue={item?.shortDescription}
            variant="outlined"
        />
      </div>
      <Stack spacing={2} direction="row" justifyContent={"center"}>
        <Button onClick={onClose} variant="outlined" color={"inherit"}>
            {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onScreenShotEdit}
          disabled={!editButtonDisable || !text?.length}
        >
            {t('save')}
        </Button>
      </Stack>
    </div>
  );
};


export default connect(null, null)(ScreenShotEdit);
