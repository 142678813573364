
        import React from 'react';

        const MinimizeIcon = () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="59px" height="59px" viewBox="0 0 59 59">
                        <title>maximize-4</title>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0"
                           strokeLinecap="round" strokeLinejoin="round">
                                <g transform="translate(-3586.000000, -52.000000)" stroke="#FFFFFF" strokeWidth="4.9">
                                        <g transform="translate(3589.000000, 55.000000)">
                                                <path d="M23.1316 50.1316C23.1316 43.0508 23.1316 39.0808 23.1316 32H5" fillRule="nonzero"/>
                                                <path d="M32 5V23.1316H50.1316" fillRule="nonzero"/>
                                                <line x1="53" y1="0" x2="30.6842105" y2="22.3157895"/>
                                                <line x1="22.3157895" y1="30.6842105" x2="0" y2="53" />
                                        </g>
                                </g>
                        </g>
                </svg>
        );

        export default MinimizeIcon;
