export const checkValidationErrors = (fields, t) => {
  let errors = {}
  let hasError = false
  //firstName
  if (!fields["firstName"]) {
    hasError = true
    errors["firstName"] = t('errors.requiredField')
  }else if (fields["firstName"].length <2) {
    hasError = true
    errors["firstName"] = t('errors.fieldLessThanTwoSymbols')
  }

  //lastName
  if (!fields["lastName"]) {
    hasError = true
    errors["lastName"] = t('errors.requiredField')
  }else if (fields["lastName"].length <2) {
    hasError = true
    errors["lastName"] = t('errors.fieldLessThanTwoSymbols')
  }

  //Email
  if (!fields["email"] && fields["selectedRegisterType"] === "Email") {
    hasError = true
    errors["email"] = t('errors.requiredField')
  }

  if (typeof fields["email"] !== "undefined" && fields["selectedRegisterType"] === "Email") {
    let lastAtPos = fields["email"].lastIndexOf("@")
    let lastDotPos = fields["email"].lastIndexOf(".")
    if (
      !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      fields["email"].indexOf("@@") == -1 &&
      lastDotPos > 2 &&
      fields["email"].length - lastDotPos > 2 &&
      !/\s/.test(fields['email'])
      )
    ) {
      hasError = true
      errors["email"] = t('errors.incorrectEmail')
    }
  }

  //speciality
  if (!fields["speciality"]) {
    hasError = true
    errors["speciality"] = t('errors.requiredField')
  }
  if (!fields["phoneNumber"] && fields["selectedRegisterType"] === "Phone") {
    hasError = true
    errors["phoneNumber"] = t('errors.requiredField')
  }

  //Password
  if (!fields["password"]) {
    hasError = true
    errors["password"] = t('errors.requiredField')
  }

  if (fields["password"]?.length < 6) {
    hasError = true
    errors["password"] = t('errors.passwordLessThanSixSymbols')
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = t('errors.requiredField')
  }

  if (fields["password"] !== fields["cPassword"]) {
    hasError = true
    errors["cPassword"] = t('errors.incorrectPassword')
  }

  return hasError ? errors : null
}
