import React, {memo, useEffect, useState} from 'react';

import ColorCircle from "../../components/ColorCircle";

import {color_picker_data} from "../../../../utils/color_picker_data";

import CloseIcon from '../../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../../assets/imgs/PaintBar/delete-trash-icon.svg';

import "./style.css";
import toolboxStatesWithRedux from "../../mapper";

const LineDrawCreateEditPopup = (props) => {
    const {
        getLineDrawToolboxWeight,
        setLineDrawToolboxWeight,
        getLineDrawToolboxColors,
        getLineDrawToolboxCurrentColor,
        setLineDrawToolboxCurrentColor,
        getLineDrawToolboxColorPickerState,
        setLineDrawToolboxColorPickerState,
        handleClose,
        handleDelete,
        updateColorStatus,
    } = props;

    const [isOpenWeight, setIsOpenWeight] = useState(false);
    const [isOpenColors, setIsOpenColors] = useState(false);

    const toggleWeightsDropdown = () => {
        setIsOpenWeight(!isOpenWeight);
    };

    const toggleColorsDropdown = () => {
        if (getLineDrawToolboxColorPickerState && isOpenColors) {
            setLineDrawToolboxColorPickerState(false);
        }

        setIsOpenColors(!isOpenColors)
    }

    const handleWeightSelect = (option) => {
        setLineDrawToolboxWeight(option);
        setIsOpenWeight(false);
    };

    const colorSelectHandler = (e, color) => {
        e.preventDefault();
        e.stopPropagation();

        setLineDrawToolboxCurrentColor(color.color);
        updateColorStatus(color.id);
    }

    return (
        <>
            {isOpenColors &&
                <div className={'color-picker-popup'}>
                    {getLineDrawToolboxColors.map((color, i) => {
                        return (
                            <div
                                key={i}
                                className="line_tool_color_data_item">
                                {i === getLineDrawToolboxColors.length - 1 ? (
                                    <div className={"available-color"} style={{height: 25, cursor: 'pointer'}} onClick={() => {setLineDrawToolboxColorPickerState(!getLineDrawToolboxColorPickerState)}}>
                                        <svg
                                            style={{
                                                background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                borderRadius: "50%",
                                                height: 25,
                                                width: 25,
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                        />
                                    </div>
                                ) : (
                                    <div className={"available-color"}
                                         onClick={(e) => colorSelectHandler(e, color)}
                                    >
                                        <ColorCircle updateColorStatus={updateColorStatus}
                                                     status={color.color === getLineDrawToolboxCurrentColor}
                                                     color={color}/>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div> }
            {
                isOpenColors && getLineDrawToolboxColorPickerState && (
                    <div
                        className="line_draw_tools_color_item_container"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        {color_picker_data.map((color, i) => (
                            <div
                                key={i}
                                className="line_draw_tools_color_item"
                                style={{backgroundColor: color}}
                                onClick={() => {
                                    setLineDrawToolboxCurrentColor(color);
                                    updateColorStatus(6);
                                    setLineDrawToolboxColorPickerState(false);
                                }}
                            ></div>
                        ))}
                        <span></span>
                    </div>
                )}
            <div className='draw-lines-popup-container_color-picker' onClick={toggleColorsDropdown}>
                <span className={"current-picked-color"} onClick={toggleColorsDropdown}>
                    <ColorCircle updateColorStatus={updateColorStatus}
                                 color={{color: getLineDrawToolboxCurrentColor}}
                                 status={true} />
                </span>
            </div>
            <div className='draw-lines-popup-container_weight-picker' onClick={toggleWeightsDropdown}>
                <span className={'current-line-weight'}>{getLineDrawToolboxWeight}</span>
                {isOpenWeight &&
                    <div className="dropdown-content">
                        {[...Array(20).keys()].map((index) => {
                            const option = index + 1;
                            return (
                                <span
                                    key={option}
                                    className={option === getLineDrawToolboxWeight ? 'selected' : ''}
                                    onClick={() => handleWeightSelect(option)}
                                >
                                    {option}
                                </span>
                            );
                        })}
                    </div>
                }
            </div>
            <div className='draw-lines-popup-container_actions'>
                <span className={'delete-icon-wrapper'} onClick={() => handleDelete('line')}>
                    <img className={'delete-icon'} src={DeleteIcon} alt="Delete"/>
                 </span>
                <span className={'close-icon-wrapper'} onClick={() => handleClose('line')}>
                    <img className={'close-icon'} src={CloseIcon} alt="Close"/>
                 </span>
            </div>
        </>
    )
};

export default toolboxStatesWithRedux(memo(LineDrawCreateEditPopup));
