import { forwardRef } from "react";
import {useTranslation} from "react-i18next";

const ShapeItem = forwardRef(({ category, categoryShapes, onClick, isActive }, ref) => {
  const {t} = useTranslation()

  const clickHandler = (shape) => {
    onClick(shape, category);
  };

  return (
    <div
      key={category}
      ref={ref}
      data-category={category}
      className="shape_selector--category-section"
    >
      <h2>{t(`shapeTool.${category}`)}</h2>
      <div className="shape_selector--list">
        {categoryShapes.map((shape) => (
          <div key={shape.id} title={t(`shapeTool.${shape.name}`)} className="shape-item" onClick={() => clickHandler(shape)}>
           <div dangerouslySetInnerHTML={{__html:shape.svg}}/>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ShapeItem;
