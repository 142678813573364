import {
    SET_ACTIVE_SHAPE,
    SET_SHAPE_MENU_IS_OPEN,
    SET_SHAPE_TOOL_DATA,
    SET_SHAPE_TOOL_STATE,
    SET_SHAPES
} from "../actions/shapeTool";

const initialState = {
    activeShape: null,
    shapeMenuIsOpen: false,
    shapes:[

    ],
};

export const shapeToolReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_SHAPE:
            return {
                ...state,
                activeShape: action.payload,
            };
        case SET_SHAPE_MENU_IS_OPEN:
            return {
                ...state,
                shapeMenuIsOpen: action.payload,
            };
        case SET_SHAPES:
            return {
                ...state,
                shapes: action.payload,
            };
        case SET_SHAPE_TOOL_STATE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};
