import React, { memo, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import toolboxStatesWithRedux from './mapper'

import CommentToolbox from './CommentToolbox'
import RulerToolbox from './RulerToolbox'
import StickyNotes from './StickyNotes'
import DrawerToolbox from './DrawerToolbox'
import MapScreenShotButton from '../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton'
import EraserToolbox from './EraserToolbox'
import EraserMockupComponent from './EraserToolbox/EraserMockupComponent'
import DeleteToolbox from './DeleteToolbox'
import UndoToolbox from './UndoRedoToolbox/UndoToolbox'
import RedoToolbox from './UndoRedoToolbox/RedoToolbox'
import MapboxDrawToolbox from './MapboxDrawToolbox'
import TextToolBox from './TextToolBox'

import { MAP_TOOLBOX_KEYS } from '../mapToolbox'
import './style.css'
import ShapeToolButton from "./ShapeToolbox/shapeToolbar/ShapeToolButton";

const Toolbox = (props) => {
  const {
    map,
    painterStart,
    getScreenShootButtonStateST,
    tools,
    globalLoading,
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const hasEraser = tools?.[MAP_TOOLBOX_KEYS.ERASER]
  const hasRuler = tools?.[MAP_TOOLBOX_KEYS.RULER]
  const hasDrawer = tools?.[MAP_TOOLBOX_KEYS.DRAWER]
  const hasUndo = tools?.[MAP_TOOLBOX_KEYS.UNDO]
  const hasRedo = tools?.[MAP_TOOLBOX_KEYS.REDO]
  const hasDelete = tools?.[MAP_TOOLBOX_KEYS.DELETE]
  const hasLineDraw = tools?.[MAP_TOOLBOX_KEYS.LINE_DRAW]
  const hasPolygonDraw = tools?.[MAP_TOOLBOX_KEYS.POLYGON_DRAW]
  const hasCommentBox = tools?.[MAP_TOOLBOX_KEYS.COMMENT_BOX]
  const hasStickyNotes = tools?.[MAP_TOOLBOX_KEYS.STICKY_NOTES]
  const hasShapeTool = tools?.[MAP_TOOLBOX_KEYS.SHAPE_TOOL]

  const hasTextBox =
    process.env.REACT_APP_ENV === 'production'
      ? null
      : tools?.[MAP_TOOLBOX_KEYS.TEXT_BOX]

  const [isScreenShot, setIsScreenShot] = useState(query.get('screenShot'))

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    setIsScreenShot(() => query.get('screenShot'))

    if (query.get('loading') === 'true') {
      const updatedSearchParams = new URLSearchParams(searchParams)
      updatedSearchParams.delete('loading')
      setSearchParams(updatedSearchParams)
    }
  }, [searchParams])

  return (
    <div
      className="paintBar_container"
      style={{ display: painterStart ? 'flex' : 'none' }}>
      <div id={'screen-shot-icon'} className="lastToolboxBtn">
        {painterStart && (
          <MapScreenShotButton
            map={map}
            disable={isScreenShot ? getScreenShootButtonStateST : undefined}
          />
        )}
      </div>
      {hasDelete && <DeleteToolbox map={map} />}
      {hasUndo && <UndoToolbox map={map} />}
      {hasRedo && <RedoToolbox map={map} />}
      {hasShapeTool && <ShapeToolButton map={map} />}
      {hasRuler && (
        <RulerToolbox
          isScreenShot={isScreenShot}
          hasLineOrPolygonDraw={hasLineDraw || hasPolygonDraw}
          map={map}
        />
      )}
      {hasEraser &&
        (hasDrawer ? (
          <EraserToolbox map={map} />
        ) : (
          <EraserMockupComponent map={map} />
        ))}
      {hasDrawer && (
        <DrawerToolbox
          map={map}
          isScreenShot={isScreenShot}
          hasLineOrPolygonDraw={hasLineDraw || hasPolygonDraw}
        />
      )}
      {hasStickyNotes && <StickyNotes map={map} />}
      {hasTextBox && <TextToolBox map={map} />}
      {hasCommentBox && <CommentToolbox map={map} />}
      {(hasLineDraw || hasPolygonDraw) && (
        <MapboxDrawToolbox
          globalLoading={globalLoading}
          hasLineDraw={hasLineDraw}
          hasPolygonDraw={hasPolygonDraw}
          map={map}
          isScreenshot={isScreenShot}
        />
      )}
    </div>
  )
}

export default toolboxStatesWithRedux(memo(Toolbox))
