import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import "./style.css";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setCompassCoordinates,
  setCompassNewAngle,
  setCompassRotate, setDisableCompass,
  setResetCompass,
} from "../../../store/actions/compassStateAction";
import {
  getCompassCoordinates, getDisableCompass,
  getNewAngle,
  getNewRotate,
  getResetCompass, getScreenShotLoadingST,
} from "../../../store/selectors";

let repeat = 0;
let repeatScale = 0;
let change = false;
let ringInitialDefault = {
  active: true,
  animate: false,
  style: { transform: "rotate(0rad)" },
  dragFinish: 0,
  drag: 0,
  startDeg: false,
};
const Compass = ({
  globalLoading,
  mapState,
  zoom,
  getNewAngle,
  setCompassNewAngle,
  getNewRotate,
  setCompassRotate,
  getCompassCoordinates,
  setCompassCoordinates,
  getResetCompass,
  disableCompass,
  setResetCompass,
  screenShotFirstLoad,
  screenShotLoading
}) => {
  useEffect(() => {
    if (mapState) {
      repeat = 0;
      ringInitialDefault = {
        ...ringInitialDefault,
        active: true,
        animate: false,
        style: { transform: "rotate(0rad)" },
        dragFinish: 0,
        drag: 0,
        startDeg: false,
      };
      ringRef.current.setAttribute("data-animate", "true");
      ringRef.current.setAttribute("data-drag", 0);
      ringRef.current.style.transform = `rotate(${0}deg)`;
      ringRef.current.setAttribute("data-active", "false");
      ringRef.current.setAttribute("data-start-deg", "false");
      ringRef.current.setAttribute("data-drag-finish", 0);

      tiltedRef.current.style.transform = `rotateX(${0}deg)`;

      change = false;
      tiltedRef.current.setAttribute("data-active", "true");
      handleTilt3D();
    }
  }, [getResetCompass]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isScreenShot = query.get("screenShot");

  const [firstLoad, setFirstLoad] = useState(true);
  const [firstRotateLoad, setRotateFirstLoad] = useState(true);


  useEffect(() => {
    if ((isScreenShot  && mapState && screenShotLoading) &&  (getNewAngle !== undefined && getNewAngle !== null) ) {
        // setFirstLoad(false);
        if(mapState.getZoom() < 5) return
        mapState.flyTo({
          pitch: getNewAngle,
        });

        tiltedRef.current.style.transform = `rotateX(${
            getNewAngle >= 45 ? 45 : getNewAngle
        }deg)`;

        const min = 4;

        if (getNewAngle <= min) {
          change = false;
          tiltedRef.current.setAttribute("data-active", "true");
          handleTilt3D();
        } else if (getNewAngle > min) {
          handleTilt2D();
        }
    }
  }, [getNewAngle]);

  useEffect(() => {
    if (
      isScreenShot &&
      firstRotateLoad &&
      getNewRotate &&
      Object.keys(getCompassCoordinates).length > 0
    ) {
      let dragFinish = ringRef.current.getAttribute("data-drag-finish");
      let newAngle = Math.atan2(
        getCompassCoordinates.dragY,
        getCompassCoordinates.dragX
      );

      newAngle = parseInt((newAngle / 3.14) * 180 + (newAngle > 0 ? 0 : 345));
      if (ringRef.current.getAttribute("data-start-deg") === "false") {
        ringRef.current.setAttribute("data-start-deg", newAngle);
      }
      if (ringRef.current.getAttribute("data-active") === "true") {
        ringRef.current.setAttribute("data-active", "false");
      }
      if (ringRef.current.onmouseup !== null && !isMobile) {
        ringRef.current.onmouseup = null;
      }
      if (ringRef.current.ontouchend !== null && isMobile) {
        ringRef.current.ontouchend = null;
      }
      let startAt = ringRef.current.getAttribute("data-start-deg");
      let res = newAngle - startAt + parseFloat(dragFinish);
      if (res > 359) {
        res -= 359;
      }

      ringRef.current.setAttribute("data-drag", getNewRotate);
      ringRef.current.style.transform = `rotate(${getNewRotate}deg)`;
      ringRef.current.setAttribute("data-active", "false");
      ringRef.current.setAttribute("data-start-deg", "false");
      ringRef.current.setAttribute("data-animate", "false");
      ringRef.current.setAttribute("data-drag-finish", getNewRotate);

      mapState.rotateTo(`${-getNewRotate}.0`, { duration: 500 });

      setRotateFirstLoad(false);
    }
  }, [getNewRotate, getCompassCoordinates]);

  const tiltedRef = useRef();
  const ringRef = useRef();
  const reseState = useSelector((state) => state.reset.resetState);

  //map drag and drop functions
  function handleDragMapRing(e) {
    if (
        zoom < 4 &&
        e?.originalEvent?.which !== 3 &&
        e?.originalEvent?.buttons !== 1 &&
        e?.originalEvent?.ctrlKey
    ) return;
      if (ringRef.current.getAttribute("data-active") === "true") {
        ringRef.current.setAttribute("data-active", "false");
      }
      let newAngle = parseInt(
        (e.target.transform.angle / 3.14) * 180 +
          (e.target.transform.angle > 0 ? 0 : 345)
      );

      if (newAngle > 180) {
        newAngle -= 345;
      }

      let dragFinish = ringRef.current.getAttribute("data-drag-finish");
      let cordinates = ringRef.current.getBoundingClientRect();

      ///
      let { pageX, pageY } = isMobile ? e?.originalEvent?.changedTouches[0] : e;
      let dragX = pageX - (cordinates.x + cordinates.width / 2),
        dragY = pageY - (cordinates.y + cordinates.height / 2);

      setCompassCoordinates({
        pageX,
        pageY,
        dragX,
        dragY,
      });

      setCompassRotate(newAngle);

      ringRef.current.setAttribute("data-drag", newAngle);
      ringRef.current.setAttribute("data-start-deg", newAngle);
      ringRef.current.style.transform = `rotate(${newAngle}deg)`;
  }
  function handleDragMapTilt(e) {
    if (
      zoom < 4 &&
      e?.originalEvent?.which !== 3 &&
      e?.originalEvent?.buttons !== 1 &&
      e?.originalEvent?.ctrlKey
    ) return;
    const max = 45;
    const min = 4;
    if (!change) {
      change = true;
    }
    if (tiltedRef.current.getAttribute("data-active") === "true") {
      tiltedRef.current.setAttribute("data-active", "false");
    }
    let newPitch = e.target.transform.pitch;

    setCompassNewAngle(newPitch);

    if (newPitch >= 0 && newPitch <= max && repeat !== newPitch) {
      repeat = newPitch;
      tiltedRef.current.style.transform = `rotateX(${newPitch}deg)`;
    }

    if (newPitch <= min) {
      change = false;
      tiltedRef.current.setAttribute("data-active", "true");
      handleTilt3D();
    } else if (newPitch > min) {
      handleTilt2D();
    }
  }
  //tilt drag and drop functions

  function dragMouseDown(e) {
    if(zoom<5) return
    //subscribe events mouseup and  mousemove on document
    e = e || window.event;
    !isMobile && e.preventDefault();

    // call a function whenever the mouse up:
    !isMobile && (document.onmouseup = closeDragElement);
    isMobile && (document.ontouchend = closeDragElement);
    // call a function whenever the cursor moves:
    !isMobile && (document.onmousemove = elementDrag);
    isMobile && (document.ontouchmove = elementDrag);
    tiltedRef.current.setAttribute("data-animate", "false");
    !isMobile && (tiltedRef.current.onmouseup = handlePosTilt);
    isMobile && (tiltedRef.current.ontouchend = handlePosTilt);
  }

  function handleTilt2D() {
    tiltedRef.current.innerHTML = "2D";
    tiltedRef.current.style.backgroundColor = "#196dff";
    tiltedRef.current.style.color = "#fff";
  }
  function handleTilt3D() {
    tiltedRef.current.style.transform = `rotateX(0deg)`;
    tiltedRef.current.innerHTML = "3D";
    tiltedRef.current.style.backgroundColor = "#fff";
    tiltedRef.current.style.color = "#2C476C";
  }

  function handlePosTilt() {
    tiltedRef.current.setAttribute("data-animate", "true");
    //if attribute data is false set css with 2d
    if (tiltedRef.current.getAttribute("data-active") === "true") {
      change = true;
      handleTilt2D();
      tiltedRef.current.style.transform = `rotateX(45deg)`;
      tiltedRef.current.setAttribute("data-active", "false");
      mapState.flyTo({
        pitch: 45,
        essential: true,
      });
      setCompassNewAngle(45);
      return;
    }
    //set data attribute true for next call
    tiltedRef.current.setAttribute("data-active", "true");
    //if attribute data is true set css with 3d
    change = false;
    handleTilt3D();
    mapState.flyTo({
      pitch: 0,
      essential: true,
    });
    setCompassNewAngle(0);
  }
  function elementDrag(e) {
    e = e || window.event;
    !isMobile && e.preventDefault();

    if (!change) {
      change = true;
    }
    //if data attribute active set false value for first click in compass  return 3d styles
    if (tiltedRef.current.getAttribute("data-active") === "true") {
      tiltedRef.current.setAttribute("data-active", "false");
    }
    // get element cordinates
    let cordinates = tiltedRef.current.getBoundingClientRect();
    // calculate the new cursor position:
    let { pageX, pageY } = isMobile ? e?.changedTouches[0] : e;
    let dragY = pageY - (cordinates.y + cordinates.width / 2);
    // if(window.screen.width>=900 && window.screen.width <= 1800){
    //     dragY += cordinates.y*30/100
    // }
    if (tiltedRef.current.onmouseup !== null && !isMobile) {
      tiltedRef.current.onmouseup = null;
    }
    if (tiltedRef.current.ontouchend !== null && isMobile) {
      tiltedRef.current.ontouchend = null;
    }
    // set the element's new position:
    if (dragY > 0) dragY = -0.1;
    let newAngle = Math.atan2(-dragY, 15);
    newAngle = (newAngle / 1.8) * 100 + (newAngle > 0 ? 0 : 45);

    const max = 85;
    const min = 4;
    if (
      dragY !== 0 &&
      newAngle >= 0 &&
      newAngle <= max &&
      repeat !== newAngle
    ) {
      repeat = newAngle;
      if (newAngle <= 45) {
        tiltedRef.current.style.transform = `rotateX(${newAngle}deg)`;
      } else {
        tiltedRef.current.style.transform = `rotateX(${45}deg)`;
      }

      setCompassNewAngle(newAngle);
      mapState.setPitch(newAngle);
    }

    if (newAngle <= min) {
      change = false;
      tiltedRef.current.setAttribute("data-active", "true");
      handleTilt3D();
    } else if (newAngle > min) {
      handleTilt2D();
    }
  }

  //ring drag and drop functions
  function dragRingMouseDown(e) {
    //subscribe events mouseup and  mousemove on document
    e = e || window.event;
    !isMobile && e.preventDefault();

    // call a function whenever the mouse up:
    !isMobile && (document.onmouseup = closeDragElement);
    isMobile && (document.ontouchend = closeDragElement);
    // call a function whenever the cursor moves:
    !isMobile && (document.onmousemove = handleRingDrag);
    isMobile && (document.ontouchmove = handleRingDrag);
    // data animate is transition here we are closa transition for floating rotate with drag
    ringRef.current.setAttribute("data-animate", "false");

    //if we are don't drag  this function call click evenet and set fix cordinates
    !isMobile && (ringRef.current.onmouseup = handleSetFixCordinateRing);
    isMobile && (ringRef.current.ontouchend = handleSetFixCordinateRing);
  }
  function handleRingDrag(e) {
    !isMobile && e.preventDefault();
    let dragFinish = ringRef.current.getAttribute("data-drag-finish");
    let cordinates = ringRef.current.getBoundingClientRect();
    ///
    let { pageX, pageY } = isMobile ? e?.changedTouches?.[0] : e;
    let dragX = pageX - (cordinates.x + cordinates.width / 2),
      dragY = pageY - (cordinates.y + cordinates.height / 2);

    setCompassCoordinates({
      pageX,
      pageY,
      dragX,
      dragY,
    });

    // if(window.screen.width>=900 && window.screen.width <= 1800){
    //     dragX +=  cordinates.x*30/100
    //     dragY += cordinates.y*30/100
    // }
    let newAngle = Math.atan2(dragY, dragX);
    newAngle = parseInt((newAngle / 3.14) * 180 + (newAngle > 0 ? 0 : 345));
    if (ringRef.current.getAttribute("data-start-deg") === "false") {
      ringRef.current.setAttribute("data-start-deg", newAngle);
    }
    if (ringRef.current.getAttribute("data-active") === "true") {
      ringRef.current.setAttribute("data-active", "false");
    }
    if (ringRef.current.onmouseup !== null && !isMobile) {
      ringRef.current.onmouseup = null;
    }
    if (ringRef.current.ontouchend !== null && isMobile) {
      ringRef.current.ontouchend = null;
    }
    let startAt = ringRef.current.getAttribute("data-start-deg");
    let res = newAngle - startAt + parseFloat(dragFinish);
    if (res > 359) {
      res -= 359;
    }
    mapState.rotateTo(`${-res}.0`, { duration: 0 });
    ringRef.current.setAttribute("data-drag", res);
    ringRef.current.style.transform = `rotate(${res}deg)`;
    setCompassRotate(res);
  }
  function handleSetFixCordinateRing() {
    //set animation transition for floating auto rotate
    ringRef.current.setAttribute("data-animate", "true");
    //set fix cordinates
    if (ringRef.current.getAttribute("data-active") === "true") {
      ringRef.current.setAttribute("data-drag", -45);
      ringRef.current.style.transform = `rotate(-45deg)`;
      ringRef.current.setAttribute("data-active", "false");
      mapState.rotateTo(`${45}.0`, { duration: 500 });
    } else if (ringRef.current.getAttribute("data-active") === "false") {
      ringRef.current.setAttribute("data-active", "true");
      if (ringRef.current.getAttribute("data-drag") > 180) {
        mapState.rotateTo(`${345}.0`, { duration: 500 });
        ringRef.current.setAttribute("data-drag", 345);
        return (ringRef.current.style.transform = `rotate(345deg)`);
      }
      mapState.rotateTo(`0`, { duration: 500 });
      ringRef.current.setAttribute("data-drag", 0);
      return (ringRef.current.style.transform = `rotate(0deg)`);
    }
  }

  //close all subscriptions
  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
    document.ontouchmove = null;
    document.ontouchstart = null;
    tiltedRef.current.onmouseup = null;
    ringRef.current.onmouseup = null;
    tiltedRef.current.ontouchstart = null;
    ringRef.current.ontouchstart = null;
    let drag = ringRef.current.getAttribute("data-drag");
    ringRef.current.setAttribute("data-drag-finish", drag);
    ringRef.current.setAttribute("data-start-deg", "false");
  }

  const handleChangeZoom = (zoomNow) => {
    if (!change) return;
    if (zoomNow < 4) {
      change = false;
      setTimeout(() => {
        tiltedRef.current.setAttribute("data-active", "true");
        mapState.flyTo({
          pitch: 0,
          speed: 0.3,
          essential: true,
        });
        mapState.scrollZoom.disable();
        setTimeout(() => {
          mapState.scrollZoom.enable();
        }, 400);
        handleTilt3D();
      }, 500);
    }
  };

  const handleRingToInitialDefault = () => {
    const element = ringRef.current;
    element.style = ringInitialDefault.style;
    element.setAttribute("data-active", ringInitialDefault.active);
    element.setAttribute("data-animate", true);
    element.setAttribute("data-drag-finish", ringInitialDefault.dragFinish);
    element.setAttribute("data-drag", ringInitialDefault.drag);
    element.setAttribute("data-start-deg", ringInitialDefault.startDeg);
    setTimeout(() => {
      element.setAttribute("data-animate", ringInitialDefault.animate);
    }, 500);
  };

  useEffect(() => {
    if (reseState !== null) {
      mapState.on("zoom", (e) => {
        handleChangeZoom(e.target.transform._zoom);
        if (e.target.transform._zoom > 4) {
          tiltedRef.current.setAttribute("data-get-start", "true");
          mapState.dragRotate._mousePitch.enable();
        } else {
          tiltedRef.current.setAttribute("data-get-start", "false");
          mapState.dragRotate._mousePitch.disable();
        }
      });
    }
  }, [reseState]);

  useEffect(() => {
    if (mapState && typeof mapState?.transform?.resetCompass !== "function") {
      mapState.transform.resetCompass = handleRingToInitialDefault;
    }
  }, [mapState]);
  useEffect(() => {
    if (mapState !== null) {
      function syncMapAndCompassStart() {
        ringRef.current.setAttribute("data-animate", "false");
        tiltedRef.current.setAttribute("data-animate", "false");
        mapState.on("rotate", handleDragMapRing);
        mapState.on("pitch", handleDragMapTilt);
      }
      function syncMapAndCompassEnd() {
        let drag = ringRef.current.getAttribute("data-drag");
        ringRef.current.setAttribute("data-drag-finish", +drag);
        ringRef.current.setAttribute("data-start-deg", "false");
        mapState.off("rotate", handleDragMapRing);
        mapState.off("pitch", handleDragMapTilt);
      }
      mapState.__proto__.syncMapAndCompassStart = syncMapAndCompassStart
      mapState.__proto__.syncMapAndCompassEnd = syncMapAndCompassEnd
      mapState.__proto__.compassTilt3D = handleTilt3D
      mapState.__proto__.compassTilt2D = handleTilt2D
      if (!isMobile) {
        mapState.on("mousedown", mapState.syncMapAndCompassStart);
        mapState.on("mouseup", mapState.syncMapAndCompassEnd);

      }
      if (isMobile) {
        mapState.on("touchstart", mapState.syncMapAndCompassStart);
        mapState.on("touchend", mapState.syncMapAndCompassEnd);
      }
    }
  }, [mapState]);



  return (
    <div className={`map-controls-view__control-group ${globalLoading && 'hidden'}`}>
      <div className="map-controls-view__tilt-rotate-control">
        <div className="map-tilt-rotate-control" aria-hidden="true">
          <div className="map-tilt-rotate-control__tilt-ring">
            <div
              aria-hidden="true"
              className="map-tilt-rotate-control__ring"
              data-active={ringInitialDefault.active}
              data-animate={ringInitialDefault.animate}
              style={ringInitialDefault.style}
              onMouseDown={() => !isMobile && dragRingMouseDown()}
              onDragStart={() => !isMobile && dragRingMouseDown()}
              onTouchMove={() => isMobile && dragRingMouseDown()}
              onTouchStart={() => isMobile && dragRingMouseDown()}
              draggable="true"
              data-drag-finish={ringInitialDefault.dragFinish}
              data-drag={ringInitialDefault.drag}
              data-start-deg={ringInitialDefault.startDeg}
              data-testid="ring-element"
              ref={ringRef}
            ></div>
          </div>
          <div className="map-tilt-rotate-control__tilt-wrapper">
            <div
              aria-hidden="true"
              className="map-tilt-rotate-control__tilt _tilted"
              data-active="true"
              onDragStart={() => !isMobile && dragMouseDown()}
              onMouseDown={() => !isMobile && dragMouseDown()}
              onTouchMove={() => isMobile && dragMouseDown()}
              onTouchStart={() => isMobile && dragMouseDown()}
              data-animate="false"
              ref={tiltedRef}
              draggable="true"
              style={{ transform: "rotateX(0deg)" }}
              data-get-start="true"
              data-disabled = {disableCompass}
              data-testid="tilt-element"
            >
              <span id="compass_3d_text">3D</span>
            </div>
          </div>
        </div>
        <div data-chunk="tooltip" data-ssr-uid="97">
          <div></div>
        </div>
      </div>
    </div>
  );
};

const mapStateTopProps = (state) => ({
  getNewAngle: getNewAngle(state),
  getNewRotate: getNewRotate(state),
  getCompassCoordinates: getCompassCoordinates(state),
  getResetCompass: getResetCompass(state),
  disableCompass: getDisableCompass(state),
  screenShotLoading: getScreenShotLoadingST(state),
});

const mapDispatchToProps = {
  setCompassNewAngle,
  setCompassRotate,
  setCompassCoordinates,
  setResetCompass,
  setDisableCompass
};

export default connect(mapStateTopProps, mapDispatchToProps)(Compass);
