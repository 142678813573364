import {useEffect, useRef} from "react";
import './style.css'
import Loader from "../UI/Loader";
const Quiz = ({close,selectedQuiz}) =>{
    const iframeRef = useRef()

    const {name,path} = selectedQuiz
    useEffect (() => {
        iframeRef.current.innerHTML = path
    }, [selectedQuiz]);
    return (
        <section className={'quiz-content'}>
            <div className={'quiz-header'}>
                <h2 className={'quiz-header-title'}>{name}</h2>
                <button onClick={close} className="quiz-header-btn quiz-close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="61px" height="61px" viewBox="0 0 61 61" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
                            <g id="Read-more" transform="translate(-3726.000000, -51.000000)" stroke="#FFFFFF" strokeWidth="4.9">
                                <g id="Group-2" transform="translate(3726.000000, 55.000000)">
                                    <line x1="-6.58813919" y1="26.1911362" x2="67.5881392" y2="26.4242484" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                    <line x1="30.6165561" y1="63.3958315" x2="30.3834439" y2="-10.7804469" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <div className={'quiz-body'} ref={iframeRef}></div>
        </section>
    )
}
export default Quiz