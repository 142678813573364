import { SET_TIMELINE_OPTIONS } from '../constants'
import ReactDOM from 'react-dom'
import GroupTemplate from '../../components/GroupTemplate'
import ItemTemplate from '../../components/ItemTemplate'
import i18n from "i18next";

const initialState = {
    data: {
        autoResize: false,
        stack: true,
        showCurrentTime: false,
        horizontalScroll: true,
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        maxHeight: '165px',
        minHeight: '90px',
        zoomMin: 315569520000,
        // groupHeightMode: 'fixed',
        format: {
            minorLabels: function (a) {
                if (a.format('YYYY').includes('-') || a.format('YYYY') === '0000') {
                    return '<p class="bc">' + i18n.t('bc') + ' ' + a.format('y').replace('-', '') + '</p>'
                }
                return '<p class="notbc">' +a.format('y')  + '</p>'
            },
        },
        orientation: {
            item: 'top',
            axis: 'bottom',
        },
        order: function (a, b) {
            return a.order - b.order
        },
        snap: function (date, scale, step) {
            const hour = 60 * 1000;
            return Math.round(date)
        },
        groupTemplate: function (group, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<GroupTemplate group={group} />, element),
                element
            )
        },
        template: function (item, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<ItemTemplate item={item} />, element),
                element
            )
        },
    },
    middleTimlinedata: {
        autoResize: false,
        stack: true,
        showCurrentTime: false,
        horizontalScroll: true,
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        maxHeight: '200px',
        minHeight: '65px',
        zoomMin: 315569520000,
        margin : {
            item: {horizontal:0, vertical:0},
            axis: 0
        },
        // groupHeightMode: 'fixed',
        format: {
            minorLabels: function (a) {

                return ''
            },
        },
        orientation: {
            item: 'top',
            axis: 'bottom',
        },
        order: function (a, b) {
            return a.order - b.order
        },
        snap: function (date, scale, step) {
            const hour = 60 * 1000;
            return Math.round(date)
        },
        groupTemplate: function (group, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<GroupTemplate group={group} />, element),
                element
            )
        },
        template: function (item, element) {
            return ReactDOM.createPortal(
                ReactDOM.render(<ItemTemplate item={item} />, element),
                element
            )
        },
    },
}

export const timeLineOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TIMELINE_OPTIONS:
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state
    }
}
