import Portal from "../../portal";
import PageLoader from "./PageLoader";
const stylePopup = {
    position:'fixed',
    top:0,
    left:0,
    right:0,
    bottom:0,
    zIndex:1000,
    display: 'grid',
    placeItems:'center'
}
const styleOverlay = {
    position:'absolute',
    width:'100%',
    height:'100%',
    backgroundColor:'#1B283A',
    zIndex: 1
}
const styleContent = {
    zIndex: 2,
    maxWidth: 'max-content',
    maxHeight: 'max-content'
}
const Popup = (props) => {
    const {
        children,
        onClose,
        isOpened,
        stylePopupUser={},
        styleOverlayUser={},
        styleContentUser={},
        loading,
        overlayHidde,
        cssText
    } = props
    if(!isOpened){
        return null
    }
    return  <Portal cssText={cssText}>
                <div id='article-gallery' className={'popup'} style={{...stylePopup,...stylePopupUser}} role={'dialog'}>
                    {!overlayHidde
                        && <div className={'overlay'}
                          style={{...styleOverlay, ...styleOverlayUser}}
                          role={'button'}
                          tabIndex={0}
                          onClick={onClose}/>}
                    <div className={'content'} style={{...styleContent,...styleContentUser}}>
                        {!('loading' in props) || loading
                            ? children
                            : <PageLoader/>
                        }
                    </div>
                </div>
            </Portal>
}
export default Popup
