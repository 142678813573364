import {SET_TOPICS, LOADING_TOPICS, SET_TOPIC_ID, SET_TOPICS_NAVIGATION} from "../constants";

const initialState = {
  data: [],
  navigation:[],
  isLoading: false,
  topicId:null
};

export const topicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TOPICS: {
      return { ...state, isLoading: true };
    }
    case SET_TOPICS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case SET_TOPICS_NAVIGATION:
      return {
        ...state,
        isLoading: false,
        navigation: action.payload,
      };
   case SET_TOPIC_ID: {
      return { ...state, topicId: action.payload };
    }
    default:
      return state;
  }
};
