import React from 'react'

const ReturnNavigation = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="55px" height="55px" viewBox="0 0 55 55" version="1.1">
            <title>Path</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="pop-up" transform="translate(-3742.000000, -1087.000000)" stroke="#2C476C" strokeWidth="3">
                    <g id="return-to-class-blue" transform="translate(3744.000000, 1089.000000)">
                        <path d="M48.0649216,0.164028136 L1.32083082,19.2866121 C-0.645111584,20.0908561 -0.344627884,22.9636261 1.74521302,23.3435881 L23.6700663,27.3299491 L27.6564304,49.2547881 C28.0363924,51.3446281 30.9091641,51.6451111 31.7134087,49.6791701 L50.8360049,2.93511014 C51.5493176,1.19136914 49.8086634,-0.549382864 48.0649216,0.164028136 Z" id="Path"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default ReturnNavigation
