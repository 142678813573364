import { connect } from "react-redux";
import "./style.css";
import DynamicTitle from "../../CustomCardComponents/DynamicTitle";
import Line from "../../CustomCardComponents/Line";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import ScreenShotEdit from "../ScreenShotEdit";
import {useTranslation} from "react-i18next";

const cardBodyStyle = {
  aspectRatio: "677/255",
  gridTemplateRows: "1fr 1fr",
  padding: "20px",
};
const DynamicScreenShotEdit = (props) => {
  const { onClose, item, handleSetEditItem } = props;
  const {t} = useTranslation()
  return (
    <div className="card-body__presentation-edit-content dynamic-screen-shot-edit-content">
      <CardBody hasIcon={false} styles={cardBodyStyle}>
        <div className={"dynamic-screen-shot-edit-content__header"}>
          <CloseBtn onClose={onClose} />
          <DynamicTitle style={{justifyContent:'center'}} content={t('edit')} />
          <Line />
        </div>
        <div>
          <ScreenShotEdit title={item?.title} onClose={onClose} item={item} handleSetEditItem={handleSetEditItem} />
        </div>
      </CardBody>
    </div>
  );
};
export default connect(null, null)(DynamicScreenShotEdit);
