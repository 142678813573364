import React, { useState, useEffect } from 'react';
import './style.css';

const CustomCursor = ({ type }) => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const cursorSize = 30; // The size of the custom cursor

    useEffect(() => {
        const handleTouchStart = (e) => {
            setIsVisible(true);
            updateCursorPosition(e.touches[0]);
        };

        const handleTouchMove = (e) => {
            updateCursorPosition(e.touches[0]);
        };

        const handleTouchEnd = () => {
            setIsVisible(false);
        };

        const updateCursorPosition = (touch) => {
            const typeOfCoordinates = {
                x: touch.clientX.toFixed(0) - cursorSize / 2, // Center the cursor horizontally
                y: touch.clientY.toFixed(0) - cursorSize / 2, // Center the cursor vertically
            }
            setCursorPosition(typeOfCoordinates);
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);

    return (
        <div
            className={type}
            style={{
                display: isVisible ? 'block' : 'none',
                transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`,
                width: `${cursorSize}px`,
                height: `${cursorSize}px`,
            }}
        />
    );
};

export default CustomCursor;
