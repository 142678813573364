import React, { Component } from "react";
import "./style.css";

class TermsOfUse extends Component {
    render() {
        return (
            <div className="terms-popup">
                <button className="terms-close" type="button" onClick={() => this.props.handleTerms()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H12" transform="translate(6 12)" fill="none" stroke="#292d32" strokeWidth="1.5" strokeDasharray="0 0"/><path d="M0,12V0" transform="translate(12 6)" fill="none" stroke="#292d32" strokeWidth="1.5" strokeDasharray="0 0"/><path d="M24,0V24H0V0Z" fill="none" opacity="0"/></svg>
                </button>
                <div className="WordSection1 terms-content">
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Օգտագործման պայմաններ </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <i>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Սույն Օգտագործման պայմանները (այսուհետև՝ Պայմաններ) վերջին անգամ թարմացվել են 01.09.2023 թ․։{" "} </span>
                        </i>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "106%",
    }}> Բարի՛ գալուստ Բրեյնոգրաֆ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "106%",
    }}> Այստեղ Դուք կարող եք ծանոթանալ մեր Ծառայությունների{" "} <i>(այսուհետև՝ Ծառայություններ) </i>Օգտագործման պայմաններին։ Խնդրում ենք մինչև մեր Ծառայություններից օգտվելը մանրամասն կարդալ սույն Պայմանները։ Սույն Պայմանները պարտադիր ուժ ունեցող համաձայնագիր <span style={{ background: "white" }}>են Ձեր</span> (այսուհետև՝{" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> Դուք </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> {" "} կամ{" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> օգտատեր </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> ) և Բրեյնոգրաֆի (այսուհետև՝{" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> մենք </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> {" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> մեզ </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> {" "} և{" "} </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> մեր </span>
                        <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> ) միջև։ Մուտք գործելով մեր կայքէջ, ստեղծելով օգտահաշիվ կամ օգտվելով մեր Ծառայություններից՝ Դուք ընդունում եք սույն Պայմաններով առաջնորդվելու պարտադիր պահանջը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "106%",
    }}> Եթե Դուք ունեք սույն Պայմաններին վերաբերող հարցեր, խնդրում ենք կապ հաստատել մեզ հետ հետևյալ էլ․ հասցեով՝ info@brainograph.com. </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "106%",
    }}> Մենք ջանում ենք բարելավել մեր Ծառայությունները և Ձեզ համար ապահովել լավագույն հնարավոր փորձառություն։ Խնդրում ենք հաշվի առնել, որ սույն Պայմանները ժամանակ առ ժամանակ ենթակա են փոփոխության։ Էական փոփոխությունների դեպքում մենք էլեկտրոնային փոստի միջոցով Ձեզ այդ մասին տեղյակ կպահենք՝ 30-օր առաջ ուղարկելով փոփոխված Պայմանների հղումը։ Շարունակելով մուտք գործել մեր կայքէջ և օգտվել մեր Ծառայություններից՝ Դուք շարունակում եք ընդունել մեր Պայմանները։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Մեր մասին </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "106%",
    }}> Բրեյնոգրաֆը ( </span>
                        <span lang="EN">
      <a href="http://www.brainograph.com/">
        <span style={{
            fontSize: "12.0pt",
            lineHeight: "106%",
            fontFamily: '"Times New Roman",serif',
            color: "#1155CC"
        }}> www.brainograph.com </span>
      </a>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "106%",
                        }}> ) վիզուալիզացիայի և ինտերակտիվ գործիքակազմի միջոցով բովանդակության կառավարման և մատուցման նորարարական կրթական հարթակ է։ Այն եզակի հայկական ՏՏ պրոդուկտ է, որի նպատակն է դպրոցներում, այլ կրթահամալիրներում, ինչպես նաև կրթական և իրազեկման նպատակով բովանդակություն ստեղծող և առաջարկող այլ կառույցներում վերափոխել ուսուցանելու և ուսանելու գործընթացները։ Բրեյնոգրաֆն օգտատերերին առաջարկում է ինտերակտիվ գործիքակազմ, որը կրթական նպատակներով ժամանակի և տարածության մեջ ճամփորդությունը դարձնում է հետաքրքիր, հիշարժան և արդյունավետ։&nbsp; </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Բովանդակության ցանկ </span>
                        </b>
                    </p>
        <div style={{
                marginLeft: '0.3in'
            }}>

                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 1. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Ձեր օգտահաշիվը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 2. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Գաղտնիությունը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 3. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Համայնքի կանոնները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 4. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Մտավոր սեփականությունն ու հեղինակային իրավունքը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 5. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Տարաբնույթ իրավական պայմանները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 6. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Պատասխանատվությունից հրաժարումը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 7. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Պատասխանատվության սահմանափակումը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 8. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Կիրառելի օրենսդրությունն ու վեճերի կարգավորումը{" "} </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 9. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Սույն Պայմանների թարմացումները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
          fontFamily: '"Times New Roman",serif'
      }}> 10. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "106%",
                            fontFamily: '"Times New Roman",serif'
                        }}></span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> Կապը մեզ հետ </span>
                        </b>
                    </p>
                </div>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Times New Roman",serif'
      }}> &nbsp; </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> Ձեր օգտահաշիվը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բրեյնոգրաֆում գրեթե ցանկացած գործողություն կատարելու համար Ձեզ անհրաժեշտ է օգտահաշիվ։ Քանզի Դուք լիովին և լիարժեք պատասխանատու եք Ձեր օգտահաշվի և դրա հետ կապված որևէ գործողության համար, մենք հորդորում ենք Ձեզ առավելագույն զգուշավորություն դրսևորել գաղտնաբառի հետ կապված։ Եթե Դուք կասկածում եք, որ մեկ ուրիշն օգտագործում է Ձեր հաշիվը, առանց հապաղելու տեղեկացրեք մեզ այդ մասին՝ կապ հաստատելով Աջակցության թիմի հետ հետևյալ էլ․ հասցեով՝ info@brainograph.com։&nbsp;&nbsp;&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Ձեր օգտահաշիվը ստեղծելիս և օգտվելիս Դուք պետք է տրամադրեք և շարունակեք տրամադրել ճշգրիտ և ամբողջական տեղեկատվություն, ներառյալ՝ իրական և գործող էլ․ հասցե։ Դուք լիարժեք պատասխանատվություն եք կրում Ձեր օգտահաշվի և օգտահաշվում կատարվող գործողությունների համար, ներառյալ՝ առանց Ձեր թույլտվության երրորդ անձանց կողմից Ձեր օգտահաշիվը օգտագործելու միջոցով հասցված վնասի (մեզ կամ երրորդ կողմի) համար։ Դուք իրավունք չունեք Ձեր օգտահաշիվը փոխանցելու մեկ ուրիշին կամ ինքներդ օգտագործելու ուրիշինը։ Եթե Դուք կապվեք մեզ հետ՝ օգտահաշվին մուտք պահանջելու նպատակով, մենք այն չենք տրամադրի, քանի դեռ Ձեզանից չենք ստացել բավարար տեղեկատվություն, որը կապացուցի, որ Դուք եք տվյալ օգտահաշվի տերը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք իրավունք չունեք մեկ ուրիշին տրամադրելու Ձեր օգտահաշվի մուտքի տվյալները։ Դուք պատասխանատու եք այն ամենի համար, ինչը պատահում է Ձեր օգտահաշվի հետ, և Բրեյնոգրաֆը չի միջամտի այն աշակերտների, ուսուցիչների և այլ տեսակի օգտատերերի միջև վեճերի կարգավորմանը, ովքեր կիսվել են օգտահաշվի մուտքի տվյալներով։ Դուք պարտավոր եք անմիջապես տեղեկացնել մեզ, եթե կասկածում եք, որ առանց Ձեր թույլտվության մեկ ուրիշն օգտագործում է Ձեր օգտահաշիվը (կամ եթե անվտանգության հետ կապված այլ կասկածներ ունեք)՝ կապ հաստատելով մեր Աջակցության թիմի հետ հետևյալ էլ․ հասցեով՝ info@brainograph.com։ Մենք կարող ենք որոշակի տեղեկատվություն պահանջել Ձեզանից, որպեսզի հաստատենք, որ Դուք իսկապես տվյալ օգտահաշվի տերն եք։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <a name="_gjdgxs" />
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Ծառայություններից օգտվելու համար անհրաժեշտ է բավարարել սոցիալական հարթակներում հաշիվ ստեղծելու համար տվյալ երկրի օրենքներով սահմանվող տարիքային շեմին ներկայացվող պահանջները։ Սահմանված տարիքային շեմից ցածր (ըստ ՀՀ օրենքի՝ 16 տարեկան, ըստ ԱՄՆ օրենքի՝ 13, և այլն) օգտատերերի համար օգտահաշիվ կարող են բացել նրանց ծնողները կամ խնամակալները։ Նման դեպքերում, ծնողը կամ խնամակալն են պատասխանատու օգտահաշվի վարման և դրա հետ կապված բոլոր գործողությունների համար։ Եթե մենք հայտնաբերենք սույն Պայմանը խախտող օգտահաշիվ, մենք կարգելափակենք այն։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> 2․ Գաղտնիություն </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մեր <button
        style={{
            textDecoration:'underline'
        }}
        onClick={() => {
        this.props.handlePolicy()
        this.props.handleTerms()
    }}>Գաղտնիության քաղաքականությունը</button>{" "}  նկարագրում է, թե ինչպես ենք մենք հավաքում, օգտագործում, տրամադրում կամ որևէ կերպ մշակում Ձեր և Ձեր կողմից Ծառայություններից օգտվելու վերաբերյալ տեղեկատվությունը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
      }}> 3․ Համայնքի կանոնները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք կարող եք օգտագործել Բրեյնոգրաֆը միայն կրթական նպատակներով։ Դուք պատասխանատու եք այն ամողջ բովանդակության համար, որը տեղադրում եք մեր հարթակում։ Մեր հարթակում Ձեր կարծիքները, հարցերը, հրապարակումները և այլ բովանդակությունը պետք է համապատասխանեն սույն կանոններին և օրենքին, և հարգեն այլոց մտավոր սեփականության իրավունքը։ Մենք մեզ իրավունք ենք վերապահում արգելափակելու Ձեր օգտահաշիվը շարունակական կամ կոպիտ խախտումների դեպքերում։ Եթե կարծում եք, որ մեկ ուրիշը խախտում է Ձեր հեղինակային իրավունքները մեր հարթակում, տեղեկացրեք մեզ այդ մասին՝ կապ հաստատելով հետևյալ էլ․ հասցեով՝ info@brainograph.com։&nbsp;&nbsp;&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Դուք իրավունք չունեք մուտք գործել կամ օգտվել մեր Ծառայություններից կամ օգտահաշիվ ստեղծել օրինազանց նպատակներով։ Ձեր կողմից Ծառայությունների օգտագործումը և մեր հարթակում Ձեր վարքագիծը պետք է լինեն Հայաստանի Հանրապետության գործող տեղական կամ համապետական օրենքների և կանոնակարգերի շրջանակներում: Ձեզ վերաբերող օրենքների և կանոնակարգերի իմացության և դրանց համապատասխան գործելու համար պատասխանատվությունը կրում եք բացառապես Դուք: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Եթե մենք տեղեկանանք, որ Ձեր բովանդակությունը կամ վարքագիծը խախտում են օրենքը կամ այլոց իրավունքները (օրինակ՝ եթե դրանք խախտում են այլոց մտավոր սեփականության կամ հեղինակային իրավունքները, կամ վերաբերում են անօրինական գործունեության), սույն Համայնքի կանոնները, կամ եթե մենք հավատացած լինենք, որ Ձեր բովանդակությունը կամ վարքագիծն օրինազանց են, անհարկի կամ առարկելի, մենք մեզ իրավունք ենք վերապահում հեռացնելու Ձեր բովանդակությունը մեր հարթակից: Բրեյնոգրաֆն առաջնորդվում է հեղինակային իրավունքի մասին օրենքներով: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Մենք կարող ենք սահմանափակել կամ դադարեցնել մեր հարթակից կամ&nbsp; Ծառայություններից օգտվելու Ձեր թույլտվությունը կամ արգելափակել Ձեր օգտահաշիվը ցանկացած ժամանակ՝ ծանուցմամբ կամ առանց ծանուցելու, որևէ պատճառով կամ առանց որևէ պատճառաբանության, սույն Պայմանների կամ Համայնքի կանոնների որևէ խախտման, խարդախությամբ վճարի վերադարձ պահանջելու, իրավապահ կամ պետական մարմինների հայցմամբ, երկարաժամկետ չօգտագործման, անսպասելի տեխնիկական հարցերի կամ խնդիրների առաջացման դեպքում, եթե մենք կասկածում ենք, որ Դուք զբաղվում եք խարդախ կամ անօրինական գործունեությամբ, կամ որևէ այլ պատճառով՝ մեր հայեցողությամբ: Ցանկացած նման դադարեցման դեպքում մենք կարող ենք ջնջել Ձեր օգտահաշիվը և բովանդակությունը, և կանխել Ձեր հետագա հասանելիությունը հարթակին և Ծառայություններին: Մենք պատասխանատվություն չենք կրում Ձեր կամ որևէ երրորդ կողմի առջև Ձեր օգտահաշվի դադարեցման, Ձեր բովանդակության հեռացման կամ մեր հարթակին և Ծառայություններին Ձեր մուտքի արգելափակման համար: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        lineHeight: "106%"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "106%",
      }}> 4.&nbsp; Մտավոր սեփականություն և հեղինակային իրավունք </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բերյնոգրաֆի և դրա Ծառայությունների հետ կապված բոլոր իրավունքները, արտոնագրերը և շահերը, այդ թվում՝ կայքէջը, հետագա հավելվածները, Կիրառական ծրագրավորման միջերեսը ((ԿԾՄ)API), տվյալների բազաները և բովանդակությունը, որը մեր աշխատակիցները կամ գործընկերները ներբեռնում կամ տրամադրում են մեր Ծառայությունների միջոցով (բացառությամբ՝ օգտատերերի կողմից սեփական օգտագործման համար ստեղծված բովանդակության) Բրեյնոգրաֆի և նրա լիցենզիատորների </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> {" "} </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> բացառիկ սեփականությունն է: Մեր հարթակը և Ծառայությունները պաշտպանված են Հեղինակային իրավունքի և հարակից իրավունքների մասին ՀՀ օրենքով, Ապրանքային նշանների մասին ՀՀ օրենքով, և ՀՀ այլ օրենքներով: Դուք չեք կարող օգտագործել Բրեյնոգրաֆի անունը կամ ապրանքանիշը, լոգոն, դոմեյնը կամ ապրանքանիշը տարբերակող այլ հատկանիշ։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
    }}> Բրեյնոգրաֆ մուտք գործելով և օգտվելով Ծառայություններից՝ Դուք իրավունք չունեք կատարելու հետևյալ քայլերը՝&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: ".0001pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> մուտք գործել կամ օգտագործել հարթակի ոչ հրապարակային տարածքները (ներառյալ բովանդակության պահպանման տարածքը), Բրեյնոգրաֆի համակարգչային համակարգերը կամ Բրեյնոգրաֆի համար ծառայություններ մատուցողների տեխնիկական ապահովման համակարգերը: </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Անջատել, միջամտել կամ փորձել շրջանցել հարթակի՝ անվտանգության հետ կապված որևէ կարգավորում, կամ մանրազնին քննել, արտատպել կամ փորձարկել մեր համակարգերից որևէ մեկի խոցելիությունը: </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Պատճենել, փոփոխել, ստեղծել հետադարձ ինժեներիայի ( </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> reverse engineer </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> ), հետադարձ հավաքման ( </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> reverse assemble){" "} </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> միջոցներ կամ որևէ այլ ձևով փորձել հայտնաբերել Բրեյնոգրաֆի և դրա Ծառայությունների ելակետային կոդերը և բովանդակությունը: </span>
                    </p>
                    <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "-.25in" }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "} </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Մուտք գործել կամ որոնել կամ փորձել մուտք գործել կամ որոնել մեր հարթակում ցանկացած եղանակով (ավտոմատացված կամ այլ), քան ներկայումս հասանելի մեր որոնողական ֆունկցիաներն են, որոնք տրամադրվում են մեր կայքի, բջջային հավելվածների կամ ԿԾՄ-ի միջոցով (և միայն այդ ԿԾՄ-ի օգտագործման Պայմաններին համաձայն)։ Դուք չպետք է վնասեք, ռոբոտի կամ որևէ այլ ավտոմատ միջոցներով մուտք գործեք Ծառայություններ: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: ".5in",
                        textIndent: "-.25in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Times New Roman",serif'
    }}> ● <span style={{ font: '7.0pt "Times New Roman"' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
    </span>
                        <span lang="EN" style={{
                            fontSize: "12.0pt",
                            lineHeight: "115%",
                        }}> Որևէ կերպ օգտագործել Ծառայությունները խեղաթյուրված, ապակողմոնորոշող կամ աղբյուրը նույնականացնող կեղծ տեղեկատվություն ուղարկելու համար (ինչպես օրինակ՝ էլեկտրոնային փոստով հաղորդագրության ուղարկումը՝&nbsp; հանդես գալով կեղծ Բրեյնոգրաֆի անունից), միջամտել կամ խանգարել (կամ փորձել դա անել) որևէ օգտատիրոջ, հոսթի կամ ցանցի մուտքը հարթակ, ներառյալ և առանց սահմանափակման հարթակների կամ ծառայությունների վիրուսներ ուղարկելու, գերբեռնման, հեղեղման, փոստաղբի (սփամ) կամ փոստով ռմբակոծման, կամ որևէ այլ եղանակով Բրեյնոգրաֆի Ծառայություններին միջամտելը կամ դրանց համար ավելորդ բեռ ստեղծելը։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 5. Տարաբնույթ իրավական պայմաններ </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Դուք համաձայնում եք, որ գրանցվելով, մուտք գործելով կամ օգտվելով մեր Ծառայություններից՝ Դուք Բրեյնոգրաֆի հետ կնքում եք իրավաբանորեն պարտադիր ուժ ունեցող պայմանագիր։ Եթե ​​դուք համաձայն չեք սույն Պայմաններին, մի գրանցվեք, մուտք մի գործեք կամ որևէ այլ կերպ մի օգտվեք մեր Ծառայություններից։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Դուք պետք է ապահովագրեք և անվնաս պահեք Բրեյնոգրաֆը (իր տնօրենների, աշխատակիցների, գործակալների, կապալառուների, մատակարարների, լիցենզիարների, իրավահաջորդների, հովանավորների հետ միասին) գործող օրենքի թույլատրելի առավելագույն չափով՝ ցանկացած և բոլոր պարտավորություններից, պահանջներից, վնասներից, ծախսերից կամ կորուստներից: Բրեյնոգրաֆը չի փոխհատուցի Ձեր՝ Ծառայությունների օգտագործման, սույն Պայմանների կամ երրորդ անձանց իրավունքների խախտման հետևանքով առաջացած ծախսերը: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Բրեյնոգրաֆը, իր գործընկեր կազմակերպությունները, լիցենզիատորները, հովանավորները, գործակալները, տնօրենները, աշխատակիցները, իրավահաջորդները ոչ մի դեպքում պատասխանատվություն չեն կրում Ձեր կամ երրորդ կողմի առջև Ձեր կողմից հրապարակված կամ Ծառայություններին մուտք գործելու կամ դրանք օգտագործելու արդյունքում Ձեզ համար առաջացած ցանկացած տեսակի ծախսերի, կորցրած շահույթի, տվյալների կորստի, վնասների, դրաց փոխհատուցման, կամ ցանկացած այլ անձնական կամ սեփականության, համակարգի ծրագրային վնասների, տեխնիկական խնդիրների, վիրուսների կամ Ձեր համակարգչին կամ այլ տեխնիկային հնարավոր սպառնալիքի, վնասի կամ կորստի համար։&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 6. Պատասխանատվությունից հրաժարումը </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> Ծառայություններն ու դրանց բովանդակությունը տրամադրվում են{" "} </span>
                        </u>
                        <u>
                            <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        </u>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> ինչպես որ է </span>
                        </u>
                        <u>
                            <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        </u>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> և{" "} </span>
                        </u>
                        <u>
                            <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> « </span>
                        </u>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> ինչպես հասանելի է </span>
                        </u>
                        <u>
                            <span lang="EN" style={{ fontFamily: '"Calibri",sans-serif' }}> » </span>
                        </u>
                        <u>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> հիմունքներով։ Մենք (և մեր մասնաճյուղերը, մատակարարները, գործընկերները և գործակալները) չենք տալիս խոստումներ կամ երաշխիքներ Ծառայությունների կամ դրանց բովանդակության համապատասխանության,&nbsp; արժանահավատության, հասանելիության, արդիականության, անվտանգության, սխալների բացակայության կամ ճշգրտության վերաբերյալ և հրապարակայնորեն հրաժարվում ենք որևէ երաշխիքների կամ պայմանների տրամադրումից (ակնհայտ կամ ենթադրյալ), ներառյալ՝ ենթադրյալ կոմերցիոն օգտակարության, կոնկրետ նպատակի ծառայելու, արտոնագրի կամ խախտումների երաշխիքները: </span>
                        </u>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Բրեյնոգրաֆը չի խոստանում կամ երաշխավորում, որ ա) Ծառայությունները լինելու են անսխալ, անվտանգ կամ առանց դադարների, բ) հարթակում տեղադրված տեղեկատվությունը կամ նյութերը ճշգրիտ են, հավաստի կամ օգտակար, գ) Ծառայություններից Ձեր օգտվելը օրինական է որևէ կոնկրետ օրենքով կամ կարգավորումներով, դ) օգտատիրոջ՝ սեփական օգտագործման համար նախատեսված բովանդակությունը չի դառնա հանրային, ե) Ծառայությունները զերծ են որևէ վնասող բաղադրիչներից, զ) տեխնիկական և այլ խնդիրներն անհապաղ կշտկվեն։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Դուք համաձայնում եք և ընդունում, որ Բրեյնոգրաֆի Ծառայություններից օգտվում եք անձնական պատասխանատվությամբ, իսկ Բեյնոգրաֆը չի խրախուսում որևէ օգտատիրոջ բովանդակությունը կամ երրորդ անձանց բովանդակությունը: Բրեյնոգրաֆը հրաժարվում է որևէ պարտավորություն ստանձնել կամ պատասխանատվություն կրել Ձեր, երրորդ կողմի կամ կազմակերպության առջև ցանկացած տեսակի կորուստների, վնասների, ծախսերի համար, որոնք առաջանում կամ բխում են Ձեր՝ մեր Ծառայությունների օգտագործման հետևանքով: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Մենք կարող ենք ցանկացած պահի և ցանկացած պատճառով որոշել դադարեցնել Ծառայությունների որոշակի ծավալի հասանելիությունը: Ոչ մի դեպքում Բրեյնոգրաֆը կամ նրա դուստր ձեռնարկությունները, մատակարարները, գործընկերները կամ գործակալները պատասխանատվություն չեն կրում նման ընդհատումների կամ հասանելիության բացակայության պատճառով հասցված վնասների համար: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Մենք պատասխանատվություն չենք կրում Ծառայությունների որևէ ծավալի ընդհատման կամ ուշացման համար, որոնք մեր ողջամիտ վերահսկողությունից դուրս են․ այդպիսիք կարող են լինել պատերազմը, թշնամանքի կամ դիվերսիայի գործողությունները, բնական աղետները, էլեկտրականության, համացանցի կամ հեռահաղորդակցության անջատումը կամ կառավարության կողմից սահմանված սահմանափակումները: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 7. Պատասխանատվության սահմանափակում </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Մեր Ծառայություններից օգտվելը կարող է առաջացնել որոշակի ռիսկեր, որոնք Դուք լիովին ընդունում եք՝ համաձայնելով սույն Պայմաններին: Դուք համաձայնում եք, որ Դուք չեք օգտվի որևէ միջոցից մեզանից վնասների փոխհատուցում հայցելու համար, նույնիսկ եթե Դուք կորուստ կամ վնաս եք կրել մեր հարթակից կամ Ծառայություններից օգտվելու արդյունքում: Օրենքի թույլատրելի սահմաններում մենք (և մեր խմբի ընկերությունները, մատակարարները, գործընկերները և գործակալները) չենք կրում որևէ պատասխանատվություն անուղղակի, պատահական, տուգանային կամ ածանցյալ վնասների համար (ներառյալ տվյալների, եկամտի, շահույթի, բիզնես հնարավորությունների կորուստը, կամ անձնական վնասը կամ մահը), լինի դա պայմանագրից, երաշխիքից, իրավախախտումից, պրոդուկտի որակի համար պատասխանատվությունից կամ այլ աղբյուրից բխող, և նույնիսկ այն դեպքում, եթե մենք նախապես տեղեկացված ենք եղել հնարավոր վնասների վերաբերյալ: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 8. </span>
                        </b>
                        <span lang="EN" style={{
                            fontSize: "7.0pt",
                            lineHeight: "115%",
                            fontFamily: '"Times New Roman",serif'
                        }}> &nbsp;&nbsp;&nbsp; </span>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> Կիրառելի օրենսդրությունը և վեճերի կարգավորումը{" "} </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Սույն Պայմանները, մեր Գաղտնիության քաղաքականությունը կամ Բրեյնոգրաֆի կողմից ներկայացված ցանկացած այլ լրացուցիչ պայմանները կարգավորվում են Հայաստանի Հանրապետության օրենքներով և կանոնակարգերով՝ առանց հակասությունների տեղիք տալու: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Դուք համաձայնում եք, որ Ձեր և Բրեյնոգրաֆի միջև որևէ անհամաձայնության դեպքում (ներառյալ՝ Ձեր և մեր երրորդ կողմի գործակալի միջև), Դուք նախ և առաջ կապ կհաստատեք մեզ հետ և կգործադրեք անհրաժեշտ բարի կամք խնդիրը լուծելու համար՝ խուսափելով առավել պաշտոնական միջոցների կիրառումից, այդ թվում՝ դատական հայցերից, և որ հստակորեն կներկայացնեք վեճի հիմքերն ու բնույթը՝ Ձեր դիրքորոշմանն աջակցող կոնկրետ փաստերով։ Անգամ եթե Վեճերի լուծման հետ կապված սույն բաժնում ասվում է հակառակը, Բրեյնոգրաֆը կարող է հասնել իրավական և արդարացի պաշտպանության համապատասխան իրավասության դատարանում իր մտավոր սեփականության և իր մատակարարների իրավունքները պաշտպանելու նպատակով: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Այն դեպքում, երբ վեճը չի կարող լուծվել փոխադարձ համաձայնությամբ, Դուք համաձայնում եք, որ Ձեր և Բրեյնոգրաֆի կամ Ձեր և մեր երրորդ կողմի գործակալի միջև ցանկացած պահանջ, հակընդդեմ պահանջ, վեճ կամ հակասություն կամ այլ խնդիրներ («Հայց») պետք է լուծվեն Հայաստանի Հանրապետության դատարաններում։ </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 9. Սույն պայմանների թարմացումները </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Խնդրում ենք նկատի ունենալ, որ սույն Պայմանները ժամանակ առ ժամանակ կարող են փոփոխվել: Նշանակալի փոփոխությունների դեպքում մենք Ձեզ այդ մասին կտեղեկացնենք 30 օր առաջ էլեկտրոնային փոստով՝ համապատասխան հղմամբ ուղղորդելով դեպի փոփոխված Պայմաններ։ Շարունակելով մուտք գործել մեր կայքէջ, Ձեր օգտահաշիվ և օգտվել մեր Ծառայություններից՝ Դուք շարունակում եք համաձայնվել մեր Պայմաններին: Փոփոխություններն ուժի մեջ են մտնում հրապարակման պահից, եթե դրանցում այլ բան նշված չէ: </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Փոփոխությունների կիրառումից հետո մեր Ծառայություններից շարունակաբար օգտվելը նշանակում է, որ Դուք ընդունում եք այդ փոփոխությունները։&nbsp;&nbsp;{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
                        <b>
      <span lang="EN" style={{
          fontSize: "12.0pt",
          lineHeight: "115%",
          fontFamily: '"Tahoma",sans-serif'
      }}> 10. Կապ մեզ հետ </span>
                        </b>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Դուք կարող եք մեզ հետ կապ հաստատել հետևյալ էլ․ հասցեով՝ info@brainograph.com, եթե ունեք հարցեր, մտահոգություններ կամ արձագանք մեր Ծառայությունների մասին։{" "} </span>
                    </p>
                    <p className="MsoNormal" style={{
                        marginTop: "12.0pt",
                        marginRight: "0in",
                        marginBottom: "12.0pt",
                        marginLeft: "0in"
                    }}>
    <span lang="EN" style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Tahoma",sans-serif'
    }}> Շնորհակալություն։{" "} </span>
                    </p>
                    <p className="MsoNormal">
                        <span lang="EN">&nbsp;</span>
                    </p>
                </div>

            </div>
        );
    }
}

export default TermsOfUse;
