import { connect } from "react-redux";
import "./style.css";
import DynamicTitle from "../../CustomCardComponents/DynamicTitle";
import Line from "../../CustomCardComponents/Line";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import ScreenShotEdit from "../ScreenShotEdit";
import {useTranslation} from "react-i18next";
import {Button} from "../../Button";
import React from "react";

const cardBodyStyle = {
  aspectRatio: "677/255",
  gridTemplateRows: "1fr 1fr",
  padding: "20px",
};
const WarningLanguage = (props) => {
  const { onClose, item,onSubmit,isScreenShot } = props;
  const {t} = useTranslation()
  return (
    <div className="card-body__presentation-warning">
      <CardBody hasIcon={false} styles={cardBodyStyle}>
        <div className={"dynamic-screen-shot-warning-content__header"}>
          <DynamicTitle style={{justifyContent:'center'}} content={t('presentationSettings.warning')} />
          <Line />
        </div>
        <div className={"dynamic-screen-shot-warning-content__body"}>
          {
            isScreenShot
                ? <p>{t('presentationSettings.screenShotWarningDescription')}</p>
                : <p>{t('presentationSettings.warningDescription')}</p>
          }

        </div>
        <div className={"dynamic-screen-shot-warning-content__footer"}>
        <Button
              className={"dynamic-screen-shot-warning-content__footer-btn dynamic-screen-shot-warning-content__footer-cancel-btn"}
              action={onClose}
              text={t('cancel')}
          />
          <Button
              className={"dynamic-screen-shot-warning-content__footer-btn dynamic-screen-shot-warning-content__footer-success-btn"}
              action={()=>onSubmit(item)}
              text={t('continue')}
          />
        </div>
      </CardBody>
    </div>
  );
};
export default connect(null, null)(WarningLanguage);
