const Sort = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
            <title>sorting</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
                <g id="Finding" transform="translate(-979.000000, -891.000000)" fillRule="nonzero" stroke="#2C476C" strokeWidth="2.4">
                    <g id="Group-29" transform="translate(103.000000, 839.000000)">
                        <g id="sorting" transform="translate(877.765849, 53.750000)">
                            <path d="M11.7800039,5.88995361 L5.88995361,0 L0,5.88995361" id="Vector"/>
                            <line x1="5.88995361" y1="28.5" x2="5.88995361" y2="0" id="Vector"/>
                            <path d="M16.6883952,22.6100464 L22.5784454,28.5 L28.468399,22.6100464" id="Vector"/>
                            <line x1="22.5783488" y1="0" x2="22.5783488" y2="28.5" id="Vector"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default Sort;
