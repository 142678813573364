import {useTranslation} from "react-i18next";

export const checkValidationErrors = (fields, t) => {
  let errors = {};
  let hasError = false;

  if (fields["selectedLoginType"] !== "Phone" && !fields["email"]) {
    hasError = true;
    errors["email"] = t('errors.emptyEmail');
  }
  if (fields["selectedLoginType"] === "Phone" && !fields["phoneNumber"]) {
    hasError = true;
    errors["phoneNumber"] = t('errors.emptyPhone');
  }

  if (fields["phoneNumber"] &&
      fields["selectedLoginType"] === "Phone" &&
      fields['phoneCode'] == '374' &&
      fields['phoneNumber'].length !== 8 ) {
    hasError = true
    errors["phoneNumber"] = t('errors.incorrectPhone')
  }

  //Name
  if (!fields["password"]) {
    hasError = true
    errors["password"] = t('errors.emptyPassword');;
  }

  if (typeof fields["email"] !== "undefined" && fields["selectedLoginType"] !== "Phone") {
    const lastAtPos = fields["email"].lastIndexOf("@");
    const lastDotPos = fields["email"].lastIndexOf(".");

    if (
      !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2 &&
          !/\s/.test(fields['email'])
      )
    ) {
      hasError = true;
      errors["email"] = t('errors.incorrectEmail');
    }
  }

  return hasError ? errors : null;
};
