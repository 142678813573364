
const tooltipRenderer = (text,content) => {
    return `
    <span class="br-tooltip variant-italic">${text}
      <span class="br-tooltiptext">${content}</span>
    </span>
    `
}

export const getGeneralInforimation = (el,articleData, additionalData = {},t) =>{
    const hasTimeRangeProvisional = articleData[0]?.articleData?.isTimeRangeProvisional;
    const hasStartOrEndDate = articleData[0]?.articleData?.startYear || articleData[0]?.articleData?.endYear;
    const hasStartAndEndDate = articleData[0]?.articleData?.startYear  && articleData[0]?.articleData?.endYear
    const startDate = articleData[0]?.articleData?.startYear;
    const endDate = articleData[0]?.articleData?.endYear
    const isStartDateApproximate = articleData[0]?.articleData?.isStartDateApproximate;
    const isEndDateApproximate = articleData[0]?.articleData?.isEndDateApproximate
    const isStartTimeIsBC = articleData[0]?.articleData?.startTimeIsBC
    const isEndTimeIsBC = articleData[0]?.articleData?.endTimeIsBC
    return {
        ...el,
        content:[{
            ...el.content[0],
            title: t('articleReadMore.' + el.content[0]?.title || '' ) ,
            subTitle:additionalData?.label,
            content:[{
                id:`${el.id}${el.id}`,
                title:
                    `${hasTimeRangeProvisional ? tooltipRenderer(t('timeExpressions.cond'), t('timeExpressions.conditional')) : ''} 
                        ${hasStartOrEndDate
                            ?(
                                `
                                ${startDate
                                            ?(`${isStartDateApproximate ? tooltipRenderer(hasTimeRangeProvisional ? t('timeExpressions.approx') : t('timeExpressions.approx'), t('timeExpressions.approximate')) : ''} ${isStartTimeIsBC ? t('bc') : ''} ${startDate}`)
                                            :''
                                        } 
                                ${!endDate
                                            ? (`- ${isEndDateApproximate ? tooltipRenderer(t('timeExpressions.approx'), t('timeExpressions.approximate')) : ''} ${t('timeExpressions.untilToday')}`)
                                            : ''
                                        }
                                ${(hasStartAndEndDate) ? '-' : ''  } 
                                ${!startDate
                                             ? (t('timeExpressions.until'))
                                            : ''
                                        } 
                                ${endDate
                                            ? (`${isEndDateApproximate ? `${tooltipRenderer(t('timeExpressions.approx'), t('timeExpressions.approximate'))} ` : ''} ${isEndTimeIsBC ? t('bc') : ''} ${endDate}`)
                                            : ''
                                }`
                            )
                            : " - "
                        }
                    `
            }]
        }]
    }
}
export const getCategorySection = (el,filteredCategories) =>{
    return {
        ...el,
        content: [{
            id:filteredCategories[0]?.id,
            title:filteredCategories[0]?.title,
            iconPath:filteredCategories[0]?.iconPath,
            spriteIcon:filteredCategories[0]?.spriteIcon,
            color: filteredCategories[0]?.color,
            content:filteredCategories[0]?.subCategories
        }]
    }
}
export const getAffiliations = (el,articleData) =>{
    return{
        ...el,
        content:[...articleData[0]?.articleData?.affiliationsInfo]
    }
}
export const getOtherConnectionsInfo = (el,articleData) =>{
    return{
        ...el,
        content:[...articleData[0]?.articleData?.otherConnectionsInfo]
    }
}
export const getTagsInfo = (el,articleData) =>{
    return{
        ...el,
        content:[...articleData[0]?.articleData?.tagsInfo]
    }
}
