import { connect } from "react-redux";
import "./style.css";
import Line from "../../CustomCardComponents/Line";
import {useTranslation} from "react-i18next";

const DynamicAction = (props) => {
  const { edit = () => {}, remove = () => {} } = props;
  const {t} = useTranslation()
  return (
    <div className="card-body__dynamic-actions dynamic-actions">
      <h6 onClick={edit}>{t('edit')}</h6>
      <Line color={"#fff"} />
      <h6 onClick={remove}>{t('delete')}</h6>
    </div>
  );
};
export default connect(null, null)(DynamicAction);
