import {
  CHANGE_MAP_REQUIREMENTS,
  CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT,
} from "../constants";
import { getMapRequirements } from "../../service/mapRequirements";

const initialState = {
  data: [
    {
      id: 1,
      value: "all",
      title: "Բոլորը",
      active: true,
      titleKey: "all"
    },
    {
      id: 2,
      value: "Mandatory",
      title: "Հիմնական",
      active: false,
      titleKey: "main"
    },
    {
      id: 3,
      value: "Additional",
      title: "Հավելյալ",
      active: false,
      titleKey: "additional"
    },
  ],
};

export const mapRequirementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MAP_REQUIREMENTS:
      return {
        ...state,
        data: getMapRequirements(action.payload, initialState.data),
      };
    case CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
