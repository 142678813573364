import { connect } from "react-redux";
import "./style.css";
import DynamicTitle from "../../CustomCardComponents/DynamicTitle";
import Line from "../../CustomCardComponents/Line";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseDark from "../../CloseDark";
import CopyShareLink from "../CopyShareLink";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import {useTranslation} from "react-i18next";

const cardBodyStyle = {
  aspectRatio: "677/255",
  gridTemplateRows: "1fr 1fr",
  padding: "20px",
};
const DynamicScreenShotShare = (props) => {
  const { onClose } = props;
  const {t} = useTranslation()
  return (
    <div className="card-body__dynamic-screen-shot-share-content dynamic-screen-shot-share-content">
      <CardBody hasIcon={false} styles={cardBodyStyle}>
        <div className={"dynamic-screen-shot-share-content__header"}>
          <CloseBtn onClose={onClose} />
          <DynamicTitle content={t('shareItem')} />
          <Line />
        </div>
        <div>
          <CopyShareLink url={props.link} />
        </div>
      </CardBody>
    </div>
  );
};
export default connect(null, null)(DynamicScreenShotShare);
