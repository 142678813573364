import {connect} from "react-redux";
import './style.css'
import React, {useCallback, useState} from "react";
import Like from "../../CustomCardComponents/Like";
import {getArticleIsLikedData, getBookmarksData} from "../../../../store/selectors";
import {likeAricleFromApi} from "../../../../store/actions/articlesAll";
import {
    getBookmarksFromAPI,
    handleGetNewBookmarks,
    handleSetBookmarks,
    setBookmarks
} from "../../../../store/actions/bookmarks";
import ConnectedSpriteImageComponent from "../../ConnectedSpriteImageComponent";
const queryParams = new URLSearchParams(window.location.search);

const BookmarkContentInfo = (props) =>{
    const {content={},likeAricleFromApi,handleUnlikeBookmark} = props
    const [articleIsLiked , setArticleIsliked] = useState(content?.like)
    const handleLikeArticle = useCallback((id)=>{
        const body = {
            'articleId':id
        }
        likeAricleFromApi(body,!articleIsLiked)
            .then(()=>handleUnlikeBookmark())
        setArticleIsliked(prev=>!prev)
    },[articleIsLiked])
    return (
        <div className={'bookmark-content-info'}>
            <div className={'bookmark-content-info__category-info'}>
                <div className={'bookmark-content-info-category-info__icon'} style={{"--category-color":content?.categoryColor}}>
                    {/*<img src={content?.categoryIcon} alt="bookmark-image"/>*/}
                    <ConnectedSpriteImageComponent name={content?.categoryIcon}/>
                </div>
                <p>{content?.categoryTitle}</p>
            </div>
            <div className={`bookmark-like-box`}  data-liked={articleIsLiked}>
                <Like id={content?.id}  liked={articleIsLiked} handleClick={()=>handleLikeArticle(content?.articleId)}/>
            </div>
        </div>
    )
}
const mapStateTopProps = (state) => ({

})
const mapDispatchToProps = {
    likeAricleFromApi:likeAricleFromApi,
}
export default connect(mapStateTopProps, mapDispatchToProps)(BookmarkContentInfo);
