import React, {useEffect, useRef, useState} from 'react';
import {isMobile} from "react-device-detect";
import { ResizableBox } from 'react-resizable';

import {color_picker_data} from "../../../utils/color_picker_data";

import CloseIcon from '../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../assets/imgs/PaintBar/delete-trash-icon.svg';
import BackgroundColorIcon from '../../../assets/imgs/PaintBar/sticky-note-background-color-icon.svg';
import Green from '../../../assets/imgs/PaintBar/StickyNotesPaths/green.svg';
import LightBlue from '../../../assets/imgs/PaintBar/StickyNotesPaths/lightBlue.svg';
import Brown from '../../../assets/imgs/PaintBar/StickyNotesPaths/brown.png';
import Blue from '../../../assets/imgs/PaintBar/StickyNotesPaths/blue.svg';
import Yellow from '../../../assets/imgs/PaintBar/StickyNotesPaths/yellow.svg';
import MarkerIcon from "./MarkerIcon";
import 'react-resizable/css/styles.css';
import "./style.css";
import toolboxStatesWithRedux from "../mapper";


const StickyNotesEditPopup = (props) => {
    const {
        getStickyNotesMarkersState,
        setStickyNotesMarkersState,
        description,
        color,
        background,
        markerWidth,
        markerHeight,
        id,
        isEdited,
        isMaximize,
        left,
        top,
    } = props
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const popupRef = useRef()
    const textareaRef = useRef()
    const [isEditing, setIsEditing] = useState(isEdited);
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingStarted, setIsDraggingStarted] = useState(false);
    const [position, setPosition] = useState({ left, top });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [isMaximized, setIsMaximized] = useState(isMaximize);
    const [text, setText] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [commentColor, setCommentColor] = useState(color || '#FFF8A7');
    const [isOpenColors, setIsOpenColors] = useState(false);
    const [isOpenPatternPicker, setIsOpenPatternPicker] = useState(false);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [width, setWidth] = useState(+markerWidth);
    const [height, setHeight] = useState(+markerHeight);
    const [minConstraints, setMinConstraints] = useState([180, 220]);
    const [maxConstraints, setMaxConstraints] = useState([360, 440]);
    const [rgbaColor, setRgbaColor] = useState('rgba(230, 223, 150, 0.92)')
    const [scrollerColor, setScrollerColor] = useState('#FFF8A7')
    const [uIBackground, setUIBackground] = useState(background)
    const [iconPosition, setIconPosition] = useState({ top: 0, left: 0 });
    const beforeStyle = `
    .sticky-note-editor-${id}::before {
    bottom: -23px;
    border: 12px solid transparent;
    border-right-color: ${rgbaColor};
    border-bottom-color: ${rgbaColor};
    left: 0;
    transform: rotate(270deg);
    z-index: 2;
    background-size: cover;
    }
    .sticky-note-editor-${id}::after {
    background: ${uIBackground};
    position: absolute;
    height: 24px;
    width: calc(100% - 23px);
    bottom: -23px;
    right: 0;
    }
    .sticky-note-editor-${id} textarea::-webkit-scrollbar-track {
      background-color: ${scrollerColor};
    }`
    const [colorsForStickyNotesColorPicker, setColorsForStickyNotesColorPicker] = useState([
        { id: 1, color: "#000000", status: 0 },
        { id: 2, color: "#2C476C", status: 0 },
        { id: 3, color: "#157EFA", status: 0 },
        { id: 4, color: "#53D669", status: 0 },
        { id: 5, color: "#FFF8A7", status: 1 },
        { id: 6, color: "#FC3142", status: 0 },
        { id: 7, status: 0 },
    ]);
    const patternsPicker = [
        { id: 1, path: Brown, status: 0, rgb: {r: 210, g: 185, b: 155} },
        { id: 2, path: Green, status: 0, rgb: {r: 190, g: 246, b: 139} },
        { id: 3, path: LightBlue, status: 0, rgb: {r: 101, g: 244, b: 221} },
        { id: 4, path: Blue, status: 0, rgb: {r: 112, g: 183, b: 241} },
        { id: 5, path: Yellow, status: 0, rgb: {r: 255, g: 248, b: 167} },
    ];
    function hexToRgb(hex) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r, g, b };
    }

    function blendColors(rgb1, rgb2, alpha1, alpha2) {
        const r = Math.round(rgb1.r * alpha1 + rgb2.r * alpha2 * (1 - alpha1));
        const g = Math.round(rgb1.g * alpha1 + rgb2.g * alpha2 * (1 - alpha1));
        const b = Math.round(rgb1.b * alpha1 + rgb2.b * alpha2 * (1 - alpha1));
        return `rgba(${r},${g},${b},${alpha1 + alpha2 * (1 - alpha1)})`;
    }
    const changeColorsForStickyNotesColorPicker = (colorHEX) => {
        const updatedColors = colorsForStickyNotesColorPicker.map(color => {
            if (color.color === colorHEX) {
                return { ...color, status: 1 };
            } else {
                return { ...color, status: 0 };
            }
        });
        setColorsForStickyNotesColorPicker(updatedColors);
    };

    const handleTextareaText = (e) => {
        setText(e.target.value);
    };

    const setStickyInfoParamByID = (id, param, value) => {
        const index = getStickyNotesMarkersState.findIndex(marker => marker.id === id);
        if (index !== -1) {
            const newMarkers = [...getStickyNotesMarkersState];
            newMarkers[index][param] = value;
            setStickyNotesMarkersState(newMarkers)
        } else {
            console.error(`Marker with id ${id} not found.`);
        }
    };

    const handleSave = (id, text, background, markerColor, isMaximized, width, height, top, left) => {
        setStickyInfoParamByID(id, 'id', id);
        setStickyInfoParamByID(id, 'description', text);
        setStickyInfoParamByID(id, 'markerColor', markerColor ? markerColor : "#FFF8A7");
        setStickyInfoParamByID(id, 'background', background ? background : "#FFF8A7");
        setStickyInfoParamByID(id, 'markerWidth', width ? width.toString() : 180);
        setStickyInfoParamByID(id, 'markerHeight', height ? height.toString() : 220);
        setStickyInfoParamByID(id, 'isMaximized', isMaximized);
        setStickyInfoParamByID(id, 'isEdited', false);
        setStickyInfoParamByID(id, 'top',top) ;
        setStickyInfoParamByID(id, 'left', left)
    }
    const handleSaveWrapper = () => {
        setScrollerColor(commentColor)
        handleSave(id, text, uIBackground, commentColor, isMaximized, width, height, position.top, position.left);
    };

    const handleDelete = (id) => {
        const note = getStickyNotesMarkersState.find(note => note.id === id)
        if(!note.isMaximized) return
        const filteredData = getStickyNotesMarkersState.filter(note => note.id !== id)
        setStickyNotesMarkersState(filteredData)
    }

    const handleCloseWrapper = () => {
        const currentText = popupRef.current.querySelector('textarea')?.value?.trim();
        setIsEditing(false);
        setIsOpenPatternPicker(false);
        setIsOpenColors(false);
        setIsOpenColorPicker(false);
        textareaRef.current?.blur();
        !currentText.length && handleDelete(id);
    };

    const toggleColorsDropdown = () => {
        if (isOpenColorPicker && isOpenColors) {
            setIsOpenColorPicker(false);
        }
        setIsOpenColors(!isOpenColors);
        setIsOpenPatternPicker(false)
    };

    const togglePatternsDropdown = () => {
        setIsOpenColors(false);
        setIsOpenColorPicker(false);
        setIsOpenPatternPicker(!isOpenPatternPicker);
    };

    const isDarkColor = (color) => {
        let r, g, b;
        if (color.startsWith('#')) {
            const hex = color.slice(1);
            r = parseInt(hex.substr(0, 2), 16);
            g = parseInt(hex.substr(2, 2), 16);
            b = parseInt(hex.substr(4, 2), 16);
        } else {
            [r, g, b] = color.match(/\d+/g).map(Number);
        }

        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
        return luminance < 128;
    };

    const toggleTextColor = (color) => {
        if (isDarkColor(color)) {
            setTextColor('#fff');
        } else {
            setTextColor('#000');
        }
    }
    const setRgba = (color) => {
        const newColorRgb = hexToRgb(color);
        const blendedColor = blendColors(newColorRgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
        setScrollerColor(blendedColor);
        setRgbaColor(blendedColor)
    }
    const colorSelectHandler = (e, selectedColor) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedColor.id === colorsForStickyNotesColorPicker.length) {
            setIsOpenColorPicker(!isOpenColorPicker);
            return;
        }
        toggleTextColor(selectedColor.color)
        setCommentColor(selectedColor.color);
        setUIBackground(selectedColor.color)
        setRgba(selectedColor.color)
        changeColorsForStickyNotesColorPicker(selectedColor.color);
    };
    const pathSelectHandler = (e, selectedPath) => {
        e.preventDefault();
        e.stopPropagation();
        setTextColor('#000')
        setCommentColor('#FFF8A7');
        const colorRgb = selectedPath.rgb
        const blendedColor = blendColors(colorRgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
        setUIBackground(`url(${selectedPath.path})`);
        setScrollerColor(colorRgb);
        setRgbaColor(blendedColor);
        changeColorsForStickyNotesColorPicker(blendedColor);
    };

    const handleMaximizeOrMinimize = (e) => {
        if (isDraggingStarted) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingStarted(false);
            textareaRef.current?.blur();
            return;
        }
        setIsMaximized(!isMaximized)
    }

    const handleClickOutside = (e) => {
        if(!isMaximized) return
        const currentText = popupRef.current.querySelector('textarea')?.value?.trim();
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setIsEditing(false);
            setIsOpenPatternPicker(false)
            setIsOpenColors(false)
            setIsOpenColorPicker(false)
            !currentText?.length && handleDelete(id);
            textareaRef?.current?.blur();
        }
    };

    const handleResize = (e, {size}) => {
        e.preventDefault()
        e.stopPropagation()
        setHeight(size.height)
        setWidth(size.width)
    }


    const handleClickInside = (e) => {
        if (isDraggingStarted || e.target.closest('.markerIcon') || e.target.closest('.popUp-close')) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingStarted(false);
            textareaRef.current?.blur();
            return;
        }
        setIsEditing(true);
    };

    const handleMouseDown = (e) => {
        if ((e.target.className === 'react-resizable-handle react-resizable-handle-se') ||
            (isEditing && e.target.className === 'sticky_textarea')) {
            return;
        }
        const component = popupRef.current;
        if(textareaRef.current) {
            textareaRef.current.classList.add('no-select');
            textareaRef.current.classList.add('custom-selection');
        } else {
            document.body.classList.add('no-select');
        }
        const rect = component.getBoundingClientRect();
        const rectLeft = isMaximized ? rect.left : rect.left - (width/2) + 12
        const rectTop = isMaximized ? rect.top : rect.top + 24
        const clientX = isMobile ? e.changedTouches?.[0]?.clientX : e.clientX
        const clientY = isMobile ? e.changedTouches?.[0]?.clientY : e.clientY
        if(clientX && clientY) {
            textareaRef.current?.blur();
            const offsetX = clientX - rectLeft;
            const offsetY = clientY - rectTop;
            setOffset({ x: offsetX, y: offsetY });
            setIsDragging(true);
            setIsDraggingStarted(false);
        }
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const component = popupRef.current;
        const rect = component.getBoundingClientRect();
        const clientX = isMobile ? e.changedTouches[0].clientX : e.clientX
        const clientY = isMobile ? e.changedTouches[0].clientY : e.clientY
        const newLeft = clientX - offset.x;
        const newTop = clientY - offset.y;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const maxLeft = viewportWidth - rect.width;
        const maxTop = viewportHeight - rect.height;

        const limitedLeft = Math.max(0, Math.min(newLeft, maxLeft));
        const limitedTop = Math.max(0, Math.min(newTop, maxTop));

        !isMobile && textareaRef.current && textareaRef.current?.focus()

        setPosition({
            left: limitedLeft,
            top: limitedTop,
        });
        setTimeout(() => {setIsDraggingStarted(true)}, 100)
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        if(textareaRef.current) {
            textareaRef.current.classList.remove('no-select');
            textareaRef.current.classList.remove('custom-selection');
        } else {
            document.body.classList.add('no-select');
        }
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener(isMobile ? 'touchmove' : 'mousemove', handleMouseMove);
            document.addEventListener(isMobile ? 'touchend' : 'mouseup', handleMouseUp);
        } else {
            document.removeEventListener(isMobile ? 'touchmove' : 'mousemove', handleMouseMove);
            document.removeEventListener(isMobile ? 'touchend' : 'mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener(isMobile ? 'touchmove' : 'mousemove', handleMouseMove);
            document.removeEventListener(isMobile ? 'touchend' : 'mouseup', handleMouseUp);
        };
    }, [isDragging]);

    useEffect(() => {
        document.addEventListener(isMobile ? 'touchstart' : 'mousedown', handleClickOutside);
        return () => {
            document.removeEventListener(isMobile ? 'touchstart' : 'mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const minWidth = window.innerWidth / 10 > 140 ? window.innerWidth / 10 : 140
            const minHeight = window.innerHeight / 4.36 > 200 ? window.innerHeight / 4.36 : 200
            const maxWidth = window.innerWidth / 5 > 200 ? window.innerWidth / 5  : 200
            const maxHeight = window.innerHeight / 2.18 > 200 ? window.innerHeight / 2.18 : 200
            setMinConstraints([minWidth, minHeight]);
            setMaxConstraints([maxWidth, maxHeight]);
        };
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        changeColorsForStickyNotesColorPicker(commentColor);
    }, [commentColor]);

    useEffect(() => {
        let positionUpdated = false;
        if (description) {
            setText(description);
        }
        if(color) {
            const rgb = hexToRgb(color)
            setCommentColor(color)
            const blendedColor = blendColors(rgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
            setRgbaColor(blendedColor);
        }
        if(background) {
            let rgb;
            if(background.includes('url')) {
                const strippedUrl2 = background.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
                rgb = patternsPicker.find(el => el.path === strippedUrl2).rgb
            } else {
                rgb = hexToRgb(background)
            }
            const blendedColor = blendColors(rgb, { r: 0, g: 0, b: 0 }, 0.9, 0.2);
            setRgbaColor(blendedColor);
            toggleTextColor(blendedColor)
            setUIBackground(background)
        }
        if(markerWidth && markerHeight) {
            if(markerWidth > windowWidth / 5) {
                setWidth( windowWidth / 5 > 140 ? windowWidth / 5 : 140)
            }
            if (markerHeight > windowHeight / 2.18) {
                setHeight(windowHeight / 2.18 > 200 ? windowHeight / 2.18 : 200)
            }
            if (markerWidth < windowWidth / 10) {
                setWidth(windowWidth / 10 > 140 ? windowWidth / 10 : 140)
            }
            if (markerHeight < windowHeight / 4.36) {
                setHeight(windowHeight / 4.36 > 200 ? windowHeight / 4.36 : 200)
            }
        } else {
            setHeight(220)
            setWidth(180)
        }
        setIsMaximized(isMaximize)
        if(left > windowWidth - width) {
            const calcLeft = windowWidth - width
            setPosition({top, left: calcLeft})
            positionUpdated = true
        }
        if(top > windowHeight - height) {
            const calcTop = windowHeight - height
            setPosition({top: calcTop, left})
            positionUpdated = true
        }
        if(!positionUpdated) {
            setPosition({top, left, width})
        }
    }, [color, left]);

    useEffect(() => {
        !isDragging && handleSaveWrapper();
    }, [commentColor, uIBackground, isDragging, isMaximized, text]);

    useEffect(() => {
        const iconX = width / 2 - 8.75;
        const iconY = -25;

        // Get the viewport's width and height
        const viewportWidth = window.innerWidth - iconX;
        const viewportHeight = window.innerHeight - height - 8.75;

        // Calculate the potential X and Y positions for the icon

        // Limit the icon's left position to stay within the viewport's width
        const limitedLeft = Math.max(0, Math.min(iconX + position.left, viewportWidth - 35 / 2)); // 35 is the approximate icon width
        // Limit the icon's top position to stay within the viewport's height
        const limitedTop = Math.max(0, Math.min(iconY + position.top, viewportHeight - 35 / 2)); // 35 is the approximate icon height

        // Set the icon's position while applying the limits
        setIconPosition({
            top: limitedTop.toFixed(0),
            left: limitedLeft.toFixed(0),
        });
    }, [position, width, height]);

    useEffect(() => {
        if (isEditing) {
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            }, 100);
        } else {
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.blur();
                }
            }, 100);
        }
    }, [isEditing]);

    return ( isMaximized ?
                <div
                    id={`sticky_note_container-${id}`}
                    className='sticky_note_container'
                    onClick={() => !isMaximized && null}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    style={{
                        left: `${position.left}px`,
                        top: `${position.top}px`,
                        cursor: isDragging ? 'grabbing' : isMaximized ? 'grab' : 'default',
                        width: width,
                        height: height
                    }}
                    ref={popupRef}>
                    <style>{beforeStyle}</style>
                        <ResizableBox
                            onResizeStop={handleSaveWrapper}
                            onResize={handleResize}
                            width={width} height={height}
                            minConstraints={minConstraints}
                            maxConstraints={maxConstraints}
                            className={'rotate-zero'}>
                            <div className={'sticky_note'}
                                 onClick={handleClickInside}>
                                {isOpenColors &&
                                    <div className='color-picker-popup no-select' >
                                        {colorsForStickyNotesColorPicker.map ((color, i) => (
                                            <div
                                                key={i}
                                                className="painter_tools_color_data_item"
                                                onClick={(e) => colorSelectHandler (e, color)}
                                            >
                                                {i === colorsForStickyNotesColorPicker.length - 1 ? (
                                                    <div className={"color_circle"}>
                                                        <svg
                                                            style={{
                                                                background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                                                borderRadius: "50%",
                                                                height: 20,
                                                                stroke: color.status ? '#3064ab' : '',
                                                                strokeWidth: color.status ? "2" : "0",
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className={"available-color"}>
                                                        <svg
                                                            style={{
                                                                stroke: color.status ? '#3064ab' : color?.color,
                                                                strokeWidth: color.status ? "2" : "0",
                                                            }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox={color.status ? "0 0 20 20" : "0 0 24 24"}
                                                            fill={color?.color}
                                                        >
                                                            <circle
                                                                cx={color.status ? "10" : "12"}
                                                                cy={color.status ? "10" : "12"}
                                                                r={color.status ? "9" : "11.5"}
                                                                stroke={color?.color}
                                                            />
                                                            <circle
                                                                cx={color.status ? "10" : "12"}
                                                                cy={color.status ? "10" : "12"}
                                                                r={color.status ? "8" : "8.5"}
                                                                fill={color?.color}
                                                            />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                }
                                {isOpenPatternPicker && (
                                    <div className='pattern-picker-popup no-select'
                                         onClick={(e) => {
                                        e.preventDefault ();
                                        e.stopPropagation ();
                                    }}>
                                        {patternsPicker.map ((pattern, i) => (
                                            <div
                                                key={pattern.id}
                                                className="painter_tools_path_data_item"
                                                onClick={(e) => pathSelectHandler (e, pattern)}
                                            >
                                                <img
                                                    src={pattern.path}
                                                    alt={`Pattern ${i + 1}`}
                                                    className="pattern-image"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {isOpenColorPicker && (
                                    <div
                                        className="sticky_note_tools_color_item_container"
                                        onClick={(e) => {
                                            e.preventDefault ();
                                            e.stopPropagation ();
                                        }}
                                    >
                                        {color_picker_data.map ((color, i) => (
                                            <div
                                                key={i}
                                                className="comment_box_tools_color_item"
                                                style={{backgroundColor: color}}
                                                onClick={() => {
                                                    setCommentColor (color);
                                                    changeColorsForStickyNotesColorPicker (null);
                                                    setIsOpenColorPicker (false);
                                                    setIsOpenColorPicker (false);
                                                    toggleTextColor (color)
                                                    setUIBackground (color)
                                                    setRgba (color)
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                )}
                                {isEditing && <div className={'edit-popup-header no-select'}>
                                    <div className={'popUp-actions'}>
                                        <div className={'color-picker-wrapper'}>
                                            <div className={'color-picker-comment-box'}>
                                                <div className='comment-box-popup-container_color-picker'
                                                     onClick={toggleColorsDropdown}>
                                                <span className={"current-picked-color-comment-box"}
                                                      onClick={toggleColorsDropdown}>
                                                    <svg
                                                        style={{stroke: '#3064ab'}}
                                                        strokeWidth="3"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill={commentColor}
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="10.5"
                                                            stroke={commentColor}
                                                        />
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="9"
                                                            fill={commentColor}
                                                        />
                                                    </svg>
                                                </span>
                                                </div>
                                            </div>
                                            <div className='background-color-changer-icon'
                                                 onClick={togglePatternsDropdown}>
                                                <img src={BackgroundColorIcon}/>
                                            </div>
                                        </div>
                                        <span className={'popUp-delete'} onClick={() => handleDelete (id)}>
                                        <img src={DeleteIcon} alt=""/>
                                    </span>
                                        <span className={'popUp-close'} onClick={handleCloseWrapper}>
                                        <img src={CloseIcon} alt=""/>
                                    </span>
                                    </div>
                                </div>}
                                {!isEditing && <div className={'markerIcon no-select'}>
                                    <MarkerIcon onClick={handleMaximizeOrMinimize} color={commentColor}/>
                                </div>}
                                <div
                                    style={{background: uIBackground}}
                                    className={`sticky-note-editor sticky-note-editor-${id}`}>
                                <textarea
                                    className='sticky_textarea'
                                    ref={textareaRef}
                                    style={{color: textColor, background: uIBackground}}
                                    maxLength="2000"
                                    rows="4" cols="50"
                                    autoFocus={isEditing}
                                    value={text}
                                    onInput={handleTextareaText}/>
                                </div>
                            </div>
                    </ResizableBox>
            </div> :
            <div
                onTouchStart={handleMouseDown}
                onMouseDown={handleMouseDown}
                ref={popupRef}
                style={{
                    position: 'absolute',
                    left: `${iconPosition.left}px`,
                    top: `${iconPosition.top}px`,
                    zIndex: 1001,
                }}
                onClick={handleMaximizeOrMinimize} className='markerIcon no-select'>
                <MarkerIcon onClick={handleMaximizeOrMinimize} color={commentColor}/>
            </div>
)
};

export default toolboxStatesWithRedux(StickyNotesEditPopup);
