import React, { useRef, useEffect, useState, useCallback } from "react";
import "../shape.css";
import ShapeCategoryItem from "./ShapeCategoryItem";
import {ShapeToolEditor, useShapes} from "../index";
import ShapeItem from "./ShapeItem";
import {allShapes, shapeCategories} from "../mock-data";
import {getRandomNumber} from "../utils";
import {  v4 as uuidv4 } from 'uuid';
import ClickOutside from "../../../ClickOutside";
import {debounce} from "lodash";

const debeounced300 = debounce((fn) => fn(), 300);


const ShapeSelector = ({onSelect}) => {
  const containerRef = useRef(null);
  const sidebarRef = useRef(null);
  const categoryRefs = useRef({});
  const [activeCategory, setActiveCategory] = useState(shapeCategories[0]);
  const {onSetMenuIsOpen, pushShape, onSetShaperClickState} = useShapes();

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const containerRect = container.getBoundingClientRect();
    const containerTop = containerRect.top;

    // Define a threshold (e.g., 50px) for how close the section needs to be to the top
    const threshold = 140;

    let visibleCategory = null;

    for (const category of shapeCategories) {
      const ref = categoryRefs.current[category];
      if (ref) {
        const rect = ref.getBoundingClientRect();

        // Check if the section top is within the threshold of the container top
        if (
          rect.top >= containerTop - 40 &&
          rect.top < containerTop + threshold
        ) {
          visibleCategory = category;
          break;
        }
      }
    }

    if (visibleCategory) {
      setActiveCategory(visibleCategory);

        debeounced300(() => {
          const element = document.getElementById(`${visibleCategory}-item`)

          if (element) {
            element.scrollIntoView({behavior: "smooth"});
          }
        })
    }
  }, [shapeCategories]);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleCategoryClick = (category) => {
    const ref = categoryRefs.current[category];
    if (ref) {
      ref.scrollIntoView({ behavior: "smooth" });
    }
  };

  const clickHandler = (item, category) => {
    const uuid = `shape-${item.id}-${uuidv4()}`
    onSetMenuIsOpen(false);
    onSetShaperClickState(uuid);
    const shapeItem = {
      ...item,
      uuid,
      category,
      svg:null,
      x: window.innerWidth/2 * getRandomNumber(1.02, 3.02),
      y: window.innerHeight/2 * getRandomNumber(1.02, 3.02),
    }
    pushShape(shapeItem)
    onSelect?.(shapeItem)
  }

  const onClose = (e) => {
    if (e && (e.target.classList.contains('pain_items') || e.target.classList.contains('icon_img'))) {
      return;
    }
    onSetMenuIsOpen(false);
    onSetShaperClickState(false);
  }


  return (
      <ClickOutside onClickOutside={onClose}>
        <div className="shape_selector--container">

          <div className="shape_selector--sidebar" ref={sidebarRef}>
            {shapeCategories.map((category) => (
                <ShapeCategoryItem
                    key={category}
                    category={category}
                    onClick={handleCategoryClick}
                    isActive={category === activeCategory}
                />
            ))}
          </div>
          <div className="shape_selector--body" ref={containerRef}>
            {shapeCategories.map((category, index) => (
                <ShapeItem categoryShapes={allShapes[category]} key={index} category={category} onClick={clickHandler}  ref={(el) => (categoryRefs.current[category] = el)}/>
            ))}
          </div>
        </div>
      </ClickOutside>
  );
};

export default ShapeSelector;
