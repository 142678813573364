export const mapboxGlDrawStyles = [
    {
        'id': 'gl-draw-polygon-fill-inactive',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'fill-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-outline-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-opacity': [
                "case",
                ["has", "user_portFillOpacity"],
                ["get", "user_portFillOpacity"],
                0.5
            ],
        }
    },
    {
        'id': 'gl-draw-polygon-fill-active',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-outline-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-opacity': [
                "case",
                ["has", "user_portFillOpacity"],
                ["get", "user_portFillOpacity"],
                0.5
            ],
        }
    },
    // {
    //   'id': 'gl-draw-polygon-midpoint-duplicate',
    //   'type': 'circle',
    //   'filter': ['all', ['==', '$type', 'Point'],
    //     ['==', 'meta', 'midpoint']
    //   ],
    //   'paint': {
    //     'circle-radius': 5,
    //     'circle-opacity': 1,
    //     'circle-color': '#fbb03b'
    //   },
    // },
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fbb03b'
        },
        // 'layout': {
        //   'symbol-placement': "line-center",
        //   'symbol-z-order': 100
        // }
    },
    {
        'id': 'gl-draw-polygon-stroke-inactive',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            "line-width": 3,
            "line-color": [
                "case",
                ["has", "user_portStrokeColor"],
                ["get", "user_portStrokeColor"],
                "#53D669"
            ],
            "line-opacity": [
                "case",
                ["has", "user_portStrokeOpacity"],
                ["get", "user_portStrokeOpacity"],
                1
            ]
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            "line-width": 3,
            "line-color": [
                "case",
                ["has", "user_portStrokeColor"],
                ["get", "user_portStrokeColor"],
                "#53D669"
            ],
            "line-opacity": [
                "case",
                ["has", "user_portStrokeOpacity"],
                ["get", "user_portStrokeOpacity"],
                1
            ]
        }
    },
    {
        'id': 'gl-draw-line-inactive',
        'type': 'line',
        'filter': [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static'],
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        "paint": {
            "line-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            "line-width": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-line-active',
        'type': 'line',
        'filter': [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true'],
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        "paint": {
            "line-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            // "line-dasharray": [0.2, 2],
            "line-width": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        'type': 'circle',
        'filter': [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        "paint": {
            "circle-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#fbb03b"
            ],
            "circle-radius": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                7
            ],
            "circle-stroke-width": 7,
            "circle-stroke-color": "rgba(0,0,0,0)"
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-point-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    },
    {
        'id': 'gl-draw-point-stroke-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-active',
        'type': 'circle',
        'filter': [
            'all',
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': "#FF0000"
        }
    },
    {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-outline-color': [
                "case",
                ["has", "user_portFillColor"],
                ["get", "user_portFillColor"],
                "#53D669"
            ],
            'fill-opacity': [
                "case",
                ["has", "user_portFillOpacity"],
                ["get", "user_portFillOpacity"],
                0.5
            ],
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            "line-width": 3,
            "line-color": [
                "case",
                ["has", "user_portStrokeColor"],
                ["get", "user_portStrokeColor"],
                "#53D669"
            ],
            "line-opacity": [
                "case",
                ["has", "user_portStrokeOpacity"],
                ["get", "user_portStrokeOpacity"],
                1
            ]
        }
    },
    {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'LineString']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            'line-width': [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-point-static',
        'type': 'circle',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Point']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#404040'
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-inactive-copy',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            "line-width": 20, // the threshold when the popup will be opened
            "line-opacity": 0
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-active-copy',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            "line-width": 20, // the threshold when the popup will be opened
            "line-opacity": 0
        }
    },
];

export const defaultStrokeColor = '#53D669';
export const defaultFillColor = '#53D669';
export const defaultStrokeOpacity = 1;
export const defaultFillOpacity = 0.5;
export const defaultWeight = 3;
