import { useDrag, useDrop } from "react-dnd";
import MapMoke from "../../../assets/imgs/mapMoke.svg";
import EditSlideIcon from '../../../assets/imgs/icons/editSlide.svg'
import DeleteSlideIcon from '../../../assets/imgs/icons/deleteSlide.svg'
import InfoIcon from '../../../assets/imgs/icons/slideInfo.svg'
import SaveSlideIcon from '../../../assets/imgs/icons/saveSlide.svg'
import { connect } from "react-redux";
import {
    getScreenShotIntermediateDataST,
    getScreenShotLoadingST,
    getScreenShotSendDataST,
    getSlidesData
} from "../../../store/selectors";
import React, {useEffect, useState} from "react";
import DefaultImage from "../../../assets/imgs/defaultImage.png"
import Loader from "../Loader";
import {useLocation} from "react-router-dom";
import MapScreenShotButton from "../DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";

const DraggableSquare = ({
     id,
     index,
     moveSquare,
     selectedId,
     onSelect,
     handleEdit,
     handleDelete,
     handleSave,
     title,
     showInfo,
     imageUrl,
     isLoading,
     openInfoPopup,
     getScreenShotSendDataST,
     getScreenShotIntermediateDataST,
     map,
     getScreenShotLoadingST,
     globalLoading,
}) => {
    const location = useLocation();

    const query = new URLSearchParams(location.search);

    const isScreenShot = query.get("screenShot");

    const handleFill = (e) => {
       if(!e.target.closest('.option__menu__container>button')) onSelect(id);
    };

    const [, drag] = useDrag({
        type: "SQUARE",
        item: { id, index },
    });

    const [, drop] = useDrop({
        accept: "SQUARE",
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveSquare(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });
    // useEffect(()=>{
    //     if(typeof +query.get("screenSlide") === 'number' && !isNaN(+query.get("screenSlide")) && +query.get("screenSlide") === id){
    //         onSelect(id)
    //     }
    // },[])
    return (
        <div id={`square-${id}`} className="square__container"  ref={(node) => drag(drop(node))}>
            <div id="screenshot-image"></div>
            {(+query.get("screenSlide") === id) ?
                <div className='mini__square__div'
                     data-is-loading={globalLoading}>
                    <div
                        onClick={handleFill}
                        className={`mini__square option__menu__square ${
                            +query.get("screenSlide") === id ? "selected" : ""
                        }`}
                        style={{backgroundImage: `url(${imageUrl || DefaultImage})`}}
                    >
                        <div className='option__menu__container'>
                            <button onClick={() => handleEdit(false)}>
                                <img src={EditSlideIcon} alt='Edit Slide'/>
                            </button>
                            <button onClick={()=>handleDelete('slide')}>
                                <img src={DeleteSlideIcon} alt='Delete Slide'/>
                            </button>
                            <MapScreenShotButton handleFill={handleSave} type={'presentation-edit'} map={map}/>

                            {/*<button onClick={handleSave} data-disable={*/}
                            {/*   !(isScreenShot &&*/}
                            {/*    !!getScreenShotSendDataST.length > 0 &&*/}
                            {/*    !!getScreenShotIntermediateDataST.length > 0 &&*/}
                            {/*    getScreenShotSendDataST !== getScreenShotIntermediateDataST)*/}
                            {/*}>*/}
                            {/*    <img src={SaveSlideIcon} alt='Save Slide'/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div> :
                 <div
                    style={{ backgroundImage: `url(${imageUrl || DefaultImage})` }}
                    onClick={handleFill}
                    className={`mini__square ${
                        +query.get("screenSlide") === id ? "selected" : ""
                    }`}
                    data-is-loading={globalLoading}
                >
                     {!isLoading && showInfo  &&
                         <div className='show__info__icon' onClick={openInfoPopup}>
                         <img src={InfoIcon}/>
                     </div>}
                     {isLoading && <Loader/>}
                </div>
            }
                <span className={"square__container__text"} data-is-loading={globalLoading}>
                    <p style={{ color: "#2C476C" }} >{index + 1}</p>
                </span>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    isFilled: getSlidesData(state),
    getScreenShotSendDataST: getScreenShotSendDataST(state),
    getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
});

const mapDispatchToProps = {

};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(DraggableSquare);
