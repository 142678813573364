import {useEffect, useRef} from 'react';
import {updateURLParameter} from "../utils/utils";


function useCountdownTimer(initialTime, callBack,autostart) {
    const timerRef = useRef(null);
    const startTimeRef = useRef(null);
    const pausedRef = useRef(false);
    const initialTimeRef = useRef(initialTime);
    const nextTimeRef = useRef(initialTime);
    const remainingTime = useRef(initialTime);

    const startCountdown = (isContinue) => {

        if (initialTimeRef.current < 0 || pausedRef.current) return;
        startTimeRef.current = Date.now();
        if(isContinue && !!remainingTime.current){
            initialTimeRef.current = remainingTime.current
        }
        if (timerRef.current === null) {
            const animate = () => {
                if(pausedRef.current) return
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTimeRef.current;
                remainingTime.current = Math.max(0, initialTimeRef.current - elapsedTime);
                if (remainingTime.current > 0 && !pausedRef.current) {
                    timerRef.current = requestAnimationFrame(animate);
                } else {
                    timerRef.current = null;
                    // Use the callback from the ref
                    if (typeof callBack === 'function' && initialTimeRef.current > 0) {
                        callBack(true);
                        initialTimeRef.current = nextTimeRef.current
                    }
                }
            };

            timerRef.current = requestAnimationFrame(animate);
        }
    };

    const stopCountdown = () => {
        if (timerRef.current !== null) {
            cancelAnimationFrame(timerRef.current);
            timerRef.current = null;
        }
    };

    const pauseCountdown = () => {
        pausedRef.current = true;
        timerRef.current = null;
        const url = new URL(window.location.href);
        const newURL = updateURLParameter(url, 'playBack', 'false');
        window.history.pushState({ path: newURL }, '', newURL);

    };

    const playCountdown = (isContinue) => {
        pausedRef.current = false;
        startCountdown(isContinue);
        const url = new URL(window.location.href);
        const newURL = updateURLParameter(url, 'playBack', 'true');
        window.history.pushState({ path: newURL }, '', newURL);
    };
    const changeInitialTime = (duration) =>{
        initialTimeRef.current = duration
        remainingTime.current = duration
    }
   const changeNextTime = (duration) =>{
         nextTimeRef.current = duration
    }

    const restartCountdown = () => {
        stopCountdown();
        startCountdown(); // Use the callback from the ref
    };

    useEffect(() => {
        if (!initialTimeRef.current) {
            initialTimeRef.current = initialTime
        }
    }, [initialTime]);

    return { changeNextTime,startCountdown, stopCountdown, restartCountdown, pauseCountdown, playCountdown, isPaused:pausedRef.current , changeInitialTime};
}

export default useCountdownTimer;
