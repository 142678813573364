import {
  LOADING_ARTICLES_ALL,
  SET_ARTICLE_FONT_SIZE,
  SET_ARTICLE_IS_LIKED,
  SET_ARTICLES_ALL,
  SET_SELECTED_ARTICLE_ALL,
  SET_ARTICLE_READ_MORE_FONT_SIZE,
} from '../constants'
import {getArticleAllAPI, likeArticleAPIRequest, unlikeArticleAPIRequest} from '../../service/article'

export const loadingArticlesAll = () => ({
    type: LOADING_ARTICLES_ALL,
})

export const setArticlesAll = (articlesAll) => ({
    type: SET_ARTICLES_ALL,
    payload: articlesAll,
})

export const setSelectedArticleAll = (articleAll) => ({
    type: SET_SELECTED_ARTICLE_ALL,
    payload: articleAll,
})

export const setArticleLiked = (article) => ({
    type: SET_ARTICLE_IS_LIKED,
    payload: article,
})

export const setArticlePopUpFontSize = (size) => ({
  type: SET_ARTICLE_FONT_SIZE,
  payload: size,
})

export const setArticleReadMoreFontSize = (size) => ({
  type: SET_ARTICLE_READ_MORE_FONT_SIZE,
  payload: size,
})
export const getArticleAllFromAPI = (id, lng) => (dispatch, getState) => {
    dispatch(loadingArticlesAll())
    getArticleAllAPI(id, lng).then((article) => {

            let articles = getState().articlesAll.data
            const openedArticles = articles.filter(x => x.show)
            if (articles.find(x => x.id == id) !== undefined || typeof articles.find(x => x.id == id) !== 'undefined' ) {
                const indexArticle = articles.findIndex(x => x.id === article.id)
                articles[indexArticle].show = true
            } else {
                if (openedArticles.length > 0) {
                    openedArticles.forEach(article => {
                        const indexArticle = articles.findIndex(x => x.id === article.id)
                        articles.splice(indexArticle, 1)
                    })
                }
                articles.push(article)
            }
            dispatch(
                setSelectedArticleAll({
                    id: id,
                    type: article.articleData.type,
                })
            )
            dispatch(setArticleLiked(article.articleData?.like))
            // dispatch(setSearch(false))
            dispatch(setArticlesAll(articles))
        })
}

export const collapsArticlePopup = (id) => (dispatch, getState) => {
    dispatch(loadingArticlesAll())
    const articles = getState().articles.data
    const foundIndex = articles.findIndex((article) => article.id === id)
    articles[foundIndex].show = !articles[foundIndex].show
    dispatch(setArticlesAll(articles))
}

export const closeArticlePopup = (id) => (dispatch, getState) => {
    dispatch(loadingArticlesAll())
    let articles, foundIndex;
    if(id === 'all') {
        articles = []
    }else{
        articles = getState().articles.data
        foundIndex = articles.findIndex((article) => article.id === id)
        articles.splice(foundIndex, 1)
    }
    dispatch(setArticlesAll(articles))
}

export const likeAricleFromApi = (body, like ) => (dispatch, getState) => {
    dispatch(setArticleLiked(like))
    if(like){
        return likeArticleAPIRequest(body)
    }else{
        return unlikeArticleAPIRequest(body.articleId)
    }
}
