import axios from "axios"

axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken",
)}`

axios.defaults.headers.accessToken = localStorage.getItem("accessToken")
axios.defaults.headers.refreshToken = localStorage.getItem("refreshToken")

const IdentityAPI = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_IDENTITY_URL,
})

const refreshAccessToken = () => {
    IdentityAPI.defaults.headers.accessToken = localStorage.getItem("accessToken")
    IdentityAPI.defaults.headers.refreshToken = localStorage.getItem("refreshToken")
    return IdentityAPI.put(`/api/Admin/RefreshAccessToken`)
}

IdentityAPI.interceptors.response.use(
    res =>  res,
async err => {
        const originalRequest = err.config
        if (
            err.response
            && err.response?.status === 401 && err.response.errorMessages.length > 0
            // eslint-disable-next-line no-underscore-dangle
            && !originalRequest._retry
        ) {
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true
            if (
                !localStorage.getItem("accessToken")
            ) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')

                // Remove userData from localStorage
                localStorage.removeItem('userData')

                // Redirect to login page
                return Promise.reject(err)
            }
            if (
                localStorage.getItem("accessToken")
                && !localStorage.getItem("refreshToken")
            ) {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')

                // Remove userData from localStorage
                localStorage.removeItem('userData')

                // Redirect to login page
                return Promise.reject(err)
            }

            try {
                const { data } = await refreshAccessToken()

                if (data.accepted) {
                    localStorage.setItem(
                        "accessToken",
                        data.data[0].accessToken,
                    )

                    IdentityAPI.defaults.headers.Authorization = `Bearer ${data.data[0].accessToken}`

                    IdentityAPI.defaults.headers.accessToken = data.data[0].accessToken

                    originalRequest.headers.Authorization = `Bearer ${data.data[0].accessToken}`

                    return IdentityAPI(originalRequest)
                }
                throw new Error(err)

                // eslint-disable-next-line no-shadow
            } catch (err) {
                return Promise.reject(err)
            }
        }
        return Promise.reject(err)
    },
)

export default IdentityAPI
