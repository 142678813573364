import { SET_COUNTRIES, LOADING_COUNTRIES } from "../constants";
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils";
import { getCountriesAPI } from "../../service/countries";

export const loadingCountries = () => ({
  type: LOADING_COUNTRIES,
});

export const setCountries = (countries) => ({
  type: SET_COUNTRIES,
  payload: countries,
});

export const getCountriesFromAPI = (lng = 1) => (dispatch, getState) => {
  const state = getState();
  const { url, body } = constructSelectedItemApiParams(
    "countrie",
    state.user.data,
      lng
  );
  dispatch(loadingCountries());
  getCountriesAPI(url, body).then((countries) => {
    dispatch(setCountries(countries));
  });
};
