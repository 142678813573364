export const PlayNext = ({active}) => {
    const fill = active ? '#2C476C' : '#E8E8E8'
    return (
        <svg width="32" height="32" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_340_2413)">
                <rect x="12" y="8" width="64" height="64" rx="12" fill="white"/>
            </g>
            <path d="M44.0841 38.2051L34.0274 30.4462C33.7385 30.2083 33.385 30.058 33.0101 30.0137C32.6353 29.9694 32.2554 30.0331 31.917 30.1969C31.4352 30.4692 31.0398 30.8667 30.7746 31.3456C30.5094 31.8245 30.3848 32.3659 30.4145 32.9099V48.4263C30.3847 48.9703 30.5093 49.5118 30.7745 49.9907C31.0397 50.4695 31.4352 50.8671 31.917 51.1393C32.1835 51.2688 32.4766 51.3372 32.7741 51.3394C33.2325 51.3337 33.6754 51.1757 34.0302 50.8913L44.0841 43.1351C44.443 42.8299 44.7278 42.45 44.9176 42.0232C45.1073 41.5963 45.1973 41.1334 45.1808 40.6681C45.1969 40.2035 45.1068 39.7412 44.917 39.3151C44.7272 38.889 44.4427 38.5097 44.0841 38.2051Z" fill={fill}/>
            <path d="M57.4923 38.2042L47.4371 30.446C47.148 30.2082 46.7945 30.058 46.4197 30.0137C46.0449 29.9694 45.6651 30.033 45.3266 30.1966C44.844 30.4682 44.4477 30.8656 44.1819 31.3445C43.916 31.8235 43.791 32.3652 43.8207 32.9096V48.4261C43.7911 48.9702 43.9158 49.5118 44.1811 49.9907C44.4465 50.4697 44.842 50.8674 45.3239 51.1398C45.5907 51.2691 45.884 51.3375 46.1817 51.3398C46.6398 51.3337 47.0824 51.1758 47.4371 50.8917L57.4916 43.1308C57.8507 42.8265 58.1356 42.4474 58.3257 42.0212C58.5157 41.595 58.6059 41.1326 58.5898 40.6679C58.6059 40.2031 58.5157 39.7406 58.3258 39.3144C58.136 38.8881 57.8512 38.5088 57.4923 38.2042Z" fill={fill}/>
            <defs>
                <filter id="filter0_d_340_2413" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="6"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_340_2413"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_340_2413" result="shape"/>
                </filter>
            </defs>
        </svg>

    )
}
