const SliderPositionLeft = ({active}) => {
    return (
        <svg width="15" height="15" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_340_13462)">
                <path fillRule="evenodd" clip-rule="evenodd" d="M0 0H25V25H0V0ZM22.9167 3H2.08333V22.9167H22.9167V3Z" fill={active ? "#2C476C" : "#C6C6C6"}/>
                <rect x="1" y="24" width="23" height="9" transform="rotate(-90 1 24)" fill={active ? "#2C476C" : "#C6C6C6"}/>
            </g>
            <defs>
                <clipPath id="clip0_340_13462">
                    <rect width="25" height="25" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default SliderPositionLeft;
