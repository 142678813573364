import React, { useEffect, memo } from 'react';

import { changeCursorIcon } from "../../../../shared/mockData";

import LineDraw from "../../../../assets/imgs/PaintBar/line-draw-tool-icon.svg";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "./style.css";
import "../../style.css";
import toolboxStatesWithRedux from "../../mapper";


const LineDrawToolbox = (props) => {

    const {
        setLineDrawToolboxState,
        getLineDrawToolboxState,
        getPolygonDrawToolboxState,
        setMapboxDrawToolboxGeoJSONState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setRulerClickedState,
        setStickyNotesClickedState,
        getPainterStartData,
        getLineDrawToolboxWeight,
        getLineDrawToolboxCurrentColor,
        setCommentToolboxState,
        setPolygonDrawToolboxState,
        selectedMapboxDrawToolFeature,
        setTextToolboxState,
        draw,
    } = props;

    const changeFeatureProps = (id) => {
        if (!draw) return;
        const thisFeature = draw.getSelected().features[0];
        if (thisFeature && thisFeature.properties) {
            draw.setFeatureProperty(id, 'portColor', getLineDrawToolboxCurrentColor);
            draw.setFeatureProperty(id, 'portWidth', getLineDrawToolboxWeight);
        }
        draw.add(draw.get(id));
        setMapboxDrawToolboxGeoJSONState(JSON.parse(JSON.stringify(draw.getAll())))
    }

    const handleIconClick = () => {
        setCommentToolboxState(false);
        setDrawerToolboxClickedState(false);
        setEraserClickedState(false);
        setRulerClickedState(false);
        setPolygonDrawToolboxState(false);
        setStickyNotesClickedState(false);
        setTextToolboxState(false);
        setLineDrawToolboxState(!getLineDrawToolboxState);
    }

    useEffect(() => {
        if(selectedMapboxDrawToolFeature) {
            changeFeatureProps(selectedMapboxDrawToolFeature.id);
        }
    }, [getLineDrawToolboxWeight, getLineDrawToolboxCurrentColor]);

    useEffect(() => {
        if(!draw || getPolygonDrawToolboxState) {
            draw?.changeMode('simple_select');
            return
        }

        if (getPainterStartData && getLineDrawToolboxState) {
                setTimeout(() => {
                    draw.changeMode('draw_line_string');
                }, 30)
        } else {
            if(!draw.getSelected().features.length) {
                draw.changeMode('simple_select');
                changeCursorIcon();
            }
        }
    }, [getLineDrawToolboxState, getPainterStartData, getPolygonDrawToolboxState]);

    return (
        <>
            <div
                id="lineDraw_icon"
                className={`pain_items ${getLineDrawToolboxState ? "button_active" : ""}`}
                onClick={handleIconClick}
            >
                <img src={LineDraw} alt="" className="icon_img"/>
            </div>
        </>
    );
};

export default toolboxStatesWithRedux(memo(LineDrawToolbox));
