import { useState } from "react";
import { connect } from "react-redux";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import "./style.css";
import {useTranslation} from "react-i18next";

const CopyShareLink = (props) => {
  const {t} = useTranslation()
  const { url = "" } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(url);
  };
  return (
    <div className="card-body__copy-share-link copy-share-link">
      <h5>{t('copyUrl')}</h5>
      <div className="copy-share-link__btn-box">
        <OutlinedInput
          value={url}
          variant="outlined"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClick} color="success">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.97876 12.5356L7.80492 14.6567C6.60593 15.8256 4.6553 15.8276 3.4562 14.6567C2.25824 13.4868 2.25824 11.5839 3.4562 10.414L5.63107 8.2929C6.03139 7.9028 6.03139 7.2695 5.63107 6.8788C5.23076 6.4881 4.58222 6.4882 4.18181 6.8788L2.00704 9C0.00937142 10.9492 0.00937142 14.1216 2.00704 16.0708C3.00588 17.0459 4.31801 17.5332 5.63015 17.5332C6.94331 17.5332 8.25535 17.0459 9.25418 16.0708L11.428 13.9497C11.8283 13.5591 11.8283 12.9263 11.428 12.5356C11.0277 12.1449 10.3792 12.145 9.97876 12.5356V12.5356Z"
                    fill="white"
                  />
                  <path
                    d="M9.25435 1.9292L7.08051 4.0503C6.6802 4.4409 6.6802 5.0737 7.08051 5.4644C7.48082 5.8551 8.12936 5.855 8.52977 5.4644L10.7036 3.3433C11.9016 2.1744 13.8523 2.1724 15.0523 3.3433C16.2503 4.5132 16.2503 6.4161 15.0523 7.586L12.8775 9.7071C12.4771 10.0972 12.4771 10.7305 12.8775 11.1212C13.0776 11.3165 13.3399 11.4142 13.602 11.4142C13.8642 11.4142 14.1265 11.3165 14.3266 11.1212L16.5015 9C18.4992 7.0508 18.4992 3.8784 16.5015 1.9292C14.5028 -0.0214973 11.251 -0.0204973 9.25435 1.9292Z"
                    fill="white"
                  />
                  <path
                    d="M10.7036 6.17189L6.3559 10.4141C5.95559 10.8047 5.95559 11.4375 6.3559 11.8282C6.55606 12.0235 6.81832 12.1212 7.08048 12.1212C7.34264 12.1212 7.6049 12.0235 7.80506 11.8282L12.1528 7.58599C12.5531 7.19539 12.5531 6.56259 12.1528 6.17189C11.7524 5.78119 11.1039 5.78129 10.7036 6.17189V6.17189Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            </InputAdornment>
          }
        />
        <Snackbar
          message="Copied to clibboard"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => setOpen(false)}
          open={open}
        />
      </div>
    </div>
  );
};
export default connect(null, null)(CopyShareLink);
