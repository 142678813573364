import { connect } from "react-redux";
import "./style.css";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import { useEffect, useState } from "react";
import { getRemoveCardId } from "../../../../store/selectors";
import {useTranslation} from "react-i18next";
const cardBodyStyle = {
  aspectRatio: "677/255",
  gridTemplateRows: "1fr 1fr",
  padding: "20px",
};
const DynamicScreenShotRemove = (props) => {
  const { onClose, id, userId, removeItemAPI, getDataFromAPI} = props;
  const {t} = useTranslation()
  const queryParams = new URLSearchParams(window.location.search);
  let pageLoc = +queryParams.get("page");

  const [state, setState] = useState(false);

  const onCardRemove = () => {
    onClose()
    setState(true);
    removeItemAPI(pageLoc, id, userId);
  };

  useEffect(() => {
    setTimeout(() => {
      state && props.getDataFromAPI(pageLoc, userId, onClose, false);
      setState(false);
    }, 2000);
  }, [props.getRemoveCardId]);

  return (
    <div className="card-body__dynamic-screen-shot-share-content dynamic-screen-shot-share-content">
      <CardBody hasIcon={false} styles={cardBodyStyle}>
        <div className={"dynamic-screen-shot-share-content__header"}>
          <CloseBtn onClose={onClose} />
          <div className="card-body__dynamic-title dynamic-title remove_screenShot_card_body__dynamic-title">
            <h4>{t('deletePopups.delete')}</h4>
            <p className="remove_screenShot_card_body_dynamic_sub_title">
              {t('deletePopups.presentation')}
            </p>
          </div>
          <div className="screenshot_zone">
            <button
              className="screenshot_cancel screenshot_buttons"
              onClick={onClose}
            >
              {t('cancel')}
            </button>
            <button
              className="screenshot_remove screenshot_buttons"
              onClick={onCardRemove}
            >
              {t('delete')}
            </button>
          </div>
        </div>
      </CardBody>
    </div>
  );
};

const mapStateTopProps = (state) => ({
  getRemoveCardId: getRemoveCardId(state),
});



export default connect(
  mapStateTopProps,
  null
)(DynamicScreenShotRemove);
