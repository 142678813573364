import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useClickOutside(ref,onClickOutside,referenceElement) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(!referenceElement) {
                    return onClickOutside(event)
                }
                if(!referenceElement.contains(event.target)){
                    return onClickOutside(event)
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function ClickOutside(props) {
    const {onClickOutside,children,reference} = props
    const wrapperRef = useRef(null);
    useClickOutside(wrapperRef,onClickOutside,reference);

    return <div id='popper' ref={wrapperRef}>{children}</div>;
}

ClickOutside.propTypes = {
    children: PropTypes.element.isRequired
};

export default ClickOutside;
