import { getFilteredSubjectsAPI, getSubjectsAPI } from "../../service/subjects"
import {
  SET_SUBJECTS,
  LOADING_SUBJECTS,
  SET_FILTERED_SUBJECTS,
  LOADING_FILTERED_SUBJECTS,
  SET_SUBJECTS_SETTINGS
} from "../constants"
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils"

export const loadingSubjects = () => ({
  type: LOADING_SUBJECTS,
})

export const loadingFilteredSubjects = () => ({
  type: LOADING_FILTERED_SUBJECTS,
})

export const setSubjects = (subjects) => ({
  type: SET_SUBJECTS,
  payload: subjects,
})
export const setSubjectsSettings = (settings) => ({
  type: SET_SUBJECTS_SETTINGS,
  payload: settings,
})

export const setFilteredSubjects = (subjects) => ({
  type: SET_FILTERED_SUBJECTS,
  payload: subjects,
})

export const getSubjectsFromAPI = (lng = 1) => (dispatch, getState) => {
  const state = getState()
  const { url, body } = constructSelectedItemApiParams(
    "subject",
    state.user.data,
    lng
  )
  dispatch(loadingSubjects())

  getSubjectsAPI(url, body, state.user.data).then((subjects) => {
    dispatch(setSubjects(subjects))
  })
}

export const getFilteredSubjectsFromAPI =
  (url, body) => (dispatch, getState) => {
    const state = getState()
    dispatch(loadingFilteredSubjects())

    getFilteredSubjectsAPI(url, body, state.user.data).then((subjects) => {
      dispatch(setFilteredSubjects(subjects))
    })
  }
