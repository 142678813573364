import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import GalleryTili from "./GalleryTili";
import GalleryView from "./GalleryView";
import FullScreenGalleryItem from "./FullScreenGalleryItem";
const GalleryNew = (props) => {
  const { title, gallery, galleryItems, toggleGallery, mapState, articleId, fromArticle} = props;
  const [isPresentation, setIsPresentation] = useState(false);
  const [tili, setTili] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(props.feauturedImageIdx);
  const [startIdx, setStartIdx] = useState(props.feauturedImageIdx);

  const [currentTiliIndex, setCurrentTiliIndex] = useState(props.feauturedTiliPageIdx);
  const [startTiliIdx, setStartTiliIdx] = useState(props.feauturedTiliPageIdx);

  const [isSlideMaximize, setIsSlideMaximize] = useState(false);

  const galleryContainer = useRef(null);
  const galleryThumb = useRef(null);
  const galleryCaption = useRef(null);
  const fullScreenRef = useRef(null);
  const galleryRef = useRef(null);
  const galleryTiliRef = useRef(null);
  const gallerySectionRef = useRef(null);
  const galleryTiliContainer = useRef(null);
  const galleryTiliContainerListener = useRef({});


  const handleSetSlideTiliIndex = (newIndex) => {
    setCurrentTiliIndex(newIndex);
  };

  const handlePopState = () => {
    updateIsPresentation();
  };

  function getPageFromUrl (type) {
    const params = new URLSearchParams(window.location.search);
    return params.get(type);
  };

  const updateIsPresentation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isPresentation = searchParams.get("presentation");
    setIsPresentation(!!isPresentation);
  };

  // const searchItemAndUpdateMaximizeState = () => {
  //   const activeItem = document.querySelector(".is-active .is-visible");
  //   if (activeItem?.firstChild?.classList.contains("is-video")) {
  //     setIsSlideMaximize(true);
  //   } else {
  //     setIsSlideMaximize(false);
  //   }
  // };

  const updateSlideMaximizeState = (type) => {
    setIsSlideMaximize(Boolean(type === 1) || Boolean(type === 3));
  };
  const slideMoveHandler = () => {
    const iframes = document.querySelectorAll(".gallery-main iframe");
    iframes.forEach((iframe) => {
      iframe.src = iframe.src;
    });
  }


  const convertToNoCookie = (url) => {
    return url.replace('youtube.com', 'youtube-nocookie.com');
  };


  // const toggleFullScreenFromTili = (action, path, tag) => {
  //   if (!action) {
  //     gallerySectionRef.current.setAttribute('data-full-screen', false);
  //     return;
  //   }
  //   gallerySectionRef.current.setAttribute('data-full-screen', true);
  //   let element = document.createElement(tag);
  //   element.setAttribute('src', path);
  //   if (tag === 'video') element.setAttribute('controls', true);
  //   setTimeout(() => {
  //     fullScreenRef.current.innerHTML = element.outerHTML;
  //   }, 0);
  // };

  const switchToSlide = (idx) => {
    setTimeout(() => {
      toggleMode();
      galleryThumb.current?.splide?.go(idx);
    }, 500);
  };

  const toggleFullScreen = (action) => {
    if (!action) {
      props.setGalleryFullScreen(false);
      gallerySectionRef.current.setAttribute('data-full-screen', false);
      return;
    }
    const activeGalleryItem = props.galleryItems[currentIndex];
    if (!activeGalleryItem) {
      console.error('Active gallery item not found.');
      return;
    }
    const imgHtml = `<img src="${activeGalleryItem.path}" alt="${activeGalleryItem.caption}" />`;
    gallerySectionRef.current.setAttribute('data-full-screen', true);
    setTimeout(() => {
      fullScreenRef.current.innerHTML = imgHtml;
    }, 0);
  };

  const toggleMode = () => {
    setTili(prev => !prev);
  };
  useEffect(() => {
    const splideContainer = galleryContainer.current?.splide;
    const splideCaption = galleryCaption.current?.splide;
    const splideThumb = galleryThumb.current?.splide;

    if (splideCaption) {
      splideCaption.on("move", (newIndex) => {
        setCurrentIndex(newIndex);
      });
    }
    if (splideContainer && splideCaption && splideThumb) {
      splideContainer.sync(splideCaption);
      splideContainer.sync(splideThumb);
    }
  }, []);

  useEffect(() => {
    // if (props.isFullScreen) {
      toggleFullScreen(props.isFullScreen);
    // }
  }, [props.isFullScreen]);

  useEffect(() => {
    if (props.isGrid) {
      toggleMode();
    }
  }, [props.isGrid]);

  useEffect(() => {
    updateIsPresentation();
  }, []);

  useEffect(() => {
    if (props.feauturedImageIdx !== galleryThumb.current?.splide?.index) {
      const timeoutId = setTimeout(() => {
          galleryThumb.current?.splide?.go(props.feauturedImageIdx );
      }, 0);

    }
  }, [props.feauturedImageIdx]);

  useEffect(() => {
    if (props.isGrid && !tili) {
      setTili(true);
    }
  }, [props.isGrid, tili]);

  useEffect(() => {
    if (props.screenShotFirstLoad && tili && !galleryTiliContainerListener.current?.["move"]) {
      const timeoutId = setTimeout(() => {
        const splideTili = galleryTiliContainer.current?.splide;
        if (splideTili) {
          splideTili.go(currentTiliIndex);
        }
      }, 500);

      return () => clearTimeout(timeoutId); // Cleanup timeout if necessary
    }
  }, [props.screenShotFirstLoad, tili, currentTiliIndex]);

  useEffect(() => {
    const splideTili = galleryTiliContainer.current?.splide;

    if (tili && !galleryTiliContainerListener.current?.["move"]) {
      splideTili.on("move", handleSetSlideTiliIndex);
      galleryTiliContainerListener.current["move"] = handleSetSlideTiliIndex;
    } else if (!tili && galleryTiliContainerListener.current?.['move']) {
      splideTili.off("move", galleryTiliContainerListener.current['move']);
      delete galleryTiliContainerListener.current['move'];
    }

    return () => {
      if (galleryTiliContainerListener.current?.['move']) {
        splideTili.off("move", galleryTiliContainerListener.current['move']);
      }
    };
  }, [tili]);

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('popstate', handlePopState);
    // Cleanup function to run on unmount
    return () => {
      // Reset currentTiliIndex and remove the event listener
      setCurrentTiliIndex(0);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
      <div className={`gallery-section`} ref={gallerySectionRef} id='gallery-section' data-full-screen="false">
        <div id={"image"}></div>
        <GalleryTili
            tili={tili}
            title={title}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentTiliIndex={currentTiliIndex}
            mapState={mapState}
            startTiliIdx={startTiliIdx}
            toggleGallery={toggleGallery}
            galleryTiliRef={galleryTiliRef}
            galleryTiliContainer={galleryTiliContainer}
            getPageFromUrl={getPageFromUrl}
            gallery={gallery}
            galleryItems={galleryItems}
            switchToSlide={switchToSlide}
        />
        <GalleryView
            title={title}
            tili={tili}
            toggleMode={toggleMode}
            toggleFullScreen={toggleFullScreen}
            isSlideMaximize={isSlideMaximize}
            toggleGallery={toggleGallery}
            galleryRef={galleryRef}
            galleryContainer={galleryContainer}
            galleryThumb={galleryThumb}
            galleryCaption={galleryCaption}
            startIdx={startIdx}
            gallery={gallery}
            getPageFromUrl={getPageFromUrl}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentIndex={currentIndex}
            mapState={mapState}
            galleryItems={galleryItems}
            activeGalleryItem={props.galleryItems[currentIndex]}
            updateSlideMaximizeState={updateSlideMaximizeState}
            convertToNoCookie={convertToNoCookie}
            slideMoveHandler={slideMoveHandler}
        />
        <FullScreenGalleryItem
            getPageFromUrl={getPageFromUrl}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentIndex={currentIndex}
            mapState={mapState}
            toggleFullScreen={toggleFullScreen}
            toggleGallery={toggleGallery}
            fullScreenRef={fullScreenRef}
        />
      </div>

  );
}

export default GalleryNew
