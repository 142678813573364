import { useRef, useState } from "react";
import { connect } from "react-redux";
import {makeStyles, OutlinedInput, Stack, TextareaAutosize} from "@mui/material";
import Button from "@mui/material/Button";
import "./style.css";
import { editScreenShotCardAPI } from "../../../../store/actions/mapStateAction";
import {editPresentationAPI, getPresentationAPI} from "../../../../store/actions/presentationMode";
import {editSlideAPI} from "../../../../store/actions/slides";
import {removeExtraSpaces} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";

const SlideEdit = (props) => {
    const {t} = useTranslation()
    const { onClose, presentation=false,item,editPresentationAPI,getPresentationAPI,editSlideAPI } = props;
    const [text, setText] = useState(item?.title);
    const [description, setDescription ]= useState(item?.shortDescription || '')

    const [editButtonDisable, setEditButtonDisable] = useState(true);
    //
    // const queryParams = new URLSearchParams(window.location.search);
    // let pageLoc = +queryParams.get("page");

    const onScreenShotEdit = () => {
        setEditButtonDisable(false);
        onClose();
        const cleanText = removeExtraSpaces(text)
        const cleanDescription = removeExtraSpaces(description)
        if(presentation){
            const body = {
                title:cleanText,
                shortDescription:cleanDescription,
            }
            editPresentationAPI(item.id,body)
                .then(resp=>{
                    getPresentationAPI(item.id)
                })
        }else{
            const body = {
                title:cleanText,
                shortDescription:cleanDescription,
            }
            editSlideAPI(item.id,body)
                .then(resp=>{
                    getPresentationAPI()
                })
        }


        // editScreenShotCardAPI(
        //     pageLoc,
        //     item.userId,
        //     item.id,
        //     text,
        //     item.data,
        //     onClose
        // );
    };

    return (
        <div className="card-body__edit-slide-title-form edit-slide-title-form">
            <h5>{presentation ? t('presentationSettings.edit') : t('presentationSettings.editSlide')}</h5>
            <p>{t('presentationSettings.name')}</p>
            <div className="edit-slide-title-form__btn-box">
                <input
                    onChange={(e) => setText(e.target.value)}
                    defaultValue={text}
                    autoFocus={true}
                    className='edit__slide__title__input'
                />
            </div>
            <p>{t('description')}</p>
            <div className="edit-slide-title-form__btn-box">
              <textarea
                  className="fixed-size-textarea"
                  rows="5"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  autoFocus={true}
              />
            </div>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
                <Button
                    onClick={onClose}
                    variant="contained"
                    style={{
                        backgroundColor: '#F7F7F7',
                        boxShadow: 'none',
                        color: '#6E6B7B',
                        textTransform: 'none',
                        width: '50%' }}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={onScreenShotEdit}
                    // disabled={!editButtonDisable || !description.length}
                    style={{
                        backgroundColor: '#24B263',
                        boxShadow: 'none',
                        color: '#fff',
                        textTransform: 'none',
                        width: '50%' }}
                >
                    {t('save')}
                </Button>
            </Stack>
        </div>
    );
};

const mapDispatchToProps = {
    editScreenShotCardAPI: editScreenShotCardAPI,
    editPresentationAPI:editPresentationAPI,
    getPresentationAPI:getPresentationAPI,
    editSlideAPI:editSlideAPI,
};

export default connect(null, mapDispatchToProps)(SlideEdit);
