import React, { Component } from "react";
import './styles.css';
import {withTranslation} from "react-i18next";

class AccountFooter extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const {t} = this.props
        return (
                <div className="acc-footer">
                    <div className='acc-footer__copy'>
                        <span className="upper">{`${t('footer.copyright')} ${new Date().getFullYear()}`}</span>
                        <span>{t('footer.allRightsReserved')}</span>
                    </div>
                    {/*<div className='acc-footer__logo'>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="166" height="29.001" viewBox="0 0 166 29.001"><g transform="translate(17420.879 19511.786)"><text transform="translate(-17350.879 -19486.785)" fill="#fff" fontSize="16" fontFamily="SegoeUI, Segoe UI" letterSpacing="0.08em"><tspan x="-69.274" y="0">Made By</tspan></text><g transform="translate(-17338.826 -19511.781)"><g transform="translate(-0.353 0.216)"><g transform="translate(0 0)"><g transform="translate(1.314 2.42)"><g transform="translate(0 8.922)"><path d="M72.758,186.275a5.373,5.373,0,0,1,3.891,1.663l1.3-1.283a7.1,7.1,0,1,0-.076,9.9l-1.334-1.246a5.323,5.323,0,1,1-3.78-9.034Z" transform="translate(-36.992 -184.46)" fill="#fff"/><path d="M33.483,194.648l-4.4-9.623H27.059L33.483,199l6.425-13.977H37.885Z" transform="translate(-27.059 -184.605)" fill="#fff"/><path d="M49.314,198.393h9.3v-1.815H51.153v-4.144H56.86V190.62H51.153v-3.78h7.464v-1.815h-9.3Z" transform="translate(-32.789 -184.605)" fill="#fff"/><path d="M109.923,184.46a7.1,7.1,0,1,0,7.2,7.1A7.159,7.159,0,0,0,109.923,184.46Zm0,12.393a5.29,5.29,0,1,1,5.359-5.289A5.331,5.331,0,0,1,109.923,196.853Z" transform="translate(-46.611 -184.46)" fill="#fff"/><path d="M85.068,186.84h4.961v11.554h1.839V186.84h4.961v-1.815H85.068Z" transform="translate(-42.019 -184.605)" fill="#fff"/></g><path d="M125.108,183.611l9.864-5.536-9.864-5.621v3.005l4.441,2.615-4.441,2.531Z" transform="translate(-52.139 -172.455)" fill="#ca000f"/></g><rect width="84" height="28" transform="translate(0.3 -0.221)" fill="none"/></g></g></g></g></svg>*/}
                    {/*</div>*/}
                </div>
        );
    }
}

export default  withTranslation()(AccountFooter);
