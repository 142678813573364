import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const sanitizeFilteredSelectedResult = (items) =>
  items.map((value, key) => ({
    id: value.id,
    value: value.id,
    title: value.title,
  }));

const selectAPIRequest = (url, body) => {
  return axios.post(url, body).then(extractItems);
};
export const getLessonsAPI = (url, body, state) => {
  return selectAPIRequest(url, body)
    .then((items) => sanitizeFilteredSelectedResult(items))
    .catch(console.error);
};
export const getLessonNavigationAPI = (id,topicId, lng = 1) => {
    return axios.get(`${process.env.REACT_APP_GIS_URL}/Lessons/TimeLine/${id}/topic/${topicId}?languageId=${lng}`)
        .then((resp) => resp.data)
        .catch(console.error);
};

