export const SET_ACTIVE_SHAPE = "@SHAPE/SET_ACTIVE_SHAPE";
export const SET_SHAPES = "@SHAPE/SET_SHAPES";
export const SET_SHAPE_MENU_IS_OPEN = "@SHAPE/SET_SHAPE_MENU_IS_OPEN";
export const SET_SHAPE_TOOL_STATE = "@SHAPE/SET_SHAPE_TOOL_STATE";


export const setActiveShape = data => ({
    type: SET_ACTIVE_SHAPE,
    payload: data,
});

export const setShapes = data => ({
    type: SET_SHAPES,
    payload: data,
});

export const setShapeMenuIsOpen = data => ({
    type: SET_SHAPE_MENU_IS_OPEN,
    payload: data,
})

export const setShapeToolState = data => ({
    type: SET_SHAPE_TOOL_STATE,
    payload: data
})
