import React from 'react';

const TiliIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="67px" height="67px" viewBox="0 0 67 67">
        <title>element-3</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-3438.000000, -48.000000)">
                <g transform="translate(3438.000000, 48.000000)">
                    <path
                        d="M61.4166667,11.1108334 C61.4166667,7.17458348 59.6300014,5.58333333 55.1912513,5.58333333 L43.9129154,5.58333333 C39.4741653,5.58333333 37.6875,7.17458348 37.6875,11.1108334 L37.6875,23.757084 C37.6875,27.7212505 39.4741653,29.2845827 43.9129154,29.2845827 L55.1912513,29.2845827 C59.6300014,29.3124994 61.4166667,27.7212512 61.4166667,23.7850013 L61.4166667,11.1108334 Z"
                        stroke="#FFFFFF"
                        strokeWidth="4.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillRule="nonzero"
                    />
                    <path
                        d="M61.4166667,43.9129154 C61.4166667,39.4741653 59.6300014,37.6875 55.1912513,37.6875 L43.9129154,37.6875 C39.4741653,37.6875 37.6875,39.4741653 37.6875,43.9129154 L37.6875,55.1912513 C37.6875,59.6300014 39.4741653,61.4166667 43.9129154,61.4166667 L55.1912513,61.4166667 C59.6300014,61.4166667 61.4166667,59.6300014 61.4166667,55.1912513 L61.4166667,43.9129154 Z"
                        stroke="#FFFFFF"
                        strokeWidth="4.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillRule="nonzero"
                    />
                    <path
                        d="M29.3125,11.1108334 C29.3125,7.17458348 27.5258347,5.58333333 23.0870846,5.58333333 L11.8087501,5.58333333 C7.36999996,5.58333333 5.58333333,7.17458348 5.58333333,11.1108334 L5.58333333,23.757084 C5.58333333,27.7212505 7.36999996,29.2845827 11.8087501,29.2845827 L23.0870846,29.2845827 C27.5258347,29.3124994 29.3125,27.7212512 29.3125,23.7850013 L29.3125,11.1108334 Z"
                        stroke="#FFFFFF"
                        strokeWidth="4.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillRule="nonzero"
                    />
                    <path
                        d="M29.3125,43.9129154 C29.3125,39.4741653 27.5258347,37.6875 23.0870846,37.6875 L11.8087501,37.6875 C7.36999996,37.6875 5.58333333,39.4741653 5.58333333,43.9129154 L5.58333333,55.1912513 C5.58333333,59.6300014 7.36999996,61.4166667 11.8087501,61.4166667 L23.0870846,61.4166667 C27.5258347,61.4166667 29.3125,59.6300014 29.3125,55.1912513 L29.3125,43.9129154 Z"
                        stroke="#FFFFFF"
                        strokeWidth="4.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fillRule="nonzero"
                    />
                    <path
                        d="M67,0 L67,67 L0,67 L0,0 L67,0 Z"
                        opacity="0"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default TiliIcon;
