// ArrowRightSVG.js
import React from 'react';

const ArrowRightSVG = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="94px"
        height="124px"
        viewBox="0 0 94 124"
        version="1.1"
    >
        <title>arrow-right</title>
        <defs>
            <filter
                x="-139.5%"
                y="-69.4%"
                width="379.1%"
                height="238.8%"
                filterUnits="objectBoundingBox"
                id="filter-1"
            >
                <feOffset
                    dx="0"
                    dy="0"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                />
                <feGaussianBlur
                    stdDeviation="10"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                />
                <feColorMatrix
                    values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                />
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
        </defs>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeDasharray="0,0"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g
                id="Read-more"
                transform="translate(-2578.000000, -853.000000)"
                fillRule="nonzero"
                stroke="#FFFFFF"
                strokeWidth="5.28"
            >
                <g
                    id="arrow-right-copy"
                    filter="url(#filter-1)"
                    transform="translate(2625.000000, 915.042653) rotate(-360.000000) translate(-2625.000000, -915.042653) translate(2610.078622, 885.042653)"
                >
                    <path d="M0,0 L29.8427564,30 L0,60" id="Vector"/>
                </g>
            </g>
        </g>
    </svg>
);

export default ArrowRightSVG;
