import axios from "axios";
import {toQueryString} from "../utils/utils";
const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data?.items || data
    return []
}
export const getMapStyles = (id, mapStyles,list,isLight) => {
    const baseMapGroupHasInSettings = list.findIndex(el=> id === el.mapTypeId) !== -1
    if(!baseMapGroupHasInSettings) return  mapStyles.map(el=> {
        el.hide = false
        if(el.isMain) {
            el.active = el.lightIsDefault ? 'light' : 'dark'
        }
        return el
    })
    return mapStyles.reduce((acum,style) => {
        const findStyle = list.find(item => style.id === item.mapTypeId);
        let activeStyle = false
        if(findStyle){
            style.hide = false
            if(style.id === id){
                if(isLight !== undefined){
                    activeStyle = isLight ? 'light' : 'dark'
                }else{
                    activeStyle = style.id === id ? 'light' : 'dark'
                }
            }
            if(activeStyle){
              style.active = style[activeStyle] ? activeStyle : 'light'
            }else{
              style.active = false
            }

            acum.push(style)
        }
        return acum
    },[])
}
// export const setupMapStyles = (props,lng) => {
//     const body = {"page":1,"perPage":1500,...props}
//     return  axios.post(`${process.env.REACT_APP_GIS_URL}/BaseMapGroup/DataProvider/${lng}`,body)
//         .then(extractDataByStatus)
// }
export const setupMapStyles = (props,lng) => {
    const params = {...props}
    return  axios.get(`${process.env.REACT_APP_GIS_URL}/BaseMapGroup/Setting/${lng}${toQueryString(params)}`,)
        .then(extractDataByStatus)
}
export const setupMapExtraLayers = (props) => {
    const body = {"page":1,"perPage":1500,...props}
    return  axios.post(`${process.env.REACT_APP_GIS_URL}/BaseMapStyles/DataProvider`,body)
        .then(extractDataByStatus)
}
export const setupBaseMapConfig = () => {
    return  axios.get(`${process.env.REACT_APP_GIS_URL}/StyleConfigurations`)
        .then(extractDataByStatus)
}


export const getMapStylesDark = (id, mapStyles) => {
    return mapStyles.map((style) => {
        style.active = style.id === id ? style.active === 'light' ? 'dark' : 'light' : false
        return style
    })
}

export const changeMapStylesList = (mapStyles, initialStyles) => {
    const data = [];
    if (!Array.isArray(mapStyles)) {
        return initialStyles
    }
     const result = initialStyles.reduce((acum,style) => {
            const findStyle = mapStyles.find(item => style.id === item.mapTypeId);
            if(findStyle){
                if(!window.scrrenShot){
                    style.active = false
                    if(style.isMain){
                        style.active = style?.lightIsDefault ? 'light' : 'dark'
                    }
                }
                style.hide = false
                style.order = findStyle.order

                acum.push(style)
            }
            return acum
     },[])?.sort((a, b) => a.order - b.order);
    return result
}
// export const changeMapStylesList = (mapStyles, initialStyles) => {
//     const data = [];
//     if (!Array.isArray(mapStyles)) {
//         return initialStyles
//     }
//      const result = initialStyles?.reduce((acum,style) => {
//             const newStyle = style
//             newStyle.hide = false
//             if(!window.scrrenShot){
//                 newStyle.active = false
//                 if(newStyle.isMain){
//                   newStyle.active = newStyle?.lightIsDefault ? 'light' : 'dark'
//                 }
//             }
//             acum.push(newStyle)
//             return acum
//      },[]);
//     console.log(initialStyles,'initialStyles')
//     console.log(result,'result')
//     return result
// }
