import React, {memo, useEffect} from 'react';
import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import EraserIcon from "../../../assets/imgs/PaintBar/eraser-icon.svg";
import toolboxStatesWithRedux from "../mapper";

const EraserMockupComponent = (props) => {
    const {
        setEraserClickedState,
        getEraserClickedState,
        setDrawerToolboxClickedState,
        getPainterStartData,
        setLineDrawToolboxState,
        setCommentToolboxState,
        setRulerClickedState,
        map,
    } = props
    const handleIconClick = () => {
        setEraserClickedState(!getEraserClickedState);
        setDrawerToolboxClickedState(false);
        setLineDrawToolboxState(false);
        setCommentToolboxState(false);
        setRulerClickedState(false);
    };

    useEffect(() => {
        if (getEraserClickedState && getPainterStartData) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.ERASER);
                map["dragPan"].disable();
            }, 50);
        } else {
            changeCursorIcon();
            map["dragPan"].enable();
            setEraserClickedState(false);
        }
    }, [getEraserClickedState, getPainterStartData]);


    return (
        <div
            onClick={handleIconClick}
            className={`pain_items ${getEraserClickedState ? "button_active" : ""}`}
        >
            <img src={EraserIcon} alt="" className="icon_img"/>
        </div>
    );
};
export default toolboxStatesWithRedux(memo(EraserMockupComponent));
