import React, {memo} from 'react';
import {changeCursorIcon} from "../../../shared/mockData";

import {DRAWER_TOOLBOX_LAYER} from "../DrawerToolbox/constants";
import {undoRedoDataKeys} from "../../mapToolbox/utils";

import ClearIcon from "../../../assets/imgs/PaintBar/clear.png";
import toolboxStatesWithRedux from "../mapper";

const DeleteToolbox = (props) => {

    const {
        setEraserClickedState,
        setDrawerToolboxClickedState,
        setCommentToolboxState,
        setPainterGeoJsonDataST,
        setLineDrawToolboxState,
        setRulerClickedState,
        setUndoRedoData,
        map
    } = props

    const getDrawingSource = () => map.getSource(DRAWER_TOOLBOX_LAYER);

    const removeAllLayersByClick = () => {
        resetActiveButton();
        if(getDrawingSource()) {
            getDrawingSource().setData({
                type: 'FeatureCollection',
                features: []
            });
            setPainterGeoJsonDataST(JSON.parse(JSON.stringify(getDrawingSource()._data)));
        }
        setUndoRedoData(undoRedoDataKeys.reduce((acc, key) => {
            acc[key] = { history: [{type: "FeatureCollection", features: []}], index: 0 };
            return acc;
        }, {}))
    };

    const resetActiveButton = () => {
        changeCursorIcon();
        setCommentToolboxState(false);
        setEraserClickedState(false);
        setDrawerToolboxClickedState(false);
        setLineDrawToolboxState(false);
        setRulerClickedState(false);
        map["dragPan"].enable();
    };

    return (
        <div
            className="pain_items"
            onClick={removeAllLayersByClick}
            style={{
                marginLeft: "7px",
            }}
        >
            <img src={ClearIcon} alt="" className="icon_img" />
        </div>
    );
};

export default toolboxStatesWithRedux(memo(DeleteToolbox));
