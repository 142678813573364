import { useLocation } from 'react-router-dom';


const LocalLocation = () => {
    const location = useLocation();
    const path = location.search;
    const store = window.localStorage;
    let url = '';
    let prevUrl = '';

    url = store.getItem('url');
    store.setItem('prevUrl', url);
    store.setItem('url', path);

    url = store.getItem('url');
    prevUrl = store.getItem('prevUrl');

    return { url, prevUrl };

}
export default LocalLocation