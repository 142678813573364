import {
    SET_IS_PRESENTATION_MODE,
    SET_PRESENTATION_MODE,
    SET_PRESENTATION_MODE_LOADING,
    SET_SELECTED_PRESENTATION,
    SET_PRESENTATION_FILTERED_TOTAL,
    SET_EXTERNAL_PRESENTATION_MOD_DATA, SET_IS_PRESENTATION_SHOW
} from "../constants";
import {stringify} from "flatted";
import {setRemoveCardId, setScreenShotButtonStateST} from "./mapStateAction";
import {
    presentationPostMethod,
    extractResult,
    presentationGetMethod, extractItems, presentationPutMethod, presentationPatchMethod
} from "../../service/presentationMode";
import {addMultipleSlidesAPI, setSlidesItems} from "./slides";
import {genPatchBody} from "../../utils/utils";
import {removeScreenShot} from "../../service/mapStateService";
import i18n from "i18next";
export const setSelectedPresentationData = (data) => ({
    type: SET_SELECTED_PRESENTATION,
    payload: data,
})
export const setPresentationModeData = (data) => ({
    type: SET_PRESENTATION_MODE,
    payload: data,
})
export const setPresentationModeLoading = (bool) => ({
    type: SET_PRESENTATION_MODE_LOADING,
    payload: bool,
})
export const setExternalPresentationModData = (data) => ({
  type: SET_EXTERNAL_PRESENTATION_MOD_DATA,
  payload: data,
})
export const setIsPresentationMode = (bool) => ({
    type: SET_IS_PRESENTATION_MODE,
    payload: bool,
})
export const setIsPresentationShow = (bool) => ({
    type: SET_IS_PRESENTATION_SHOW,
    payload: bool,
})
export const setPresentationFilteredTotal = (length) => ({
    type: SET_PRESENTATION_FILTERED_TOTAL,
    payload: length,
})

export const setMenu = (bool) => (dispatch) => {
    dispatch(setIsPresentationMode(bool));
};
export const setIsPresentationShowF = (bool) => (dispatch) => {
    dispatch(setIsPresentationShow(bool));
};

export const getPresentationAPI = (id) => (dispatch,states) => {
    const state = states()
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/${id || state.presentationMode.data?.id}`;
    dispatch(setPresentationModeLoading(true))
    return presentationGetMethod(url)
        .then(extractResult)
        .then((resp)=>{
            dispatch(setPresentationModeData(resp))
            // dispatch(setSlidesItems(resp?.slides || []))
            return resp
        })
}
export const postExternalPresentationAPI = (data) => (dispatch) => {
  const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/ExternalDataProvider`;
  return presentationPostMethod(url, data)
    .then(extractResult)
    .then((data) => {
      dispatch(setExternalPresentationModData(data))
      return data;
  });
};

export const editPresentationAPI = (id,data) => (dispatch,states) => {
    const state = states()
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/${id || state.presentationMode.data?.id}`;
    const body = Object.keys(data)?.map(el=>(
        {
            key:el,
            value:data[el]
        }
    ))
    // dispatch(setPresentationModeLoading(true))
    return presentationPatchMethod(url, genPatchBody(body))
        .then(extractResult)
        // .then((resp)=>{
        //     dispatch(setPresentationModeData(resp))
        //     dispatch(setSlidesItems(resp?.slides))
        //     return resp
        // })
}



export const createPresentationAPI = () => (dispatch) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation`;
    const title = i18n.language === 'hy' ? 'Ցուցադրություն' : 'Presentation'
    const body = {
        "title": title,
        "shortDescription": "",
        "description": "",
        "slideTransitionDuration": 10,
        "imageUrl": null,
        "showDescriptionPopup": true,
        "descriptionPopupTransitionDuration": 0,
        "isVoiceEnabled": true,
        "audioGender": "Male"
    }
    dispatch(setPresentationModeLoading(true))
    return presentationPostMethod(url, body)
        .then(extractResult)
        .then((resp) => {
            dispatch(setPresentationModeData(resp))
            dispatch(setSlidesItems(resp?.slides))
            return resp
        })
}
export const removePresentationAPI =
    (cardId) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/Presentation/${cardId}`;
        return removeScreenShot(url)
            .then(resp=>{
                dispatch(setIsPresentationMode(false))
                return resp
            })
    };
