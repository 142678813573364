import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';

const NavigationSlides = ({
                              galleryItems,
                              updateSlideMaximizeState,
                              handleYoutubeImagePathGen,
                              playIcon
                          }) => {
    // Function to render an image slide
    const renderImageSlide = (slide) => (
        <div className="single-gallery-img">
            <img src={slide?.path} alt={slide.caption} />
        </div>
    );

    // Function to render a video slide
    const renderVideoSlide = (slide) => (
        <div className="single-gallery-video is-video">
            <video
                data-item={true}
                src={slide?.path}
                className="hiddConroll"
                controls="hidden"
                style={{ pointerEvents: "none" }}
            />
            <div className={"single-gallery-video-icon"}>
                {/* SVG content can be rendered here if provided */}
            </div>
        </div>
    );

    // Function to render a YouTube slide
    const renderYouTubeSlide = (slide) => (
        <div className="single-gallery-iframe">
            <img src={handleYoutubeImagePathGen(slide?.path)} alt={slide.caption} />
            <img src={playIcon} className={'videoPlayBtn'} />
        </div>
    );

    // Render each slide based on its type
    return (
        <>
            {galleryItems
                ?.filter((x) => x.type !== 4)
                ?.map((slide) => (
                    <SplideSlide
                        key={slide?.path}
                        className={`gallery-item single-gallery ${slide.mediaId}`}
                        onClick={() => updateSlideMaximizeState(slide.type)}
                    >
                        {slide.type === 0 ? renderImageSlide(slide) :
                            slide.type === 1 ? renderVideoSlide(slide) :
                                slide.type === 3 ? renderYouTubeSlide(slide) :
                                    null}
                    </SplideSlide>
                ))}
        </>
    );
};

export default NavigationSlides;
