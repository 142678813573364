import React from "react"
import {connect, Provider} from "react-redux";
import {getSpriteBase64Data, getSpriteData, getSpriteURLData} from "../../../store/selectors";
import {store} from "../../../store";
import './style.css'

const SpriteImage = (props) => {
  const {spriteJson,spriteURL,name,centered=false} = props
  const {width,height,x,y} = name in spriteJson
      ? spriteJson[name]
      : spriteJson['default'] || {}
  return (
    <div className={`sprite-icon ${centered?'centered':''}`} style={{
      '--image-src':`url(${props.getSpriteBase64Data})`,
      '--width':width  + 'px',
      '--height':height  + 'px',
      '--position-x':'-' + x + 'px',
      '--position-y':'-' + y + 'px',
    }}/>
  )
}
const mapStateTopProps = (state) => ({
  spriteJson: getSpriteData(state),
  spriteURL: getSpriteURLData(state),
  getSpriteBase64Data: getSpriteBase64Data(state),
});
const ConnectedSpriteImage = connect(mapStateTopProps)(SpriteImage)
const ConnectedSpriteImageComponent = (props) => {
    return (
        <Provider store={store}>
            <ConnectedSpriteImage {...props}/>
        </Provider>
    )
}
export default ConnectedSpriteImageComponent