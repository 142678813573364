import { parse, stringify, toJSON, fromJSON } from "flatted";
import {getLocaleKey} from "../../../../utils/helpers";

export const generatePath = (topicId,lessonId,id, userId) => {
  let url = window.location.origin + '/map?'
  if(id) url += `presentation=${id}`
  if(topicId) url += `&type=topic&topicID=${topicId}`
  if(lessonId) url += `&type=lesson&lessonID=${lessonId}`
  if(userId) url += `&userId=${userId}`
  return url
};
export const getInfoSection = (el, data) => {
  const id = data?.id;
  const userId = data?.createdBy;
  const createAt = data?.createdAt;
  const modifiedAt = data?.modifiedAt;
  const title = data?.title;

  const info = data?.data && parse(data?.data);
  let result = true;
  if (el.section === "new-tab") result = generatePath(data.topicId,data.lessonId, id, userId);
  if (el.section === "dynamic-title") result = title;

  if (el.section === "dynamic-info") {
    result = [
      {
        id: 1,
        subject: "Դաս",
        title: info?.lessonTitle,
      },
      {
        id: 2,
        subject: "Թեմա",
        title: info?.topicStaticTitle,
      },
    ];
  }

  if (el.section === "date-info") {
    const date = new Date(createAt* 1000);
    const formattedCreateAtDate = date.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const dateMod = new Date(modifiedAt* 1000);

    const formattedModifiedAtDate = dateMod.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    result = [
      {
        id: 1,
        dateType: "createdAt",
        dateTime: `${formattedCreateAtDate}`,
      },
      {
        id: 2,
        dateType: "modifiedAt",
        dateTime: modifiedAt ? `${formattedModifiedAtDate}` : null,
      },
    ];
  }
  return result;
};
export const checkIsEmptyValues = (el) => {
  let result = getInfoSection(el);
  if (Array.isArray(result)) {
    result = !!getInfoSection(el)?.length;
  } else if (typeof result === "string" || typeof result === "object") {
    result = !!getInfoSection(el);
  } else if (typeof result === "function") {
    result = !!result()?.content?.length;
  }
  return result;
};
export const hrefToPresentationLocation = (item) =>{
  let location = '';
  if(item?.topicId) location = `/map?type=topic&topicID=${item?.topicId}&presentation=${item.id}`
  if(item?.lessonId) location = `/map?type=lesson&lessonID=${item?.lessonId}&presentation=${item.id}`
  if(location) return window.open(location, '_blank');
  return false
}

