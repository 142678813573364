export const checkValidationErrors = (fields, t) => {
  let errors = {};
  let hasError = false;

  //Password
  if (!fields["token"]) {
    hasError = true;
    errors["token"] = t('errors.requiredField');
  }

  //Password
  if (!fields["password"]) {
    hasError = true;
    errors["password"] = t('errors.requiredField');
  }

  if (fields["password"]?.length < 6) {
    hasError = true;
    errors["password"] =
      "Ձեր գաղտնաբառը պետք է պարունակի առնվազն 6 նիշ*";
  }

  //CPassword
  if (!fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = t('errors.requiredField');
  }

  if (fields["password"] !== fields["cPassword"]) {
    hasError = true;
    errors["cPassword"] = "Խնդրում ենք մուտքագրել վավեր գաղտնաբառ։";
  }

  return hasError ? errors : null;
};
