import React, {memo, useEffect, useState} from 'react';
import './advancedShapes.css';
import usePrevious from '../../../../hooks/usePrevious';
import {ifHasAlpha} from "../utils";
import {SHAPE_DEFAULT_COLOR} from "../constants";

const LineArrow = ({ width, height, strokeColor, fillColor, arrowWidth, onChange }) => {
    const [arrowWidthState, setArrowWidthState] = useState(width * arrowWidth);
    const [lineWidthState, setLineWidthState] = useState(width - arrowWidthState);
    const [heightState, setHeightState] = useState(height);
    const fillHasAlpha = ifHasAlpha(fillColor)

    const prevWidth = usePrevious(width);
    const prevHeight = usePrevious(height);

    useEffect(() => {
        // Proportional resizing when corner handles are dragged
        if (!prevWidth && !prevHeight) return;

        if (prevWidth && prevHeight) {
            const widthRatio = width / prevWidth;
            const heightRatio = height / prevHeight;

            if (prevWidth !== width && prevHeight !== height) {
                // When both width and height are changed proportionally
                setArrowWidthState(prev => prev * widthRatio);
                setLineWidthState(prev => prev * widthRatio);
                setHeightState(prev => prev * heightRatio);
            } else if (prevWidth !== width) {
                // Independent width adjustment when only the width changes
                const widthDifference = width - prevWidth;
                setLineWidthState(prev => prev + widthDifference);

            } else if (prevHeight !== height) {
                // Vertical scaling when only the height changes
                setHeightState(height);
            }
        }
    }, [width, height, prevWidth, prevHeight]);

    useEffect(() => {
        onChange?.({arrowWidth:  arrowWidthState / width});
    },[arrowWidthState, width]);

    return (
        <div
            className="line-arrow--target"
            style={{
                display: 'flex',
                height: heightState,
                willChange: 'auto',
            }}
        >
            <div className="line-arrow--line" style={{width: lineWidthState}}>
                <svg
                    viewBox={`0 0 100 ${heightState}`}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width={fillHasAlpha ? "100%" : "101%"}
                    height="100%"
                >
                    <rect
                        x="0"
                        vectorEffect="non-scaling-stroke"
                        y={heightState * 0.35}
                        width={fillHasAlpha ? "100%" : "101%"}
                        height={heightState * 0.3}
                        fill={fillColor || SHAPE_DEFAULT_COLOR}
                    />
                    <path
                        d={`M 0 ${heightState * 0.35} H 100 M 0 ${heightState * 0.35} V ${heightState * 0.35 + heightState * 0.3} M 0 ${heightState * 0.35 + heightState * 0.3} H 100`}
                        fill={"none"}
                        stroke={strokeColor}
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                    />
                </svg>
            </div>

            <div className="line-arrow--arrow" style={{width: arrowWidthState, willChange: 'auto'}}>
                <svg preserveAspectRatio="none"
                     width="100%"
                     height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 0 0 L 200 100 L 0 200 M 0 0 L 0 70 M 0 280 L 0 130"
                          fill={fillColor || SHAPE_DEFAULT_COLOR}
                          stroke={strokeColor || SHAPE_DEFAULT_COLOR}
                          strokeWidth="2"
                          vectorEffect="non-scaling-stroke"
                          strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
};

export default memo(LineArrow);
