import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import "./style.css";
import PresentationIcon from "../../Presentation";
import CloseIcon from '../../../../assets/imgs/icons/Close-blue.svg'
import {getMultipleSlidsByPresentationAPI, setSelectedSlide, setSlidesItems} from "../../../../store/actions/slides";
import {
    getPresentationData,
    getPresentationModeData,
    getSlidesData,
    getSlidesFilteredLength
} from "../../../../store/selectors";
import {setMenu} from "../../../../store/actions/presentationMode";
import SliderCards from "../ChooseSlides/SliderCards";
import SearchSection from "../SearchSection";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import {useTranslation} from "react-i18next";
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced300 = debounce((fn) => fn(), 300);
const SeeAllSlides = (props) => {
    const {t} = useTranslation()
    const { getSlidesFilteredLength,onClose, slides, setSelectedSlide, getMultipleSlidesByPresentationAPI,getPresentationModeData } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const [page, setPage] = useState(1);
    const [searchParamInfo, setSearchParamsInfo] = useState({});

    const handleChangePage = (e, value) => {
        const pageLoc = +value;
        if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
            setPage(() => pageLoc);
        }
    };
    const handleNextPage = () =>{
        const nextPage = page+1
        handleChangePage(null, nextPage)
    }

    const handleSetSearchParams = (e) =>{
        debeounced300(()=> {
            handleChangePage(null,1)
            const newSearch = {...searchParamInfo}
            newSearch.searchTerm = e?.target?.value || ''
            setSearchParamsInfo(prev => newSearch)
        })
    }
    const handleSingleCardClick = (index,item) => {
        if (activeIndex === index) {
            setActiveIndex(null);
            setSelectedSlide(null)
        } else {
            setActiveIndex(index);
            setSelectedSlide(item)
            onClose()
        }
    };
    const handleGetSlides = () => {
        let pageLoc = page;
        const search = {
            page,
            perPage:10,
            ...searchParamInfo
        }
        if (pageLoc > Math.ceil(getSlidesFilteredLength / 10))
            pageLoc = Math.ceil(getSlidesFilteredLength / 10);
        if (pageLoc < 1) pageLoc = 1;
        if (!isNaN(pageLoc) && typeof pageLoc === "number") {
            if (pageLoc !== page) setPage(() => pageLoc);
            getMultipleSlidesByPresentationAPI([`${getPresentationModeData?.id}`],search,'add');
        }
    }
    useEffect(handleGetSlides, [page]);
    // useEffect(() => {
    //     handleGetSlides()
    // }, [searchParamInfo]);
    return (
        <div className="card-body__choose-slide-form">
            <div className='choose__header__container'>
                <div className='first__section'>
                    <PresentationIcon isActive={false}/>
                    <h5>{t('allSlides')}</h5>
                </div>
                <button onClick={onClose}>
                    <img src={CloseIcon}/>
                </button>
            </div>
            {/*<input className='search__container' placeholder='Փնտրել'/>*/}
            {/*<SearchSection handleSetSearchParams={handleSetSearchParams}/>*/}
            {/*<div className='cards__container'>*/}
                <InfiniteScroll
                    dataLength={slides.length}
                    next={handleNextPage}
                    hasMore={getSlidesFilteredLength > slides.length}
                    loader={<div style={{scale:'0.5',width:'100%',display:'flex',justifyContent:'center'}}> <Loader /></div>}
                    className="cards__container"
                    height={400}
                >
                    {slides?.map((item) => (
                            <SliderCards
                                index={item?.order}
                                key={item?.id}
                                active={activeIndex == item.id}
                                onClick={() => handleSingleCardClick(item?.id, item)}
                                text={item?.title}
                                imageUrl={item?.imageUrl}/>
                        )
                    )}
                </InfiniteScroll>
            {/*</div>*/}
            <div className='bottom__container'>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setSlidesItems: setSlidesItems,
    setIsPresentationMode: setMenu,
    setSelectedSlide:setSelectedSlide,
    getMultipleSlidesByPresentationAPI:getMultipleSlidsByPresentationAPI,
};
const mapStateToProps = (state) => ({
    slides: getSlidesData(state),
    getPresentationModeData: getPresentationModeData(state),
    getSlidesFilteredLength: getSlidesFilteredLength(state),

});

export default connect(mapStateToProps, mapDispatchToProps)(SeeAllSlides);
