import {formatArticleTime, formatLessonTime, formatServerResponseTime} from '../utils'
import {getFilteredCategoriesData} from "../../../store/selectors";
import {getArticleFromAPI, loadingArticles, setArticles, setSelectedArticle} from "../../../store/actions/articles";
import {getArticleAPI} from "../../../service/article";
import {setCompassNewAngle, setCompassRotate} from "../../../store/actions/compassStateAction";
import i18n from "i18next";
export const processMiddleMaxTimeLineLessonItemsRendering = (data,categories,getState,dispatch) => {
    const screenshotLanguage = getState().mapState.screenshotLanguage
    const selectedLanguage = screenshotLanguage || (localStorage.getItem('language') === 'hy' ? 1 : 2)

    function convertToFourDigitYear(year) {
        var isNegative = year < 0;
        var absoluteYear = Math.abs(year);
        var paddedYear = absoluteYear.toString().padStart(4, '0');
        var date = new Date('0001-01-01');
        date.setFullYear(paddedYear);
        if (isNegative) {
            // Convert negative year to BCE
            date.setFullYear(-paddedYear);
        }
        return date;
    }
    function getArticleApi (id,getState,dispatch,stopFly) {
        dispatch(loadingArticles());
        const lng = getState().mapState.screenshotLanguage || (i18n.language === 'hy' ? 1 : 2)

        getArticleAPI(id,lng).then((article) => {
            let articles = getState().articles.data;
            const openedArticles = articles.filter((x) => x.show);
            if (
                articles.find((x) => x.id == id) !== undefined ||
                typeof articles.find((x) => x.id == id) !== "undefined"
            ) {
                const indexArticle = articles.findIndex((x) => x.id === article.id);
                articles[indexArticle].show = true;
            } else {
                if (openedArticles.length > 0) {
                    openedArticles.forEach((article) => {
                        const indexArticle = articles.findIndex((x) => x.id === article.id);
                        articles.splice(indexArticle, 1);
                    });
                }
                articles.push(article);
            }

            dispatch(
                setSelectedArticle({
                    id: id,
                    type: article.articleData.type,
                    fly: !!stopFly
                })
            );
            // dispatch(setSearch(false))
            dispatch(setArticles(articles));
        });
    }

    let middleTimeLineDataItem = new Array()
    middleTimeLineDataItem.__proto__.getItemByDateRange = function (step,time) {
        const fullYear = time.getFullYear()

        const rangeStart = {
            1000:[fullYear+250, 0, 0],
            500:[fullYear+150, 0, 0],
            100:[fullYear+5, 0, 0],
            50:[fullYear+2, 0, 0],
            10:[fullYear+1, 0, 0],
            5:[fullYear-1, 6, 0],
            1:[fullYear, 0, 0],
        }
        const rangeEnd = {
            1000:[fullYear-250, 0, 0],
            500:[fullYear-150, 0, 0],
            100:[fullYear-5, 0, 0],
            50:[fullYear-2, 0, 0],
            10:[fullYear-1, 0, 0],
            5:[fullYear+1, 6, 0],
            1:[fullYear, 0, 0],
        }
        const start =  convertToFourDigitYear(...rangeStart[step]);
        const end =  convertToFourDigitYear(...rangeEnd[step]);
        return this.filter((item)=>{
            return (
                item.className === "related-all-max" &&
                (new Date(item.start) >= start && new Date(item.start) <= end ||
                    new Date(item.end) >= start && new Date(item.end) <= end ||
                    new Date(item.start) <= start && new Date(item.end) >= end)
            );
        })
    }
    const { startFrom, endTo } = formatServerResponseTime(data)
    data.articles?.map((article,index)=>{
            let cat = categories.find((x) => x.id === article?.subcategory[0]?.categoryId)
            const { articleStartFrom, articleEndTo } = formatArticleTime(article)
            const content = article.content.find(x => x.languageId === selectedLanguage)
            middleTimeLineDataItem.push({
                id: `ra-${data.id}-${article.id}`,
                articleId:article.id,
                order: index+1,
                content: content.mainTitle,
                // prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
                category:cat,
                start: articleStartFrom || startFrom,
                end:articleEndTo || endTo,
                groupId: `${data.id}-${article.id}`,
                group: cat?.id,
                className:'related-all-max',
                getArticle:(id)=> {
                    dispatch(setCompassNewAngle(0));
                    dispatch(setCompassRotate(0));
                    getArticleApi(id, getState, dispatch)
                }
            })
    })
    return middleTimeLineDataItem
}
