import React, { memo } from 'react';
import NextIcon from "../../../../assets/imgs/PaintBar/next-icon.png";
import toolboxStatesWithRedux from "../../mapper";
import useUndoRedo from '../useUndoRedo'; // Import the custom hook

const RedoToolbox = (props) => {
    const {
        getSelectedToolUndoRedo,
        getUndoRedoData,
        setUndoRedoData,
        map
    } = props;

    const { currentUndoRedoSelectedComponent, updateSourceData, setGeoJsonData } = useUndoRedo(
        getSelectedToolUndoRedo,
        getUndoRedoData,
        setUndoRedoData,
        map
    );

    const onRedoHandle = () => {
        if (!currentUndoRedoSelectedComponent.current || currentUndoRedoSelectedComponent.current.index >= currentUndoRedoSelectedComponent.current.history.length - 1) return;

        const newValue = currentUndoRedoSelectedComponent.current.history[currentUndoRedoSelectedComponent.current.index + 1];

        setUndoRedoData({
            ...getUndoRedoData,
            [getSelectedToolUndoRedo]: {
                ...currentUndoRedoSelectedComponent.current,
                index: currentUndoRedoSelectedComponent.current.index + 1,
            },
        });

        setGeoJsonData(newValue);
        updateSourceData(newValue);
    };

    return (
        <div className="pain_items" onClick={onRedoHandle}>
            <img src={NextIcon} alt="" className="icon_img" />
        </div>
    );
};

export default toolboxStatesWithRedux(memo(RedoToolbox));
