import React from "react";

const DEFAULT_SIZE = 20;
const DEFAULT_COLOR = '#FFC107';

const IsEditoralIcon = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_57_20408)">
                <path
                    d="M27.927 10.7407C27.7437 10.1737 27.2408 9.77105 26.6459 9.71743L18.5643 8.98361L15.3686 1.50384C15.133 0.955667 14.5964 0.60083 14.0001 0.60083C13.4039 0.60083 12.8673 0.955667 12.6316 1.50512L9.43597 8.98361L1.35312 9.71743C0.759232 9.77233 0.257632 10.1737 0.0732711 10.7407C-0.11109 11.3077 0.0591716 11.9296 0.508432 12.3216L6.61714 17.6789L4.81582 25.6137C4.68402 26.1972 4.91046 26.8002 5.39454 27.1502C5.65474 27.3382 5.95916 27.4339 6.26615 27.4339C6.53083 27.4339 6.79338 27.3625 7.02902 27.2215L14.0001 23.0551L20.9687 27.2215C21.4786 27.5283 22.1214 27.5003 22.6045 27.1502C23.0888 26.7992 23.315 26.1959 23.1832 25.6137L21.3819 17.6789L27.4906 12.3226C27.9398 11.9296 28.1114 11.3087 27.927 10.7407Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_57_20408">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default IsEditoralIcon;