import React from 'react'

const CloseDarkModal = () => {
    return (
      <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
    <g id="Close-blue" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
        <g id="Group" transform="translate(1.312816, 2.196699)" stroke="#2C476C" strokeWidth="1.5">
            <line x1="-1.7547574" y1="5.1202426" x2="13.2452426" y2="5.1202426" id="Vector" transform="translate(5.745243, 5.745243) rotate(-315.000000) translate(-5.745243, -5.745243) "></line>
            <line x1="6.00412607" y1="13.2452426" x2="6.00412607" y2="-1.7547574" id="Vector" transform="translate(6.629126, 5.745243) rotate(-315.000000) translate(-6.629126, -5.745243) "></line>
        </g>
    </g>
</svg>
    )
}
export default CloseDarkModal
