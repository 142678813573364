import { getSchoolsAPI } from "../../service/schools";
import { SET_SCHOOLS, LOADING_SCHOOLS } from "../constants";
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils";

export const loadingSchools = () => ({
  type: LOADING_SCHOOLS,
});

export const setSchools = (schools) => ({
  type: SET_SCHOOLS,
  payload: schools,
});

export const getSchoolsFromAPI = (lng = 1) => (dispatch, getState) => {
  const state = getState();
  const { url, body } = constructSelectedItemApiParams(
    "school",
    state.user.data,
    lng
  );
  dispatch(loadingSchools());

  getSchoolsAPI(url, body).then((schools) => {
    dispatch(setSchools(schools));
  });
};
