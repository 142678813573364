import React from "react";

const ArticlePresentationScreenshot = ({white}) => {
    return (
        <svg width="20" height="37" viewBox="0 0 35 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.49665 13C3.87977 13 4.9933 13.9648 4.9933 15.1633V36.8266C4.9933 42.8086 10.5844 47.6633 17.5 47.6633C24.4156 47.6633 30.0067 42.8086 30.0067 36.8266V10.8266C30.0067 7.24141 26.6544 4.32656 22.505 4.32656C18.3557 4.32656 15.0033 7.24141 15.0033 10.8266V32.5C15.0033 33.6984 16.1286 34.6633 17.5 34.6633C18.8714 34.6633 19.9966 33.6984 19.9966 32.5V15.1633C19.9966 13.9648 21.1219 13 22.4933 13C23.8764 13 24.99 13.9648 24.99 15.1633V32.5C24.99 36.0953 21.6376 39 17.4883 39C13.3389 39 9.9866 36.0953 9.9866 32.5V10.8367C9.99832 4.85469 15.6011 0 22.505 0C29.4089 0 35 4.85469 35 10.8367V36.8367C35 45.2156 27.1584 52 17.5 52C7.84159 52 0 45.2055 0 36.8367V15.1633C0 13.9648 1.11353 13 2.49665 13Z" fill={white ? "#fff" : "#2C476C"}/>
        </svg>
    );
};
export default ArticlePresentationScreenshot;
