import React from "react";
import "./style.css";
import { getAboutData } from "../../store/selectors";
import { setAbout } from "../../store/actions/about";
import { connect } from "react-redux";

export const About = (props) => {
  return (
    <div className="about-popup">
      <button
        className="popup-close"
        type="button"
        onClick={() => props.handleAbout ? props.handleAbout() : props.setAbout(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M0,0H12"
            transform="translate(6 12)"
            fill="none"
            stroke="#292d32"
            strokeWidth="1.5"
            strokeDasharray="0 0"
          />
          <path
            d="M0,12V0"
            transform="translate(12 6)"
            fill="none"
            stroke="#292d32"
            strokeWidth="1.5"
            strokeDasharray="0 0"
          />
          <path d="M24,0V24H0V0Z" fill="none" opacity="0" />
        </svg>
      </button>
      <div className="about-popup-content">
        <h2 className="popup-title">աշխարհացույց</h2>
        <h3 className="popup-subtitle">
          Ճանաչել զիմաստութիւն եւ զխրատ, իմանալ զբանս հանճարոյ
        </h3>
        <p className="popup-text">
          «Աշխարհացույց»-ը նորարարական կրթական նախագիծ է՝ ստեղծված գիտական
          ​​մտքի, տեխնոլոգիական լուծումների և ուսուցման ժամանակակից մեթոդների
          համատեղմամբ։ Այն նպատակ ունի պատմության, աշխարհագրության և այլ
          առարկաների ուսուցումը դարձնել պատկերավոր, հետաքրքիր և թիրախային հայ
          աշակերտի համար։
        </p>
        <p className="bold-text">Ինչու՞ է պետք «Աշխարհացույցը»</p>
        <p className="popup-text">
          «Աշխարհացույց» կրթական նախագիծը ինտեգրված կրթատեղեկատվական առցանց
          հարթակի օգնությամբ առաջարկում է Հայոց պատմություն առարկայի դասավանդման
          ինտերակտիվություն և բովանդակության վիզուալիզացիա՝ համալրված
          համապատասխան գործիքակազմով, պատմության հետաքրքիր դրվագներով,
          ֆիլտրերով, որոնողական համակարգով և այլն: Այն պատմական ցանկացած
          իրադարձություն կամ անուն կապում է տարածության ու ժամանակի հետ՝
          հնարավորություն տալով դասավանդողին իր խոսքն առավել պատկերավոր ու
          հանրամատչելի մատուցել աշակերտներին։
        </p>
      </div>
    </div>
  );
};
const mapStateTopProps = (state) => ({
  about: getAboutData(state),
});

const mapDispatchToProps = {
  setAbout: setAbout,
};

export default connect(mapStateTopProps, mapDispatchToProps)(About);
