import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';

const SlidesCaption = ({ galleryItems }) => {
    // Render each slide caption
    const renderCaptionSlide = (slide) => (
        <SplideSlide key={slide?.path} className={`gallery-item ${slide.mediaId}`}>
            <div className={'gallery-caption'}>
                <span dangerouslySetInnerHTML={{ __html: slide.caption }}></span>
            </div>
        </SplideSlide>
    );

    return (
        <>
            {galleryItems
                ?.filter((x) => x.type !== 4)
                ?.map(renderCaptionSlide)}
        </>
    );
};

export default SlidesCaption;
