import { SET_SPRITE_DATA, SET_SPRITE_BASE64,SET_SPRITE_URL } from "../constants";
import {generateUniqueId} from "../../utils/helpers";
const initialState = {
  data: {},
  url: '/BaseSprite',
  base64: '',
  generatedUniqueId: generateUniqueId(),
};

export const spriteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPRITE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_SPRITE_URL:
      return {
        ...state,
        url: action.payload,
      };
    case SET_SPRITE_BASE64:
      return {
        ...state,
        base64: action.payload,
      };
    default:
      return state;
  }
};
