import { useEffect } from 'react';
import PropTypes from 'prop-types';

import {getCurrentUser} from "../../../helpers";

const GTag = ({ children, location }) => {

  const sendGtagBYUserInfo = (user) => {
    window.gtag('set', { user_id: user.id })
    window.gtag('config', process.env.REACT_APP_GA_TAG_ID, {
      page_path: location.pathname,
    })
  }
  const userInfoSender = async () => {
    const accessToken = localStorage.getItem('accessToken')
    const isAdmin = localStorage.getItem('admin') === 'true'
    const userInfo = localStorage.getItem('userInfo')
    if (!accessToken || isAdmin) return window.gtag('config', process.env.REACT_APP_GA_TAG_ID)
    if (userInfo && JSON.parse(userInfo)?.data) return sendGtagBYUserInfo(JSON.parse(userInfo)?.data)
    await getCurrentUser()
        .then(res=>{
          const userInfo = localStorage.getItem('userInfo')
          if (userInfo && JSON.parse(userInfo)?.data) return sendGtagBYUserInfo(JSON.parse(userInfo)?.data)
        })
  }

  useEffect(() => {
    userInfoSender()
  }, [location.pathname])

  return children;

};

GTag.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
};

export default GTag;
