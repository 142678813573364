import {connect} from "react-redux";

//COMPONENTS
import CardBody from "../CustomCardComponents/CardBody";
import NewTab from "../CustomCardComponents/NewTab";
import DynamicTitle from "../CustomCardComponents/DynamicTitle";
import Line from "../CustomCardComponents/Line";
import DynamicInfo from "./DynamicInfo";
import DateSection from "./DateSection";
import DynamicScreenShotEdit from "./DynamicScreenShotEdit";
import PresentationIcon from "../../../assets/imgs/icons/presentations-icon.svg";

//CONSTANTS
import { ARTICLE_READ_MORE_SECTIONS } from "./constants";


//UTILS
import {checkIsEmptyValues, getInfoSection, hrefToPresentationLocation} from "./utils";

//STYLES
import "./style.css";
import DynamicActionBtn from "./PresentationActionBtn";
import Popup from "../Popup";
import React, {useEffect, useLayoutEffect, useState} from "react";
import DynamicScreenShotRemove from "./DynamicScreenShotRemove";
import { getRemoveCardId } from "../../../store/selectors";
import ScreenShotDynamicInfo from "./ScreenShotDynamicInfo/ScreenShotDynamicInfo";
import PresentationInfo from "./PresentationInfo/PresentationInfo";
import PresentationImageInfo from "./PresentationImageInfo/PresentationImageInfo";
import {editPresentationCardAPI, removePresentationCardAPI} from "../../../store/actions/presentation";
import IsEditoralIcon from "../IsEditoralIcon";
import {getLocaleKey} from "../../../utils/helpers";
import WarningLanguage from "./WarningLanguage";
import {userInfoSettingsURI} from "../../../shared/mockData";
import axios from "axios";
import i18n from "i18next";
import {changeLanguage} from "../../../utils/utils";

const PresentationCard = (props) => {
  const { item,editPresentationCardAPI, handleGetData, removePresentationCardAPI } = props;
  const [state, setState] = useState(true);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [removePopupShow, setRemovePopupShow] = useState(false);
  const [warningLanguagePopupShow, setWarningLanguagePopupShow] = useState(false);
  //EDIT
  const handleOpenEditPopup = () => {
    setEditPopupShow(() => true);
  };
  const handleCloseEditPopup = () => {
    setEditPopupShow(() => false);
  };
  const handleCloseWarningPopup = () => {
    setWarningLanguagePopupShow(() => false);
  };
  const handleEditItem = (e) => {
    e.stopPropagation()
    handleOpenEditPopup();
  };

  //REMOVE
  const handleOpenRemovePopup = () => {
    setRemovePopupShow(() => true);
  };
  const handleCloseRemovePopup = () => {
    setRemovePopupShow(() => false);
  };
  const handleRemoveItem = (e) => {
    e.stopPropagation()
    handleOpenRemovePopup();
  };
  const checkPresentationValidLanguage = (item) =>{
    if(getLocaleKey(item.languageId ) !== localStorage.getItem('language')) {
      setWarningLanguagePopupShow(true)
      return false
    }
    return hrefToPresentationLocation(item)
  }

  const handleHrefToPresentationLocation = (item) =>{
    changeLanguage(getLocaleKey(item.languageId))
    setWarningLanguagePopupShow(false)
    return hrefToPresentationLocation(item)
  }
  function handleSetEditItem  (){
    editPresentationCardAPI(...arguments)
        .then(resp=>{
          handleGetData()
        })
  }
  function handleSetRemoveItem  (){
    removePresentationCardAPI(...arguments)
        .then(()=>{
          setTimeout(() => {
            handleGetData(true)
          },2000)
        })
  }

  useEffect(() => {
    setTimeout(() => {
      setState(!(item.id === props.getRemoveCardId));
    }, 400);
  }, [props.getRemoveCardId]);

  return (
    <>
      {state && (
        <>
          <CardBody
              hasIcon={false}
              classN={
              `presentation-card
               ${(item?.lessonId || item?.topicId) ? "hasLink" : ""}
               ${
                  item.id === props.getRemoveCardId
                      ? "screenshot_item_wrapper_remove_animation"
                      : ""
              }`
          }>
            <div style={{width: '100%'}}>
              <div onClick={()=>checkPresentationValidLanguage(item)}>
              <DynamicTitle
                style={{textTransform: 'none', fontSize: '14px'}}
                content={item?.title}
              >
              <div className={'card_action_section'}>
                  {
                      item.isEditorial &&
                      <div className={'is_owner_icon'}>
                          <IsEditoralIcon size={20} />
                      </div>
                  }
                  <DynamicActionBtn
                      handleEditItem={handleEditItem}
                      handleRemoveItem={handleRemoveItem}
                  />
                  </div>
              </DynamicTitle>
              </div>
              <Line />
            </div>
            <div className='presentation__container'>
                {/*<div className='presentation__img' style={{backgroundImage:`url(${PresentationIcon})`}}>*/}
                {/*  <div className="number__circle">8</div>*/}
                {/*</div>*/}
              {/*{checkIsEmptyValues(ARTICLE_READ_MORE_SECTIONS[2], item) &&*/}
              {/*  getInfoSection(ARTICLE_READ_MORE_SECTIONS[2], item).map(*/}
              {/*    (el) => <ScreenShotDynamicInfo key={el.id} content={el} />*/}
              {/*  )}*/}
              {checkIsEmptyValues(ARTICLE_READ_MORE_SECTIONS[2], item) &&
                  (<PresentationInfo content={item} >
                    <PresentationImageInfo slidesCount={item.slidesCount}  imageUrl={item?.imageUrl}/>
                  </PresentationInfo>)
              }
            </div>
            <div>
              {checkIsEmptyValues(ARTICLE_READ_MORE_SECTIONS[3], item) && (
                <DateSection
                  content={getInfoSection(ARTICLE_READ_MORE_SECTIONS[3], item)}
                  link={getInfoSection(ARTICLE_READ_MORE_SECTIONS[0], item)}
                />
              )}
            </div>
          </CardBody>
          <Popup
              isOpened={warningLanguagePopupShow}
              onClose={handleCloseWarningPopup}
              styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
          >
            <WarningLanguage
                onClose={handleCloseWarningPopup}
                item={item}
                onSubmit={handleHrefToPresentationLocation}
                />
          </Popup>
          <Popup
            isOpened={editPopupShow}
            onClose={handleCloseEditPopup}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
          >
            <DynamicScreenShotEdit
                onClose={handleCloseEditPopup}
                item={item}
                handleSetEditItem={handleSetEditItem}/>
          </Popup>
          <Popup
            isOpened={removePopupShow}
            onClose={handleCloseRemovePopup}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
          >
            <DynamicScreenShotRemove
              id={item?.id}
              userId={item?.userId}
              onClose={handleCloseRemovePopup}
              removeItemAPI={handleSetRemoveItem}
              getDataFromAPI={handleGetData}

            />
          </Popup>
        </>
      )}
    </>
  );
};

const mapStateTopProps = (state) => ({
  getRemoveCardId: getRemoveCardId(state),
});
const mapDispatchToProps = {
  editPresentationCardAPI,
  removePresentationCardAPI,
}

export default connect(mapStateTopProps, mapDispatchToProps)(PresentationCard);
