import React, {useCallback, useEffect, useState} from "react";
import './style.css'
import PresentationSlideBoxes from "../../PresentationSlideBoxes";
import {
    getIsSeeAllMode,
    getSlidesSelectedSlideData,
    getSubjectsSettingsData,
    getUserData
} from "../../../../store/selectors";
import {setSeeAllSlides} from "../../../../store/actions/slides";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {dispatchScreenShotLoader, getScreenShotSingleDataAPI} from "../../../../store/actions/mapStateAction";

const ShowSlidesMode = (props) => {
    const {t} = useTranslation()
    const {
        setSeeAllSlides,
        getSlidesSelectedSlideData,
        handleShowInfo,
        map,
        globalLoading,
        dispatchScreenShotLoader,
        getScreenShotSingleDataAPI,
        getSubjectsSettingsData,
        firstLoad,
        setFirstLoad,
        handleGetPresentation,
        hidd,
        handleCloseSlideInfoPopup,
        firstRunScreenShotStates
    } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [searchParams, setSearchParams] = useSearchParams();

    const [runScreenShotStates, setRunScreenShotStates] = useState(null);

    const handleSeeAll = () => {
        setSeeAllSlides(true)
    }
    const handleSelect = (id) =>{
    }
    const handleReplaceUrl = useCallback((selectedId) =>{
        // Use the browser's history API to update the URL when isPresentationMode changes
        const newUrl = new URLSearchParams(window.location.search);
        // const presentationParam = (!!selectedId && typeof selectedId === 'number')
        //                                     ? `&screenSlide=${selectedId}&userId=101&screenShot=true&loading=true`
        //                                     : '';
        const currentUser = JSON.parse(localStorage.getItem('userInfo'))?.data
        if((!!selectedId && typeof selectedId === 'number')){
            newUrl.set('screenSlide',selectedId)
            newUrl.set('screenShot','true')
            newUrl.set('userId',`${currentUser?.id}`)
            newUrl.set('loading','true')
        }else if(newUrl.has('screenSlide')){
            newUrl.delete('screenSlide')
            newUrl.delete('screenShot')
            newUrl.delete('userId')
        }
        navigate({
            pathname: window.location.pathname,
            search: newUrl.toString(),
        }, { replace: true })
    },[])
    const runSlide = async (id) =>{
        if(!id) return
        window.scrrenShot = true
        dispatchScreenShotLoader(true)
        const getApi =  await getScreenShotSingleDataAPI(id,'Slide')
        await getApi.call()
            .then((res) => getApi.serialized(res))
            .then((res) => getApi.setLanguage(res))
            .then((res) => getApi.setLocation(res))
            .then((res) => {
                getApi.setData(res)
                    .then((runScreenShotStates)=>{
                        setRunScreenShotStates(()=>runScreenShotStates)
                    })
            })
        // await getScreenShotSingleDataAPI(id,'Slide')
        //     .then((runScreenShotStates)=>{
        //         setRunScreenShotStates(()=>runScreenShotStates)
        //     })
    }

    useEffect(()=> {
        if(Object.keys(getSubjectsSettingsData).length > 0) {
            const statesST = ['checkSettingsSTF','loadBaseMap', 'mapSTF','toolBarSTF','leftBarSTF','timelineSTF','leftBarCatOpenSTF','compassSTF','loadDoneSTF',]
            if(firstLoad){
                firstRunScreenShotStates?.(statesST)
            }
            runScreenShotStates?.(statesST)
        }
    },[getSubjectsSettingsData,runScreenShotStates])

    useEffect(()=>{
        if(!getSlidesSelectedSlideData?.id) return
        if(!isNaN(+query.get("screenSlide")) && (+query.get("screenSlide") === getSlidesSelectedSlideData?.id)) return;
        handleReplaceUrl(getSlidesSelectedSlideData?.id)
    },[getSlidesSelectedSlideData])

    useEffect(()=>{
        if(query.get("loading") === 'true' || firstLoad) return
        runSlide(getSlidesSelectedSlideData?.id || +query.get("screenSlide"))
    },[searchParams])

    // useEffect( () => {
    //     if(!firstLoad) return
    //     setFirstLoad(false)
    //     if(+query.get("presentation")) handleGetPresentation(+query.get("presentation"))
    // })
    // if(hidd) return <div className={"slides__show__container-deactive"}></div>
    return(
        <div id={"slides__show__container"} className={hidd ? "slides__show__container-deactive" :'slides__show__container'}>
            <PresentationSlideBoxes
                hidd={hidd}
                selectedId={getSlidesSelectedSlideData?.id}
                showInfo={true}
                onSelect={handleSelect}
                handleShowInfo={handleShowInfo}
                map={map}
                globalLoading={globalLoading}
                handleCloseSlideInfoPopup={handleCloseSlideInfoPopup}/>
            {!hidd && <button style={{padding: '3px 0'}} onClick={handleSeeAll}>{t('seeAll')}</button>}
        </div>
    )
}

const mapStateTopProps = (state) => ({
    isSeeAllMode: getIsSeeAllMode(state),
    getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),
    user: getUserData(state),
    getSubjectsSettingsData:getSubjectsSettingsData(state)

});

const mapDispatchToProps = {
    setSeeAllSlides : setSeeAllSlides,
    dispatchScreenShotLoader:dispatchScreenShotLoader,
    getScreenShotSingleDataAPI,


};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(ShowSlidesMode);
