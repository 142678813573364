import React, { useEffect, memo} from 'react';

import {changeCursorIcon} from "../../../../shared/mockData";

import PolygonDraw from "../../../../assets/imgs/PaintBar/polygon-draw-tool-icon.svg";
import "./style.css";
import "../../style.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import toolboxStatesWithRedux from "../../mapper";


const PolygonDrawToolbox = (props) => {

    const {
        setPolygonDrawToolboxState,
        getPainterStartData,
        getPolygonDrawToolboxState,
        getLineDrawToolboxState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setStickyNotesClickedState,
        setRulerClickedState,
        getPolygonDrawToolboxCurrentFillColor,
        getPolygonDrawToolboxCurrentFillOpacity,
        getPolygonDrawToolboxCurrentStrokeColor,
        getPolygonDrawToolboxCurrentStrokeOpacity,
        setMapboxDrawToolboxGeoJSONState,
        setLineDrawToolboxState,
        setCommentToolboxState,
        selectedMapboxDrawToolFeature,
        setTextToolboxState,
        draw,
    } = props;

    const changeFeatureProps = (id) => {
        if (!draw) return;
        const thisFeature = draw.getSelected().features[0];
        if (thisFeature && thisFeature.properties) {
            draw.setFeatureProperty(id, 'portFillColor', getPolygonDrawToolboxCurrentFillColor);
            draw.setFeatureProperty(id, 'portFillOpacity', getPolygonDrawToolboxCurrentFillOpacity);
            draw.setFeatureProperty(id, 'portStrokeColor', getPolygonDrawToolboxCurrentStrokeColor);
            draw.setFeatureProperty(id, 'portStrokeOpacity', getPolygonDrawToolboxCurrentStrokeOpacity);
        }
        draw.add(draw.get(id));
        setMapboxDrawToolboxGeoJSONState(JSON.parse(JSON.stringify(draw.getAll())))
    }

    useEffect(() => {
        if(selectedMapboxDrawToolFeature) {
            changeFeatureProps(selectedMapboxDrawToolFeature.id);
        }
    }, [getPolygonDrawToolboxCurrentFillColor, getPolygonDrawToolboxCurrentFillOpacity, getPolygonDrawToolboxCurrentStrokeColor, getPolygonDrawToolboxCurrentStrokeOpacity]);


    const handleIconClick = () => {
        setCommentToolboxState(false);
        setDrawerToolboxClickedState(false);
        setStickyNotesClickedState(false);
        setTextToolboxState(false);
        setEraserClickedState(false);
        setRulerClickedState(false);
        setLineDrawToolboxState(false);
        setPolygonDrawToolboxState(!getPolygonDrawToolboxState);
    }

    useEffect(() => {
        if(!draw || getLineDrawToolboxState) {
            draw?.changeMode('simple_select');
            return
        }

        if (getPainterStartData && getPolygonDrawToolboxState) {
            setTimeout(() => {
                draw.changeMode('draw_polygon');
            }, 30)
        } else {
            if(!draw.getSelected().features.length) {
                draw.changeMode('simple_select');
                changeCursorIcon();
            }
        }
    }, [getPainterStartData, getPolygonDrawToolboxState, getLineDrawToolboxState]);

    return (
        <>
            <div
                id="lineDraw_icon"
                className={`pain_items ${getPolygonDrawToolboxState ? "button_active" : ""}`}
                onClick={handleIconClick}
            >
                <img src={PolygonDraw} alt="" className="icon_img"/>
            </div>
        </>
    );
};

export default toolboxStatesWithRedux(memo(PolygonDrawToolbox));
