import { useEffect, useRef } from 'react'
import { MAP_TOOLBOX_KEYS } from '../../mapToolbox'
import {
  DRAWER_TOOLBOX_LAYER,
  RULER_LAYER_AND_SOURCE_NAME,
} from '../DrawerToolbox/constants'
import {useDispatch} from "react-redux";
import {setPainterGeoJsonDataST, setRulerGeoJsonDataST} from "../../../store/actions/mapStateAction";

const useUndoRedo = (
  getSelectedToolUndoRedo,
  getUndoRedoData,
  setUndoRedoData,
  map,
) => {
  const dispatch = useDispatch();
  const currentUndoRedoSelectedComponent = useRef(null)

  useEffect(() => {
    if (getUndoRedoData && getSelectedToolUndoRedo) {
      currentUndoRedoSelectedComponent.current =
        getUndoRedoData[getSelectedToolUndoRedo]
    }
  }, [getSelectedToolUndoRedo, getUndoRedoData])

  const getDrawingSource = () => {
    return map.getSource(DRAWER_TOOLBOX_LAYER)
  }

  const getRulerSource = () => {
    return map.getSource(RULER_LAYER_AND_SOURCE_NAME)
  }

  const updateSourceData = (newValue) => {
    switch (getSelectedToolUndoRedo) {
      case MAP_TOOLBOX_KEYS.DRAWER:
        getDrawingSource().setData(newValue)
        break
      case MAP_TOOLBOX_KEYS.RULER:
        getRulerSource().setData(newValue)
        break
      default:
        break
    }
  }

  const setGeoJsonData = (newValue) => {
    switch (getSelectedToolUndoRedo) {
      case MAP_TOOLBOX_KEYS.DRAWER:
        dispatch(setPainterGeoJsonDataST(newValue))
        break
      case MAP_TOOLBOX_KEYS.RULER:
        dispatch(setRulerGeoJsonDataST(newValue))
        break
      default:
        break
    }
  }

  return {
    currentUndoRedoSelectedComponent,
    updateSourceData,
    setGeoJsonData,
    setUndoRedoData,
  }
}

export default useUndoRedo;
