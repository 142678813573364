import React from 'react';
import { SplideSlide } from '@splidejs/react-splide';

const TiliSlides = ({
                        galleryItems,
                        switchToSlide,
                        handleYoutubeImagePathGen,
                        playIcon
                    }) => {
    // Function to render an image slide
    const renderImageSlide = (slide, idx, parentIdx) => (
        <div
            key={slide.id}
            className="gallery-block"
            onClick={() => switchToSlide(idx + (parentIdx * 12))}
        >
            <img
                src={slide?.path}
                alt={slide.caption}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className={'gallery-title'}>
                <span dangerouslySetInnerHTML={{ __html: slide.caption }}></span>
                <div className={'overlay'} />
            </div>
        </div>
    );

    // Function to render a video slide
    const renderVideoSlide = (slide, idx, parentIdx) => (
        <div
            key={slide.id}
            className="gallery-block hiddConroll is-video"
            onClick={() => switchToSlide(idx + (parentIdx * 12))}
        >
            <video
                data-item={true}
                src={slide?.path}
                className="hiddConroll"
                controls="hidden"
                style={{ pointerEvents: "none" }}
            />
            <div className={"gallery-title"}>
                <span dangerouslySetInnerHTML={{ __html: slide.caption }}></span>
                <div className={'overlay'} />
            </div>
            <div className={"single-gallery-video-icon"}>
                {/* SVG content can be rendered here if provided */}
            </div>
        </div>
    );

    // Function to render a YouTube slide
    const renderYouTubeSlide = (slide, idx, parentIdx) => (
        <div
            key={slide.id}
            className="tili-iframe"
            onClick={() => switchToSlide(idx + (parentIdx * 12))}
        >
            <div className="tili-iframe-item">
                <img src={handleYoutubeImagePathGen(slide?.path)} alt={slide.caption} />
                <img src={playIcon} className={'videoPlayBtn'} />
            </div>
        </div>
    );

    // Chunk gallery items into groups of 12, with a special case for the last group
    const chunkedGalleryItems = galleryItems?.filter((x) => x.type !== 4).reduce((acum, _, idx, self) => {
        if (idx % 12 === 0) {
            // Get the next 12 items from the array
            const chunk = self.slice(idx, idx + 12);
            acum.push(chunk);
        }
        return acum;
    }, [])

    // Render each slide group as a SplideSlide
    return (
        <>
            {chunkedGalleryItems?.map((item, parentIdx) => (
                <SplideSlide className="gallery-item" key={parentIdx}>
                    <div className="tili-item">
                        {item.map((slide, idx) => {
                            switch (slide.type) {
                                case 0:
                                    return renderImageSlide(slide, idx, parentIdx);
                                case 1:
                                    return renderVideoSlide(slide, idx, parentIdx);
                                case 3:
                                    return renderYouTubeSlide(slide, idx, parentIdx);
                                default:
                                    return null;
                            }
                        })}
                    </div>
                </SplideSlide>
            ))}
        </>
    );
};

export default TiliSlides;
