import { connect } from "react-redux";
import "./style.css";
import React from "react";
import {useTranslation} from "react-i18next";

const ScreenShotDynamicInfo = (props) => {
  const { content = {} } = props;
  const {t} = useTranslation()
  return (
    <div className="card-body__dynamic-info dynamic-info">
      {content?.title && <h4>{t(content?.subject)} - &nbsp;</h4>}
      {content?.title && (
        <span
          style={{
            wordBreak: "break-all",
          }}
          className="screenshot_body_content"
        >
          {content?.title}
        </span>
      )}
    </div>
  );
};
export default connect(null, null)(ScreenShotDynamicInfo);
