import ArrowLeft from '../../../assets/imgs/icons/arrowLeft.svg'
import "./style.css"
import ConnectedSpriteImageComponent from "../ConnectedSpriteImageComponent";
const MapElementsPopup = (props) =>{
    const {child,selectELements,coordinates} = props
   return(
            <div className="elements-popup-box"
                 style={{
                     '--icon-arrow-left':`url("${ArrowLeft}")`
                 }}>
                <div className="elements-popup-content">
                    <ul className="elements-popup-ul">
                        {
                            child?.map(el=>(
                                <li className="elements-popup-li"
                                    key={`elemnt-popup-${el?.properties?.id}`}
                                    style={{
                                        '--color':`${el?.properties?.color}`
                                    }}
                                    onClick={()=>selectELements(el,coordinates,child.length)}>
                                    <div className="elements-popup-icon">
                                        <ConnectedSpriteImageComponent name={el?.properties?.spriteIcon}/>
                                    </div>
                                    {/*<i className="elements-popup-icon"*/}
                                    {/*   style={{*/}
                                    {/*       '--icon':`url("${el?.properties?.iconPath}")`*/}
                                    {/*   }}/>*/}
                                    <span className="elements-popup-title">{el?.properties?.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
   )
}
export default MapElementsPopup