import React, { Component } from "react";
import Account from "./../account";
import "./style.css";
import { getErrorMessage } from "../../helpers";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../components/UI/Loader";
import ErrorAlert from "../../components/errorAlert";
import axios from "axios";
import { TextInput } from "../../components/UI/TextInput";
import { Button } from "../../components/UI/Button";
import { checkValidationErrors } from "../forgot/form-validation";
import PhoneInput from "react-phone-input-2";
import { withTranslation } from 'react-i18next';

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fields: {
        type: 'Email',
        phoneCode: '374'
      },
      errors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      window.location.href = "/account-boards";
    }
  }

  contactSubmit(e) {
    this.setState({ loading: true });
    this.state.errors = {}
    this.state.apiErrorMsg = ''
    e.preventDefault();
    const errors = checkValidationErrors(this.state.fields, this.props.t);
    if (errors) {
      this.setState({ loading: false, errors });
    } else {
      this.forgotPass();
    }
  }

  handleChange(field, e, el) {
    let fields = this.state.fields;
    if(field === 'phoneCode') {
      fields["phoneCode"] = el.dialCode;
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  }

  forgotPass = () => {
    const props = this.state.fields['type'] === "Email" ? this.state.fields["email"] :
        `${this.state.fields["phoneCode"]}/${this.state.fields["phoneNumber"]}`
    axios
      .get(
        `${process.env.REACT_APP_IDENTITY_URL}/api/User/ResetPassword/${props}`
      )
      .then(async (res) => {
        const errorState = {
          loading: false,
          showError: true,
        };
        if (res.data.accepted) {
          if(this.state.fields['type'] === 'Email') {
          localStorage.setItem(
            "forgotPassEmail",
            JSON.stringify(this.state.fields["email"])
          ) } else {
            localStorage.setItem(
                "forgotPassPhoneNumber",
                JSON.stringify(this.state.fields["phoneNumber"])
            );
            localStorage.setItem(
                  "forgotPassPhoneNumberCode",
                JSON.stringify(this.state.fields["phoneCode"]))}
          this.props.navigate("/resetPass");
          return;
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
          this.setState(errorState);
        }
      });
  };

  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state;
    const { handleChange, contactSubmit, setState } = this;
    const {t} = this.props

    return (
      <Account>
        <div className="account-content">
          <h2 className="forgot-subtitle">{t('forgetPassword')}</h2>
          <p className="forgot-descr">
            {fields['type'] === 'Email' ?
                t('forgetPageText.forgetPassword') :
                t('forgetPageText.forgetPasswordSMS')}
          </p>
          <div
              className={'switchButtonContainer'}>
            <Button
                value="Email"
                action={handleChange.bind(this, "type")}
                className={fields["type"] === 'Email' ? 'switchButtonActive' : 'switchButton'}
                text={t('signInEmail')}
            />
            <Button
                value='Phone'
                action={handleChange.bind(this, "type")}
                className={fields["type"] === 'Phone' ? 'switchButtonActive' : 'switchButton'}
                text={t('signInPhoneNumber')}
            />
          </div>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            {/*<p className="forgot-email">{fields['type'] === 'Email' ?*/}
            {/*    'Նշե՛ք Ձեր Էլեկտրոնային Հասցեն' : 'Նշե՛ք Ձեր հեռախոսահամարը'}</p>*/}
            {fields['type'] === 'Email' ?
                <TextInput
                hasError={(errors["email"] || apiErrorMsg) && (errors['email'] || t('errors.userNotFound'))}
                placeholder={`${t("signInEmailPlaceholder")} *`}
                onChange={handleChange.bind (this, "email")}
                value={fields["email"] || ""}
            /> :
                <div
                    className={'phone-box'}>
                  <PhoneInput
                      inputProps={{
                        disabled: true,
                        style: { pointerEvents: 'none'}
                      }}
                      countryCodeEditable={false}
                      containerClass='phoneContainer'
                      inputClass={errors["phoneNumber"]  || showError ?
                          "phoneInputError" :
                          "phoneInput"}
                      buttonClass='flagButton'
                      country={'am'}
                      value=""
                      onChange={handleChange.bind(this, "phoneCode")}
                  />
                  {(errors["phoneNumber"] || apiErrorMsg) && <span className={'error-text'}>{errors["phoneNumber"] || t('errors.userNotFound')} *</span>}
                  <input
                      onInput={(e) => e.target.value =
                          fields.phoneCode == '374' ? e.target.value.slice(0, 8) :
                              e.target.value.slice(0, 15)}
                      ref={this.phoneNumberInputRef}
                      value={fields['phoneNumber']}
                      type="number"
                      placeholder={fields['phoneCode'] == '374' ?
                          `${t('signInPhoneNumberPlaceholder')} *` :
                          `${t('phoneNumber')} *`}
                      onChange={handleChange.bind(this, "phoneNumber")}
                      className={(errors["phoneNumber"] || apiErrorMsg) ? 'phoneNumberInputError': showError ? 'phoneNumberInput red' : 'phoneNumberInput'}/>
                </div>}
            <Button text={t('send')} action={contactSubmit.bind(this)} />
            <Link
                style={{marginTop: '20px'}}
                className="reg"
                to="/login">
              {t('logIn')}
            </Link>
          </form>
          {loading && <Loader />}
        </div>
      </Account>
    );
  }
}

export default withTranslation()(withNavigation(ForgotPassword));
