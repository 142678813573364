import {
    APPLE_SVG, ARROW_LINE,
    BANK_SVG,
    BOOK_SVG,
    BOOKMARK_SVG,
    BRUSH_SVG, BUS_SVG,
    CARROT_SVG,
    CAT_SVG, CHEF_SVG,
    CHEVRON_SVG,
    CUTLERY_SVG,
    DOLPHIN_SVG,
    ELLIPSE_SVG, FITNESS_SVG,
    FLASK_SVG,
    FLOWER_SVG, FOOTBALL_SVG,
    GLOBE_SVG,
    GREATER_THAN_SVG,
    HAPPY_SVG,
    HAT_SVG,
    HEART_SVG,
    HOSPITAL_SVG,
    ISLAND_SVG,
    LAMP_SVG, LAPTOP_SVG,
    LEMON_SVG,
    LOCATION_SVG,
    MAN_SVG,
    MARKET_SVG,
    MICROSCOPE_SVG,
    MOLECULE_SVG,
    MORTARBOARD_SVG,
    MUSIC_SVG,
    OLD_SVG,
    PALETTE_SVG,
    PAW_SVG,
    PENCIL_SVG,
    PILL_SVG, PLANE_SVG,
    PLUS_SVG, POLICE_SVG,
    QUESTION_SVG,
    RABBIT_SVG,
    RUN_SVG,
    SCHOOL_SVG,
    SHOE_SVG,
    SQUARE_SVG,
    STAR_SVG, STRETCHING_SVG, SUITCASE_SVG,
    SUN_SVG,
    T_SHIRT_SVG,
    TESTTUBE_SVG,
    THEATER_SVG, TRAIN_SVG,
    TREE_SVG,
    TRIANGLE_SVG, TRUCK_SVG, WEIGHTLIFTING_SVG
} from "./shape-svgs";
import LineArrow from "./advancedShapes/LineArrow";


const TEST_3_SVG_ELLIPSE = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="40" cy="40" rx="38" ry="28" stroke="purple" stroke-width="2" fill="yellow" />
</svg>`

const TEST_4_SVG_POLYGON = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <polygon points="40,2 2,78 78,78" stroke="orange" stroke-width="2" fill="pink" />
</svg>`

const TEST_5_SVG_ARROW = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <!-- Define the arrowhead marker -->
  <defs>
    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" fill="black" />
    </marker>
  </defs>

  <!-- Draw the line with the arrowhead marker -->
  <line x1="2" y1="40" x2="58" y2="40" stroke="black" stroke-width="2" marker-end="url(#arrowhead)" />
</svg>`

export const shapeCategories = [
   "basic",
   "objects",
   "animals",
   "nature",
    "food",
    "symbols",
    "education",
    "arts",
    "science",
    "people",
    "places",
    "activities",
    "transportation",
    "work",
];



export const allShapes =
    {
        "basic": [
            {
                "id": 1,
                "name": "star",
                "svg": STAR_SVG
            },
            {
                "id": 2,
                "name": "heart",
                "svg": HEART_SVG
            },
            {
                "id": 3,
                "name": "triangle",
                "svg": TRIANGLE_SVG
            },
            {
                "id": 4,
                "name": "bookmark",
                "svg": BOOKMARK_SVG
            },
            {
                "id": 5,
                "name": "ellipse",
                "svg": ELLIPSE_SVG
            },
            {
                "id": 6,
                "name": "square",
                "svg": SQUARE_SVG
            },
            {
                "id": 7,
                "name": "pill",
                "svg": PILL_SVG
            },
            {
                "id": 8,
                "name": "chevron",
                "svg": CHEVRON_SVG
            }
        ],
        "objects": [
            {
                "id": 1,
                "name": "t-shirt",
                "svg": T_SHIRT_SVG
            },
            {
                "id": 2,
                "name": "lamp",
                "svg": LAMP_SVG
            },
            {
                "id": 3,
                "name": "shoe",
                "svg": SHOE_SVG
            },
            {
                "id": 4,
                "name": "hat",
                "svg": HAT_SVG
            }
        ],
        "animals": [
            {
                "id": 1,
                "name": "paw",
                "svg": PAW_SVG
            },
            {
                "id": 2,
                "name": "cat",
                "svg": CAT_SVG
            },
            {
                "id": 3,
                "name": "rabbit",
                "svg": RABBIT_SVG
            },
            {
                "id": 4,
                "name": "dolphin",
                "svg": DOLPHIN_SVG
            }
        ],
        "nature": [
            {
                "id": 1,
                "name": "tree",
                "svg": TREE_SVG
            },
            {
                "id": 2,
                "name": "flower",
                "svg": FLOWER_SVG
            },
            {
                "id": 3,
                "name": "island",
                "svg": ISLAND_SVG
            },
            {
                "id": 4,
                "name": "sun",
                "svg": SUN_SVG
            }
        ],
        "food": [
            {
                "id": 1,
                "name": "apple",
                "svg": APPLE_SVG
            },
            {
                "id": 2,
                "name": "lemon",
                "svg": LEMON_SVG
            },
            {
                "id": 3,
                "name": "carrot",
                "svg": CARROT_SVG
            },
            {
                "id": 4,
                "name": "cutlery",
                "svg": CUTLERY_SVG
            }
        ],
        "symbols": [
            {
                "id": 1,
                "name": "plus",
                "svg": PLUS_SVG
            },
            {
                "id": 2,
                "name": "greater than",
                "svg": GREATER_THAN_SVG
            },
            {
                "id": 3,
                "name": "location",
                "svg": LOCATION_SVG
            },
            {
                "id": 4,
                "name": "question",
                "svg": QUESTION_SVG
            },
            {
                "id": 5,
                "name": "Line arrow",
                "type": "line_arrow",
                resizable: true,
                "svg": ARROW_LINE,
                component: LineArrow
            }
        ],
        "education": [
            {
                "id": 1,
                "name": "school",
                "svg": SCHOOL_SVG
            },
            {
                "id": 2,
                "name": "book",
                "svg": BOOK_SVG
            },
            {
                "id": 3,
                "name": "mortarboard",
                "svg": MORTARBOARD_SVG
            },
            {
                "id": 4,
                "name": "pencil",
                "svg": PENCIL_SVG
            }
        ],
        "arts": [
            {
                "id": 1,
                "name": "brush",
                "svg": BRUSH_SVG
            },
            {
                "id": 2,
                "name": "theater",
                "svg": THEATER_SVG
            },
            {
                "id": 3,
                "name": "music",
                "svg": MUSIC_SVG
            },
            {
                "id": 4,
                "name": "palette",
                "svg": PALETTE_SVG
            }
        ],
        "science": [
            {
                "id": 1,
                "name": "molecule",
                "svg": MOLECULE_SVG
            },
            {
                "id": 2,
                "name": "flask",
                "svg": FLASK_SVG
            },
            {
                "id": 3,
                "name": "microscope",
                "svg": MICROSCOPE_SVG
            },
            {
                "id": 4,
                "name": "test tube",
                "svg": TESTTUBE_SVG
            }
        ],
        "people": [
            {
                "id": 1,
                "name": "man",
                "svg": MAN_SVG
            },
            {
                "id": 2,
                "name": "old",
                "svg": OLD_SVG
            },
            {
                "id": 3,
                "name": "happy",
                "svg": HAPPY_SVG
            },
            {
                "id": 4,
                "name": "run",
                "svg": RUN_SVG
            }
        ],
        "places": [
            {
                "id": 1,
                "name": "bank",
                "svg": BANK_SVG
            },
            {
                "id": 2,
                "name": "hospital",
                "svg": HOSPITAL_SVG
            },
            {
                "id": 3,
                "name": "globe",
                "svg": GLOBE_SVG
            },
            {
                "id": 4,
                "name": "market",
                "svg": MARKET_SVG
            }
        ],
        "activities": [
            {
                "id": 1,
                "name": "weightlifting",
                "svg": WEIGHTLIFTING_SVG
            },
            {
                "id": 2,
                "name": "fitness",
                "svg": FITNESS_SVG
            },
            {
                "id": 3,
                "name": "football",
                "svg": FOOTBALL_SVG
            },
            {
                "id": 4,
                "name": "stretching",
                "svg": STRETCHING_SVG
            }
        ],
        "transportation": [
            {
                "id": 1,
                "name": "truck",
                "svg": TRUCK_SVG
            },
            {
                "id": 2,
                "name": "plane",
                "svg": PLANE_SVG
            },
            {
                "id": 3,
                "name": "bus",
                "svg": BUS_SVG
            },
            {
                "id": 4,
                "name": "train",
                "svg": TRAIN_SVG
            }
        ],
        "work": [
            {
                "id": 1,
                "name": "chef",
                "svg": CHEF_SVG
            },
            {
                "id": 2,
                "name": "police",
                "svg": POLICE_SVG
            },
            {
                "id": 3,
                "name": "suitcase",
                "svg": SUITCASE_SVG
            },
            {
                "id": 4,
                "name": "laptop",
                "svg": LAPTOP_SVG
            }
        ]
    }


    export const SHAPE_SIZES = {
        "2": {
            "width": 71.92390441894531,
            "height": 99.00004577636719,
            "aspectRatio": 0.726503749113565
        },
        "3": {
            "width": 95.7227554321289,
            "height": 97.78700256347656,
            "aspectRatio": 0.978890373186276
        },
        "star": {
            "width": 99.00000762939453,
            "height": 99.00000762939453,
            "aspectRatio": 1
        },
        "heart": {
            "width": 99.00230407714844,
            "height": 85.61199951171875,
            "aspectRatio": 1.1564068663481781
        },
        "triangle": {
            "width": 95.7227554321289,
            "height": 97.78700256347656,
            "aspectRatio": 0.978890373186276
        },
        "bookmark": {
            "width": 76.78599548339844,
            "height": 98.74099731445312,
            "aspectRatio": 0.7776505967309989
        },
        "ellipse": {
            "width": 99,
            "height": 98.91799926757812,
            "aspectRatio": 1.0008289768599146
        },
        "square": {
            "width": 100,
            "height": 100,
            "aspectRatio": 1
        },
        "pill": {
            "width": 66.02400207519531,
            "height": 100,
            "aspectRatio": 0.6602400207519531
        },
        "chevron": {
            "width": 84.92900085449219,
            "height": 99,
            "aspectRatio": 0.8578686954999211
        },
        "t-shirt": {
            "width": 98.21244812011719,
            "height": 99.0002670288086,
            "aspectRatio": 0.9920422547096549
        },
        "lamp": {
            "width": 71.92390441894531,
            "height": 99.00004577636719,
            "aspectRatio": 0.726503749113565
        },
        "shoe": {
            "width": 99,
            "height": 75.2782211303711,
            "aspectRatio": 1.3151214058119969
        },
        "hat": {
            "width": 99,
            "height": 55.073997497558594,
            "aspectRatio": 1.7975815175644119
        },
        "paw": {
            "width": 98.99200439453125,
            "height": 84.30600786209106,
            "aspectRatio": 1.174198694788914
        },
        "cat": {
            "width": 81.90242004394531,
            "height": 98.99028015136719,
            "aspectRatio": 0.8273784044121036
        },
        "rabbit": {
            "width": 99.00292205810547,
            "height": 84.813720703125,
            "aspectRatio": 1.1672984186679793
        },
        "dolphin": {
            "width": 98.99772644042969,
            "height": 84.88327026367188,
            "aspectRatio": 1.1662807775067365
        },
        "tree": {
            "width": 93.5146713256836,
            "height": 98.9979019165039,
            "aspectRatio": 0.94461265860518
        },
        "flower": {
            "width": 98.96138763427734,
            "height": 88.59527587890625,
            "aspectRatio": 1.1170052426896857
        },
        "island": {
            "width": 84.92876434326172,
            "height": 99.00031280517578,
            "aspectRatio": 0.8578635959503919
        },
        "sun": {
            "width": 96.21300506591797,
            "height": 97.96699845790863,
            "aspectRatio": 0.9820960790919376
        },
        "apple": {
            "width": 78.18499755859375,
            "height": 99.00110816955566,
            "aspectRatio": 0.789738610043527
        },
        "lemon": {
            "width": 98.46127319335938,
            "height": 99.02143859863281,
            "aspectRatio": 0.9943429886174046
        },
        "carrot": {
            "width": 98.9899673461914,
            "height": 98.99845123291016,
            "aspectRatio": 0.999914302833902
        },
        "cutlery": {
            "width": 74.24000549316406,
            "height": 99,
            "aspectRatio": 0.7498990453854956
        },
        "plus": {
            "width": 98.8600082397461,
            "height": 98.33200073242188,
            "aspectRatio": 1.0053696406397854
        },
        "greater than": {
            "width": 91.73119354248047,
            "height": 98.998779296875,
            "aspectRatio": 0.9265891377044086
        },
        "location": {
            "width": 61.851348876953125,
            "height": 98.98627471923828,
            "aspectRatio": 0.624847728156115
        },
        "question": {
            "width": 50.40699768066406,
            "height": 99,
            "aspectRatio": 0.5091615927339804
        },
        "school": {
            "width": 98.20004630088806,
            "height": 99,
            "aspectRatio": 0.9919196596049299
        },
        "book": {
            "width": 99.00000762939453,
            "height": 74.96804523468018,
            "aspectRatio": 1.3205627453601683
        },
        "mortarboard": {
            "width": 98.99384307861328,
            "height": 62.7780876159668,
            "aspectRatio": 1.5768852929096788
        },
        "pencil": {
            "width": 98.19338989257812,
            "height": 98.99910908937454,
            "aspectRatio": 0.9918613490140701
        },
        "brush": {
            "width": 98.6388931274414,
            "height": 97.83176517486572,
            "aspectRatio": 1.008250162420488
        },
        "theater": {
            "width": 98.99988162517548,
            "height": 83.02703094482422,
            "aspectRatio": 1.1923813304966433
        },
        "music": {
            "width": 99,
            "height": 98.12171173095703,
            "aspectRatio": 1.0089510084317646
        },
        "palette": {
            "width": 98.94231414794922,
            "height": 98.2599868774414,
            "aspectRatio": 1.0069441009732565
        },
        "molecule": {
            "width": 98.43778991699219,
            "height": 98.99864196777344,
            "aspectRatio": 0.9943347500568359
        },
        "flask": {
            "width": 79.56800842285156,
            "height": 99.00016784667969,
            "aspectRatio": 0.8037158941596698
        },
        "microscope": {
            "width": 73.44741821289062,
            "height": 98.99925994873047,
            "aspectRatio": 0.7418986591508605
        },
        "test tube": {
            "width": 43.446495056152344,
            "height": 99.00000095367432,
            "aspectRatio": 0.43885348118817225
        },
        "man": {
            "width": 39.73199462890625,
            "height": 98.99848198890686,
            "aspectRatio": 0.4013394329961379
        },
        "old": {
            "width": 94.45027923583984,
            "height": 99.00381469726562,
            "aspectRatio": 0.9540064645453349
        },
        "happy": {
            "width": 67.5698013305664,
            "height": 99.0031967163086,
            "aspectRatio": 0.6825012077558075
        },
        "run": {
            "width": 72.89411926269531,
            "height": 98.95646667480469,
            "aspectRatio": 0.7366281528850797
        },
        "bank": {
            "width": 95,
            "height": 94.35178589820862,
            "aspectRatio": 1.0068701837026244
        },
        "hospital": {
            "width": 99.00194549560547,
            "height": 94.47303771972656,
            "aspectRatio": 1.0479386276253213
        },
        "globe": {
            "width": 97.84988403320312,
            "height": 98.9966812133789,
            "aspectRatio": 0.9884158017610312
        },
        "market": {
            "width": 98.19713592529297,
            "height": 99,
            "aspectRatio": 0.9918902618716462
        },
        "weightlifting": {
            "width": 99.21278381347656,
            "height": 91.64548587799072,
            "aspectRatio": 1.0825714203268049
        },
        "fitness": {
            "width": 99.00222778320312,
            "height": 98.1184310913086,
            "aspectRatio": 1.0090074482649654
        },
        "football": {
            "width": 98.19999694824219,
            "height": 98.19876861572266,
            "aspectRatio": 1.0000125086346483
        },
        "stretching": {
            "width": 65.13325500488281,
            "height": 98.99663543701172,
            "aspectRatio": 0.6579340269228134
        },
        "truck": {
            "width": 99,
            "height": 71.3531494140625,
            "aspectRatio": 1.3874650357127583
        },
        "plane": {
            "width": 96.89346313476562,
            "height": 95.72015380859375,
            "aspectRatio": 1.0122577041457548
        },
        "bus": {
            "width": 99,
            "height": 54.67108154296875,
            "aspectRatio": 1.8108293672988878
        },
        "train": {
            "width": 99.04520416259766,
            "height": 72.92861938476562,
            "aspectRatio": 1.3581116027994853
        },
        "chef": {
            "width": 98.9658432006836,
            "height": 81.41163635253906,
            "aspectRatio": 1.215622822911077
        },
        "police": {
            "width": 98.84199523925781,
            "height": 80.57518005371094,
            "aspectRatio": 1.2267052356987638
        },
        "suitcase": {
            "width": 99,
            "height": 81.69423198699951,
            "aspectRatio": 1.2118358615055522
        },
        "laptop": {
            "width": 98.99900817871094,
            "height": 76.6500244140625,
            "aspectRatio": 1.291571776206091
        },
        "Line arrow": {
            "width": 98,
            "height": 40,
            "aspectRatio": 1.9999999999999998
        },
    };
