import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

const santizeSelectedResult = (items) =>
  items.map((value) => ({
    value: value.id,
    label: value.title,
  }));

const sortItemsByAlphabetical = (items) =>
  items.sort((a, b) =>
    a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : -1
  );

export const getSchoolsAPI = (url, body) => {
  return axios
    .post(url, body)
    .then((response) => extractItems(response))
    .then((items) => santizeSelectedResult(items))
    .then((items) => sortItemsByAlphabetical(items))
    .catch(console.log);
};
