import React, {useEffect, useState} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import './style.css';
import {connect} from "react-redux";
import DraggableSquare from "./SlideBoxes";
import {
    getPresentationModeData,
    getSlidesData,
    getSlidesFilteredLength,
    getSlidesLoading, getSlidesPage
} from "../../../store/selectors";
import {
    getMultipleSlidsByPresentationAPI,
    setSelectedSlide,
    setSlidesItemsByOrder, setSlidesPage,
    updateSlideOrderAPI
} from "../../../store/actions/slides";
import InfiniteHorizontalScroll from "../InfiniteHorizontalScroll";
import EmptySquares from "./EmptySquares";
import {useLocation, useNavigate} from "react-router-dom";
const PresentationSlideBoxes = (props) => {
    const [searchParamsInfo, setSearchParamsInfo] = useState({});
    const {selectedId,
        onSelect,
        handleEdit,
        handleShowInfo,
        handleDelete,
        handleSave,
        handleChooseForm,
        slides,
        showInfo=false,
        setSelectedSlide,
        setSlidesItemsByOrder,
        updateSlideOrderAPI,
        map,
        getSlidesByPresentationID,
        getNewData,
        setGetNewData,
        globalLoading,
        setSlidesPage,
        page,
        hidd,
        handleCloseSlideInfoPopup
    } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const handleSelect = (id, el) => {
        if(selectedId !== id) {
            onSelect(id)
            setSelectedSlide(el)
        }
        handleCloseSlideInfoPopup()

        // else {
        //     onSelect('')
        //     setSelectedSlide('')
        // }
    }
    const handleMoveSquare = (dragIndex, hoverIndex) => {
        const newSlides = [...slides];
        const draggedSlide = newSlides[dragIndex];

        newSlides.splice(dragIndex, 1);

        newSlides.splice(hoverIndex, 0, draggedSlide);

        updateSlideOrderAPI(draggedSlide?.id,hoverIndex + 1)
        setSlidesItemsByOrder(newSlides);
    };
    const handleChangePage = (e, value) => {
        const pageLoc = +value;
        if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
            setSlidesPage(pageLoc);
        }
    };
    const handleNextPage = () =>{
        const nextPage = page+1
        handleChangePage(null, nextPage)
    }
    const getSlidesData = () =>{
        let pageLoc = page;
        let perPage = 10
        const search = {
            page:pageLoc,
            perPage:perPage,
            ...searchParamsInfo
        }
        if (pageLoc > Math.ceil(props.getSlidesFilteredLength / 10))
            pageLoc = Math.ceil(props.getSlidesFilteredLength / 10);
        if (pageLoc < 1) pageLoc = 1;
        if(pageLoc  > 1 && props.getSlidesFilteredLength === slides.length) return console.log('end')
        if (!isNaN(pageLoc) && typeof pageLoc === "number") {
            if (pageLoc !== page) setSlidesPage(pageLoc);
            getSlidesByPresentationID([`${props.getPresentationModeData?.id || +query.get("presentation")}`], search,'add')
                .then(resp=>{
                    if(+query.get("screenSlide")){
                        const allData = [...slides,...resp.items]
                        const checkItem  = allData.findIndex(el=>el.id === +query.get("screenSlide"))
                        if((checkItem === -1 || checkItem === allData.length - 1) && resp.filteredTotal > allData.length) setSlidesPage( pageLoc + 1);
                    }
                })
        }
    }
    useEffect(()=> {
        if(Math.ceil(slides.length / 10) < page) {
            getSlidesData()
        }
    }, [page]);
    useEffect(() => {
        if(+query.get("screenSlide") && !!slides.length && !selectedId) {
            const findItem =  slides.find(el=>el.id === +query.get("screenSlide"))
            handleSelect(+query.get("screenSlide"),findItem)
        }
    }, [slides]);
    useEffect(()=> {
       if(getNewData) {
           setSlidesPage(1);
           getSlidesData()
           setGetNewData(false)
       }
    }, [getNewData]);
    if(hidd) return  <div className="scrollable__container-deactive"></div>
    return (
        <div className="scrollable__container" >
            <DndProvider backend={HTML5Backend}>
                    <div id={'draggable__squares'} className="draggable__squares">
                                <InfiniteHorizontalScroll
                                    fetchData={handleNextPage}
                                    fetching={props.slidesIsLoading}
                                    hasMore={props.getSlidesFilteredLength > slides?.length && !props.slidesIsLoading}
                                    offset={50}
                                >

                                { !!slides.length
                                    && slides?.map((el, index) => (
                                        <DraggableSquare
                                            showInfo={showInfo}
                                            item={el}
                                            key={el.id}
                                            id={el.id}
                                            index={index}
                                            title={el.title}
                                            imageUrl={el.imageUrl}
                                            moveSquare={handleMoveSquare}
                                            selectedId={selectedId}
                                            onSelect={(id) => handleSelect(id, el)}
                                            handleEdit={handleEdit}
                                            handleDelete={handleDelete}
                                            handleSave={handleSave}
                                            handleChooseForm={handleChooseForm}
                                            openInfoPopup={handleShowInfo}
                                            map={map}
                                            globalLoading={globalLoading}
                                        />
                                    ))
                                }
                                {slides?.length < 10 &&
                                    <>
                                        {Array.from({ length: 10 - slides?.length }).map((_, index) => (
                                            <EmptySquares key={index}  />
                                        ))}
                                    </>}
                            </InfiniteHorizontalScroll>
                    </div>
            </DndProvider>
        </div>
    );
};
const mapStateTopProps = (state) => ({
    slides: getSlidesData(state),
    page: getSlidesPage(state),
    squares:getPresentationModeData(state),
    slidesIsLoading:getSlidesLoading(state),
    getPresentationModeData: getPresentationModeData(state),
    getSlidesFilteredLength: getSlidesFilteredLength(state),
});

const mapDispatchToProps = {
    setSlidesItemsByOrder: setSlidesItemsByOrder,
    setSelectedSlide:setSelectedSlide,
    updateSlideOrderAPI:updateSlideOrderAPI,
    getSlidesByPresentationID:getMultipleSlidsByPresentationAPI,
    setSlidesPage:setSlidesPage,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(PresentationSlideBoxes);
