export const PlayBack = ({active}) => {
    const fill = active ? '#2C476C' : '#E8E8E8'
    return (
        <svg width="32" height="32" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_340_2413)">
                <rect x="12" y="8" width="64" height="64" rx="12" fill="white"/>
            </g>
            <path d="M44.9159 42.7949L54.9726 50.5538C55.2615 50.7917 55.615 50.942 55.9899 50.9863C56.3647 51.0306 56.7446 50.9669 57.083 50.8031C57.5648 50.5308 57.9602 50.1333 58.2254 49.6544C58.4906 49.1755 58.6152 48.6341 58.5855 48.0901V32.5737C58.6153 32.0297 58.4907 31.4882 58.2255 31.0093C57.9603 30.5305 57.5648 30.1329 57.083 29.8607C56.8165 29.7312 56.5234 29.6628 56.2259 29.6606C55.7675 29.6663 55.3246 29.8243 54.9698 30.1087L44.9159 37.8649C44.557 38.1701 44.2722 38.55 44.0824 38.9768C43.8927 39.4037 43.8027 39.8666 43.8192 40.3319C43.8031 40.7965 43.8932 41.2588 44.083 41.6849C44.2728 42.111 44.5573 42.4903 44.9159 42.7949Z" fill={fill}/>
            <path d="M31.5077 42.7958L41.5629 50.554C41.852 50.7918 42.2055 50.942 42.5803 50.9863C42.9551 51.0306 43.3349 50.967 43.6734 50.8034C44.156 50.5318 44.5523 50.1344 44.8181 49.6555C45.084 49.1765 45.209 48.6348 45.1793 48.0904V32.5739C45.2089 32.0298 45.0842 31.4882 44.8189 31.0093C44.5535 30.5303 44.158 30.1326 43.6761 29.8602C43.4093 29.7309 43.116 29.6625 42.8183 29.6602C42.3602 29.6663 41.9176 29.8242 41.5629 30.1083L31.5084 37.8692C31.1493 38.1735 30.8644 38.5526 30.6743 38.9788C30.4843 39.405 30.3941 39.8674 30.4102 40.3321C30.3941 40.7969 30.4843 41.2594 30.6742 41.6856C30.864 42.1119 31.1488 42.4912 31.5077 42.7958Z" fill={fill}/>
            <defs>
                <filter id="filter0_d_340_2413" x="0" y="0" width="88" height="88" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="6"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_340_2413"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_340_2413" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}
