import React, { Component } from "react";
import './style.css';

class Loader extends Component {
    render() {
        return (
            <div className="col-sm-6 text-center">
                <div className="loader3">
                    <span></span>
                    <span></span>
                </div>
            </div>
        );
    }
}

export default Loader;
