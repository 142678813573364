import ErrorMsg from "../errorMsg";
import React from "react";
import { months, years } from "../../shared/mockData";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "./CalendarIcon";
import DatePicker, { registerLocale } from "react-datepicker";
import hy from "date-fns/locale/hy";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
registerLocale("hy", hy);

export const MyDatePicker = ({
  contentClassName = "input-wrapper",
  className = "account-input",
  placeholder,
  onChange,
  value,
  hasError,
}) => {
    const {t,i18n} = useTranslation()
    const {language} = i18n
    return (
      <div className={`${contentClassName} ${hasError ? 'error' : ''}`}>
        <div className={className}>
          <DatePicker
            selected={value}
            locale={language}
            dateFormat="dd.MM.yyyy"
            renderCustomHeader={({ date, changeYear, changeMonth }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <select
                  value={months[new Date(date).getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }>
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {t('months.' + option)}
                    </option>
                  ))}
                </select>
                <select
                  value={new Date(date).getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}>
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
            minDate={new Date('01-01-1900')}
            maxDate={new Date()}
            placeholderText={placeholder}
            className={'date-picker'}
            onChange={onChange}
          />
        </div>
        {/*{hasError && (*/}
        {/*  <>*/}
        {/*    <button className="error-btn" type="button" />*/}
        {/*    <ErrorMsg text={hasError} />*/}
        {/*  </>*/}
        {/*)}*/}
        {hasError && <span className={'error-text'}>* {hasError}</span>}
        <div className={'MyDatePicker-icon'}>
          <CalendarIcon />
        </div>
      </div>
    )
};
