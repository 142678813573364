import ConnectedSpriteImageComponent from "../ConnectedSpriteImageComponent";
import "./style.css"

const MapElementsPopupRadius = (props) =>{
    const {child, selectELements,coordinates,activeStyle} = props
    return(
        <div className="elements-popup-radius-box">
            {
                child.map(el=>(
                    <div className="elements-popup-radius-content"
                         id={`element-${el.id || el?.properties?.id}`}
                         key={`element-${el.id || el?.properties?.id}`}
                         style={{
                             '--top':`${el.x}px`,
                             '--left': `${el.y}px`,
                             '--rotate': `rotate(${el.radians}rad)`,
                             '--radians': `rotate(${el.radians * -1}rad)`,
                         }}
                         onClick={()=>selectELements(el,coordinates,child?.length || 0)}
                    >
                        <div className="elements-popup-radius-content__line" ></div>
                        <div className="elements-popup-radius-content__info"
                             data-left={el.y>0}
                             data-title={el?.properties?.name}
                             style={{
                                 // '--icon':`url("${el?.properties?.iconPath}")`,
                                 '--color':`${el?.properties?.color}`,
                                 '--text-color':activeStyle.color,
                                 '--text-halo-color':activeStyle['halo-color']
                             }}
                        >
                            <ConnectedSpriteImageComponent name={el?.properties?.spriteIcon} centered={true}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export default MapElementsPopupRadius