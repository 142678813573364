import { GallerySection, List } from "./utils/utils";

export const ARTICLE_READ_MORE_GALLERY = [
  {
    path: "https://bg-frankfurt-output.s3.eu-central-1.amazonaws.com/e503e22ac26c9664c8977bd6e95a7879ff125241f436fe2194748faffaf94436/optimized.jpg",
    caption:
      '<p style="text-align: center;"><u> Խճանկար  «Աստված ուխտ է կապում Նոյի հետ»</u></p>',
    mediaId: "431d1792-7c2a-40e5-93c1-180e5c581707",
    order: 1,
    type: 0,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4094,
  },
  {
    path: "https://www.youtube.com/embed/lfnbe0_lg1U ",
    caption: '<p style="text-align: right;"><em><u>ascasc</u></em></p>',
    mediaId: "00000000-0000-0000-0000-000000000000",
    order: 2,
    type: 3,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4095,
  },
  {
    path: "https://bg-frankfurt-output.s3.eu-central-1.amazonaws.com/94d1c31412dc64ae23769127b1d899b9fc01a5de0e229a4efd6ca1a682db78fc/optimized.jpg",
    caption: "Պալատինյան կապելլան Պալերմո քաղաքում",
    mediaId: "f157b5ee-ca51-4e8f-beee-97093b6f70b9",
    order: 3,
    type: 0,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4096,
  },
  {
    path: "https://www.youtube.com/embed/LVxrB46PtH0 ",
    caption: null,
    mediaId: "00000000-0000-0000-0000-000000000000",
    order: 4,
    type: 3,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4097,
  },
  {
    path: "https://www.youtube.com/embed/C0tyVDo6CDk",
    caption: null,
    mediaId: "00000000-0000-0000-0000-000000000000",
    order: 5,
    type: 3,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4098,
  },
  {
    path: "https://bg-frankfurt-output.s3.eu-central-1.amazonaws.com/24cf0cefc94945e213cde7acde6797120427d272652135a2e4570f0f2e089644/optimized.jpg",
    caption: "Խճանկար  «Նոյը բաց է թողնում աղավնուն»",
    mediaId: "8f16307e-4553-4190-b64b-12733d84d490",
    order: 6,
    type: 0,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4099,
  },
  {
    path: "https://www.youtube.com/embed/4_SoaZVgmsY",
    caption: null,
    mediaId: "00000000-0000-0000-0000-000000000000",
    order: 7,
    type: 3,
    languageId: 1,
    createdAt: 1666609057,
    createdBy: 1,
    modifiedAt: null,
    modifiedBy: null,
    deletedAt: null,
    deletedBy: null,
    id: 4100,
  },
];
export const ARTICLE_GALLERY_FILTER_TYPES = [
  {
    id: 1,
    title: "all",
    type: [],
    checked: true,
  },
  {
    id: 2,
    title: "image",
    type: [0],
  },
  {
    id: 3,
    title: "video",
    type: [1, 3],
  },
  {
    id: 4,
    title: "360Files",
    type: [2],
  },
];
export const ARTICLE_READ_MORE_SECTIONS = [
  {
    id: 'm-1',
    title: "description",
    section: "description",
    content: (
      <p>
        Մեծ Հայք, հայտնի է նաև որպես Հայոց թագավորություն կամ Մեծ Հայքի
        թագավորություն ( .լատ ՝ Armenia Maior), հինաշխարհյան վաղ միջնադարյան
        շրջանի պետություն Հայկական լեռնաշխարհում, որը գոյություն է ունեցել
        մ.թ.ա. 331-ից մինչև մ.թ. 428 թվականը։ Թագավորությունը հիմնադրել է
        Հայաստանի սատրապ -Երվանդ Գ- ն, ով Ալեքսանդր Մակեդոնացու արևելյան
        արշավանքից հետո Մեծ Հայքը հռչակել է անկախ պետություն։ Երվանդունիներն
        իրենց աթոռանիստն են դարձնում Վանի թագավորության օրոք հիմնադրված
        Արգիշտիխինիլի, քաղաքը որը վերանվանվում է Արմավիր։ Երվանդունիների
        համահայկական թագավորությունն անկում է ապրում մ.թ.ա. 200 մոտ թվականին՝
        սելևկյան զորքերի կողմից Հայքի նվաճումից և Երվանդ Վերջինի գահազրկումից
        հետո։
      </p>
    ),
  },
  {
    id: 'm-2',
    title: "gallerySection",
    section: "gallery-section",
  },
  {
    id: 'm-3',
    title: "map",
    section: "map",
  },
  {
    id: 'm-4',
    title: "mainContent",
    section: "main-content",
    content:
      "Մեծ Հայք\n" +
      "Մագնեսիայի ճակատամարտում սելևկյան բանակի ջախջախումից հետո մ․թ․ա․ 189 թվականին Արտաշես Ա-ն հռչակվում է հայոց արքա՝ վերականգնելով անկախ պետականությունը։ Վերջինս միավորում է Մեծ Հայքի գրեթե բոլոր նահանգները (բացառությամբ Ծոփքի), ստեղծում կայուն և մարտունակ բանակ, իր հաջորդներին թողնում տնտեսապես զարգացած պետություն։\n" +
      "Մեծ Հայքի թագավորությունը սակայն իր հզորության գագաթնակետին է հասնում Տիգրան Բ Մեծի օրոք (մ․թ․ա․ 95-մ․թ․ա․ 55)․ Տիգրանյան նվաճումների արդյունքում ստեղծվում է Հայկական աշխարհակալությունը, որը ձգվում էր Կովկասյանլեռներից մինչև Եգիպտոս, Կասպից ծովից մինչև Արևելյան Միջերկրական։ \n" +
      "Տիգրանը պարթևներից խլում է «արքայից արքա» տիտղոսը՝ Մեծ Հայքը դարձնելով արևելքի հզորագույն քաղաքական ուժը։Սակայն հետագա տարիներին շարունակվող հռոմեա-պարթևական հակամարտությունն էական վնաս է հասցնում Հայկական տիրակալությունը, որն էլ անկում է ապրում մ․թ․ 1 թվականին՝ Տիգրան Դ-ի մահվանից հետո։ Արտաշեսյանների թագավորության անկումից հետո կես դարից ավելի Մեծ Հայքում հաջորդաբար իշխում են Հռոմի և Պարթևստանի դրածոները։ Արյունարբու պատերազմներից հետոշ՝ 66 թվականին, Ներոնը Հայքի արքա է ճանաչում Տրդատ Ա Արշակունուն՝ Վաղարշ Ա-ի եղբորը։ \n" +
      "1.\n" +
      "Արդյունքում՝ Մեծ Հայքում հաստատվում է Արշակունիների ժառանգական իշխանությունը։ \n" +
      "2.\n" +
      "Տրդատ Գ Մեծի կառավարման տարիներին՝ 301 թվականին, Մեծ Հայքն առաջինն   աշխարհում քրիստոնեությունն ընդունում է որպես պետական կրոն։\n" +
      "Հաջորդ հարյուրամյակում, այս անգամ Վռամշապուհ արքայի կառավարման տարիներին, տեղի է ունենում հերթական դարակազմիկ իրադարձությունը և 405 թվականին Մեսրոպ Մաշտոցն իրագործում է հայոց գրերի գյուտը։ Մինչ այդ սակայն՝ 387 թվականին, Մեծ Հայքը բաժանվել էր Հռոմի և Սասանյան տերության ազդեցության գոտիների, մինչդեռ Արշակունիները չէին դադարում իշխել միացյալ հայոց հողերի վրա։ Արշակ Գ-ի մահից հետո հռոմեացիները վերացնում են արևմտահայկական թագավորությունը՝ այն միացնելով Փոքր Հայքի նահանգներին, իսկ պարսկական հատվածում Արշակունիները շարունակում են իշխել մինչև 428 թվականը։\n",
  },
  {
    id: 'm-5',
    title: "notes",
    section: "notes",
  },
];
export const ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_GENERAL_INFORMATION = [
  {
    id: 1,
    title: "period",
    content: [],
  },
];
export const ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_CATEGORY = [
  {
    id: 1,
    title: "դեմք",
    iconPath:
      "https://bg-stage-frankfurt-output.s3.eu-central-1.amazonaws.com/91bdfc19392e40e226c62013a80220e89445af50eea7f4bf175bdd18307326a3/optimized.png",
    content: [
      {
        id: 1,
        title: "Կաթողիկոս",
        iconPath:
          "https://bg-stage-frankfurt-output.s3.eu-central-1.amazonaws.com/12aada63429627fa09f8e74d196a06990ffc4e7a7088298767201d5d5d05ae18/optimized.png",
      },
      {
        id: 2,
        title: "Գիտնական",
        iconPath:
          "https://bg-stage-frankfurt-output.s3.eu-central-1.amazonaws.com/58e711b1ab146c3863a54b68bfbf2cf34c1c48ee0c3b71fd0a83155b13502ad1/optimized.png",
      },
    ],
  },
];
export const ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_AFFILIATION = [
  {
    id: 1,
    title: "պատմական դարաշրջան",
    content: [],
  },
  {
    id: 2,
    title: "տարածաշրջան",
    content: [],
  },
  {
    id: 3,
    title: "Երկիր",
    content: [],
  },
  {
    id: 4,
    title: "Պատմական երկիր",
    content: [],
  },
];
export const ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_OTHER_CONNECTIONS = [
  {
    id: 1,
    title: "թեմա",
    content: [],
  },
  {
    id: 2,
    title: "դաս",
    content: [],
  },
  {
    id: 3,
    title: "Առնչվող Հոդվածներ",
    content: [],
  },
];

export const ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS = [
  {
    id: 'r-1',
    title: "generalInformation",
    section: "general-information",
    content: ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS_GENERAL_INFORMATION,
  },
  {
    id: 'r-2',
    title: "category",
    section: "category",
    content: [],
  },
  {
    id: 'r-3',
    title: "affiliation",
    section: "affiliation",
    content: [],
  },
  {
    id: 'r-4',
    title: "otherConnections",
    section: "other-connections",
    content: [],
  },
  {
    id: 'r-5',
    title: "tags",
    section: "tags",
    content: [],
  },
];
