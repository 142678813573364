export const generatePath = (bookmarkId) => {
    return `/map?preview=true&type=article&articleID=${bookmarkId}&bookmarkID=${bookmarkId}`
}
export const getCategorySection = (el,filteredCategories) =>{
    return {
            categoryTitle:filteredCategories[0]?.title,
            categoryIcon:filteredCategories[0]?.spriteIcon,
            categoryColor:filteredCategories[0]?.color,
        }
}
export const getInfoSection = (el,articleData) =>{
    let result = true
    if(el.section === 'new-tab') result =  generatePath(articleData?.id)
    if(el.section === 'dynamic-title') result = articleData?.content?.mainTitle
    if(el.section === 'dynamic-info') {
        result = {
            id: 1,
            imgPath: articleData?.content?.featuredImage,
            description: articleData?.content?.introText,
        }
    }
    if(el.section === 'content-info') {
        result = getCategorySection(el, articleData?.categoryInfo)

        result.id = articleData?.id
        result.articleId = articleData?.id
        result.like = true
    }
    return result

}
export const checkIsEmptyValues = (el) =>{
    let result = getInfoSection(el)
    if(Array.isArray(result)) {
        result = !!getInfoSection (el)?.length
    }else if(typeof result === 'string' || typeof result === 'object') {
        result = !!getInfoSection (el)
    }else if(typeof result === 'function'){
        result = !!result()?.content?.length
    }
    return result
}