const NoLanguage = ({size=64})=>(
    <svg  width={size} height={size} viewBox="0 0 64 64" fill="none" stroke="black" stroke-width="2">
        <rect x="16" y="8" width="32" height="48" rx="4" ry="4" fill="none" stroke="black"/>
        <line x1="22" y1="20" x2="42" y2="20" stroke="black" stroke-width="2"/>
        <line x1="22" y1="30" x2="42" y2="30" stroke="black" stroke-width="2"/>
        <line x1="22" y1="40" x2="42" y2="40" stroke="black" stroke-width="2"/>
        <circle cx="32" cy="32" r="20" stroke="red" stroke-width="4" fill="none"/>
        <line x1="18" y1="18" x2="46" y2="46" stroke="red" stroke-width="4"/>
    </svg>
)
export default NoLanguage

