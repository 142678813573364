import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "../../../components/UI/Button";
import { MyDatePicker } from "../../../components/UI/MyDatePicker";
import { SelectBox } from "../../../components/UI/SelectBox";
import { getErrorMessage } from "../../../helpers";
import {
    dropDownCustomAccountStyles,
    dropDownCustomAccountStylesForLittleScreens,
    dropDownCustomAccountStylesForRegions,
    updateUserInfoURI,
} from "../../../shared/mockData";
import Success from '../../../assets/imgs/success.svg'
import NotValid from '../../../assets/imgs/notValid.svg'
import Phone from '../../../assets/imgs/phone.svg'
import Email from '../../../assets/imgs/email.svg'
import Edit from '../../../assets/imgs/icons/edit.svg'
import { setRegions } from "../../../store/actions/regions";
import { setSchools } from "../../../store/actions/schools";
import { setStates } from "../../../store/actions/states";
import {
    getCountriesData,
    getGradesData,
    getRegionsData,
    getSchoolsData,
    getStatesData,
    getSubjectsData,
    getUserData,
} from "../../../store/selectors";
import { checkAdditionalValidationErrors } from "../form-validation";
import { checkAccountValidationErrors } from "../form-validation";
import {PhoneEmailContainer} from "./PhoneEmailContainer";
import {
    constructSelectedItemApiParams,
    extractItems,
    getUserUpdateApiParams,
    santizeSelectedResult,
    sortItemsByAlphabetical,
    updateUserAccount,
} from "../utils";
import PhoneInput from "react-phone-input-2";
import {TextInput} from "../../../components/UI/TextInput";
import {ResendCode} from "../../../components/resendCode";
import {getUserFromAPI} from "../../../store/actions/user";
import { isMobile } from "react-device-detect";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import ArmFlag from "../../../assets/imgs/icons/arm-flag.svg";
import UkFlag from "../../../assets/imgs/icons/uk-flag.svg";
import {getLocaleKey, getTranslation} from "../../../utils/helpers";

class RightSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            change: false,
            isPopupOpen: false,
            isChangePopupOpen: false,
            loading: false,
            fields: {...props.user,code:[]},
            errors: {},
            apiErrorMsg: "",
            showError: false,
            confirmError:'',
            regLng:localStorage.getItem('regLanguage'),
            uploadedFile: {
                id: null,
                url: null
            },
            windowWidth: window.innerWidth,
            genderOptions: [
                {
                    value: "Female",
                    label: this.props.t('female')
                },
                {
                    value: "Male",
                    label: this.props.t('male')
                },
            ]
        };
        this.handleChangePopupOpen = this.handleChangePopupOpen.bind(this);
    }
    confirm = (type) => {
        this.state.fields.type = type
        const sendUrl = type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendEmailConfirmationCode` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendPhoneNumberConfirmationCode`
        axios.post(
            sendUrl
        )
            .then((resp) => {
                if(resp.data.accepted) {
                    this.handlePopupOpen()
                }
            })
    }

    cancelEditing = () => {
        this.handleSelectOptions('')
        this.props.action()
    }

    handlePopupOpen = () => {
        this.setState({ isPopupOpen: true });
    };
    handleChangePopupOpen = (type) => {
        this.state.fields.type = type
        this.setState({ isChangePopupOpen: true });
    };

    handlePopupClose = () => {
        this.setState({isChangePopupOpen: false})
        this.setState({ isPopupOpen: false });
        this.state.errors = []
        this.state.confirmeError = ''
        this.state.apiErrorMsg = ''
    };

    componentDidMount() {
        if (this.props.uploadFile.length) {
            this.setState({ uploadedFile: this.props.uploadFile });
        }
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uploadFile !== [] && prevProps.uploadFile !== this.props.uploadFile) {
            this.setState({ uploadedFile: this.props.uploadFile });
        }
        this.checkLanguageChange();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    checkLanguageChange() {
        if (this.props.i18n.language !== this.language) {
            this.setState((prevState) => {
                const updatedGenderOptions = [
                    {
                        value: "Female",
                        label: this.props.t('female'),
                    },
                    {
                        value: "Male",
                        label: this.props.t('male'),
                    },
                ];
                const selectedGender = updatedGenderOptions.find(el => el.value === this.state.fields.gender?.value);
                return {
                    genderOptions: updatedGenderOptions,
                    fields: {
                        ...prevState.fields,
                        gender: selectedGender ? selectedGender : prevState.fields.gender
                    }
                };
            });
            this.language = this.props.i18n.language;
        }
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth
        });
    }
    handleSelectOptions = (type) => {
        const { url, body } = constructSelectedItemApiParams(
            type,
            this.state.fields,
            this.state.regLng
        );
        const handleItemsLength = (items) => {
            if (type === "state" && items.length === 1) {
                let fields = this.state.fields;
                fields["state"] = {
                    value: items[0].value,
                    label: items[0].label,
                };
                this.setState({ fields });
                this.handleSelectOptions("school");
            }
            return items;
        };
        let resObj = {};
        axios
            .post(url, body)
            .then((response) => extractItems(response))
            .then((items) => santizeSelectedResult(items))
            .then((items) => handleItemsLength(items))
            .then((items) => sortItemsByAlphabetical(items))
            .then((items) => {
                if (type === "region") {
                    this.props.setRegions(items);
                } else if (type === "state") {
                    this.props.setStates(items);
                    if (items.length === 1) {
                        this.handleSelectOptions("school");
                    }
                } else if (type === "school") {
                    this.props.setSchools(items);
                }
                setTimeout(() => {
                    if (type === "region") {
                        resObj.region = "";
                        resObj.state = "";
                        resObj.school = "";
                    } else if (type === "state") {
                        if (items.length === 1) {
                            resObj.state = items[0];
                            resObj.school = "";
                        } else {
                            resObj.state = "";
                            resObj.school = "";
                        }
                    } else if (type === "school") {
                        resObj.school = "";
                    }
                    resObj = { ...this.state.fields, ...resObj };
                    this.setState({ fields: { ...resObj } });
                }, 200);
                // this.setState ({
                //   [`${type}s`]: items,
                // })
            });
    };

    resendCode = () => {
        const sendUrl = this.state.fields.type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendEmailConfirmationCode` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendPhoneNumberConfirmationCode`
        axios.put(
            sendUrl
        ).then((response) => {
            return response
        })
    }
    resendChangeCode = () => {
        const {fields} = this.state
        const sendUrl = fields.type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangeEmail/${fields.changeEmail}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangePhoneNumber/${fields.changePhoneCode || "374"}/${fields.changePhoneNumber}`
        axios.put(
            sendUrl
        ).then((response) => {
            return response
        })
    }

    handleChange(field, e, el) {
        let fields = this.state.fields
        if(field === "email") e.target.value = e.target.value.trim()
        if(field === 'phoneCode' || field === 'changePhoneCode') {
            fields[field] = el.dialCode;
        } else {
            fields[field] = e.target.value
        }
        this.setState({ fields })
    }


    additionalSubmit = async (e) => {
        this.setState({ loading: true });
        e.preventDefault();
        const errors = checkAccountValidationErrors(this.state.fields, this.props.t);
        if (errors) {
            this.setState({ loading: false, errors });
        } else {
            await this.updateAccount();
            await this.props.getUserFromAPI();
        }
    };

    confirmCode() {
        const {fields} = this.state
        this.setState({confirmeError: ''})
        const url = fields.type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmEmailByCode/${fields.code}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmPhoneNumberByCode/${fields.code}`
        if (fields.code.length) {
            axios.post (url)
                .then ((res) => {
                    if (res.data.accepted) {
                        this.setState({confirmeError:''})
                        this.handlePopupClose()
                    }
                    else{
                        this.setState({confirmeError: this.props.t('errors.enterValidConfirmCode')})
                    }
                })
                .catch((error)=>{
                    this.setState({confirmeError:error})
                })
        }else{
            this.setState({confirmeError: this.props.t('errors.requiredField')})
        }
    }
    confirmChangeCode() {
        const {fields} = this.state
        this.setState({confirmeError:''})
        const url = fields.type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmEmailChange/${fields.changeEmail}/${fields.code}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmPhoneNumberChange/${fields.changePhoneCode || '374'}/${fields.changePhoneNumber}/${fields.code}`
        if (fields.code.length) {
            axios.post (url)
                .then ((res) => {
                    if (res.data.accepted) {
                        this.setState({change: false})
                        if(fields.type === 'Phone') {
                            fields.phone = fields.changePhoneNumber;
                            fields.phoneCode = fields.changePhoneCode
                        } else {
                            fields.email = fields.changeEmail
                        }
                        this.setState({confirmeError:''})
                        this.handlePopupClose()
                    }
                    else{
                        this.setState({confirmeError: this.props.t('errors.enterValidConfirmCode')})
                    }
                })
                .catch((error)=>{
                    this.setState({confirmeError:error})
                })
        }else{
            this.setState({confirmeError: this.props.t('errors.requiredField')})
        }
    }

        changeEmailPhone () {
        const {fields} = this.state
        const sendUrl = fields.type === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangeEmail/${fields.changeEmail}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangePhoneNumber/${fields.changePhoneCode || '374'}/${fields.changePhoneNumber}`
        const errorState = {
            loading: false,
            showError: true,
        }
        const errors = checkAdditionalValidationErrors(this.state.fields, this.props.t);
        if (errors) {
            this.setState({ loading: false, errors });
        } else {
            axios.put (sendUrl)
                .then ((res) => {
                    if (res.data.accepted) {
                        this.setState ({change: true})
                        this.handlePopupClose ();
                        this.handlePopupOpen ()
                    } else {
                        errorState.apiErrorMsg =
                            fields.type === "Email" ?
                                "Էլ. հասցեն արդեն գրանցված է" :
                                "Հեռախոսահամարն արդեն գրանցված է"
                        this.setState (errorState)
                    }
                })
        }
}
    updateAccount = () => {
        const { fields, uploadedFile } = this.state;
        const body = getUserUpdateApiParams(fields, this.props.user, uploadedFile);
        const errorState = {
            loading: false,
            showError: false,
            uploadedFile: {
                id: null,
                url: null,
            },
        };
        updateUserAccount(updateUserInfoURI, body)
            .then((res) => {
                if (res.data.accepted === false) {
                    return Promise.reject(res.data.errorMessages);
                }
                return res.data;
            })
            .then(this.getData)
            .catch((error) => {
                errorState.showError = true;
                errorState.apiErrorMsg = getErrorMessage(error);
                this.setState(errorState);
            });
        this.props.action()
    };

    handleformatDate = (inputDate) => {
        const parsedDate = new Date(inputDate);

        const day = parsedDate.getDate();
        const month = parsedDate.getMonth() + 1;
        const year = parsedDate.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : `${day}`;
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;

        const formattedDateString = `${formattedMonth}.${formattedDay}.${year}`;
        return formattedDateString;
    }


    render() {
        const { fields, errors, apiErrorMsg, isPopupOpen, isChangePopupOpen, change, confirmeError,windowWidth, genderOptions } = this.state;
        const { additionalSubmit, handleSelectOptions, handleChange, confirm, resendCode, resendChangeCode,
            cancelEditing, handlePopupClose, confirmChangeCode, confirmCode, changeEmailPhone,
            handleChangePopupOpen } = this;
        const { user, grades, countries, states, subjects, regions, schools, action, edit, t } = this.props;
        // Old logic for filtering grades without duplicates
        // const uniqueGrades = grades.filter((grade, index, arr) => {
        //     return arr.findIndex((g) => g.label === grade.label) === index;
        // });
        if(windowWidth < 1025){
            if (edit) {
                return (
                    <>
                        <div className="user-right-header mobile edit">
                            <p className="user-additional">{t('profilePage')}</p>
                        </div>
                        <div className="user-additional-content mobile">
                            <PhoneEmailContainer
                                fields={fields}
                                handleChangePopupOpen={handleChangePopupOpen}
                                confirm={confirm}
                                registrationMethod={user.registeredMethod}
                            />
                            {!fields.isEmailVerified &&
                                <div
                                    onClick={() => confirm('Email')}
                                    className={'notVerified'}>
                                    {t('confirm')}
                                </div>}
                            <form>
                                <div className="user-detail-mobile">
                                    <span className="user-detail-mobile-title">{t('language')}</span>
                                    <div className="user-input border-none">
                                        <p>{+this.state.regLng === 1 ? <img src={ArmFlag}/> : <img src={UkFlag}/>}</p>
                                    </div>
                                </div>
                                <div className="user-detail-mobile underline-none">
                                    <span className="user-detail-mobile-title">{t('role')}</span>
                                    <div className="user-input border-none">
                                        <p>{t(`userType.${fields['speciality'].text.toLowerCase()}`)}</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        gap: '10px'
                                    }}>
                                    <div
                                        className={'user-detail-mobile underline-none'}>
                                        <span className="user-detail-input-mobile-title">{t('firstName')}</span>
                                        <TextInput
                                            hasError={errors['firstName']}
                                            contentClassName={'input'}
                                            onChange={handleChange.bind(this, 'firstName')}
                                            className={errors['firstName'] ? 'big-input name red-border' : 'big-input name'}
                                            value={fields.firstName}
                                        />
                                    </div>
                                    <div
                                        className={'user-detail-mobile underline-none'}>
                                        <span className="user-detail-input-mobile-title">{t('lastName')}</span>
                                        <TextInput
                                            hasError={errors['lastName']}
                                            contentClassName={'input'}
                                            onChange={handleChange.bind(this, 'lastName')}
                                            className={errors['lastName'] ? 'big-input name red-border' : 'big-input name'}
                                            value={fields.lastName}
                                        />
                                    </div>
                                </div>
                                <div className="user-detail-mobile underline-none">
                                    <span className={'user-detail-input-mobile-title'}>{t('gender')}</span>
                                    <SelectBox
                                        hasError={errors["gender"]}
                                        placeholder={`${t('gender')} *`}
                                        contentClassName={`user-detail-input-change`}
                                        dropDownStyles={dropDownCustomAccountStyles}
                                        className={`user-input select`}
                                        options={genderOptions}
                                        onChange={(e) => {
                                            fields["gender"] = e;
                                            this.setState({fields: fields});
                                        }}
                                        value={fields["gender"] || ""}
                                    />
                                </div>
                                <label className="user-detail-mobile underline-none">
                                    <span className={'user-detail-input-mobile-title'}>{t('dateOfBirth')}</span>
                                    <MyDatePicker
                                        contentClassName={`user-input user-detail-input-change`}
                                        className={""}
                                        hasError={errors["birthDate"]}
                                        placeholder={`${t('dateOfBirth')} *`}
                                        onChange={(e) => {
                                            fields["birthDate"] = e;
                                            this.setState({fields});
                                        }}
                                        value={new Date(fields["birthDate"]) || null}
                                    />
                                </label>
                                <div className="user-detail-mobile underline-none">
                                    <span className={'user-detail-input-mobile-title'}>{t('country')}</span>
                                    <SelectBox
                                        hasError={errors["country"]}
                                        placeholder={`${t('country')} *`}
                                        contentClassName={`user-detail-input-change`}
                                        dropDownStyles={dropDownCustomAccountStyles}
                                        className={`user-input select`}
                                        options={countries}
                                        onChange={(e) => {
                                            fields["country"] = e;
                                            this.setState({fields: fields});
                                            handleSelectOptions("region");
                                        }}
                                        value={fields["country"] || ""}
                                    />
                                </div>
                                <div className="user-detail-mobile underline-none">
                                    <span className={'user-detail-input-mobile-title'}>{t('region')}</span>
                                    <SelectBox
                                        hasError={errors["region"]}
                                        placeholder={`${t('region')} *`}
                                        contentClassName={`user-detail-input-change`}
                                        dropDownStyles={dropDownCustomAccountStyles}
                                        className={`user-input select`}
                                        options={regions}
                                        onChange={(e) => {
                                            fields["region"] = e;
                                            this.setState({fields: fields});
                                            handleSelectOptions("state");
                                        }}
                                        value={fields["region"] || ""}
                                    />
                                </div>
                                {states && states.length > 1 ? (
                                    <div className="user-detail-mobile underline-none">
                                        <span className={'user-detail-input-mobile-title'}>{t('commune')}</span>
                                        <SelectBox
                                            hasError={errors["state"]}
                                            placeholder={`${t('commune')} *`}
                                            contentClassName={`user-detail-input-change`}
                                            dropDownStyles={dropDownCustomAccountStyles}
                                            className={`user-input select`}
                                            options={fields["region"] ? states : []}
                                            onChange={(e) => {
                                                fields["state"] = e;
                                                this.setState({fields: fields});
                                                handleSelectOptions("school");
                                            }}
                                            value={fields["state"] || ""}
                                        />
                                    </div>
                                ) : null}

                                {user.speciality.text !== 'Other' &&
                                    (fields['country']?.value === 11 && schools && schools.length ?
                                        <div className="user-detail-mobile underline-none">
                                            <span className={'user-detail-input-mobile-title'}>{t('school')}</span>
                                            <SelectBox
                                                hasError={errors["school"]}
                                                placeholder={`${t('school')} *`}
                                                contentClassName={`user-detail-input-change`}
                                                dropDownStyles={dropDownCustomAccountStyles}
                                                className={`user-input select`}
                                                options={fields["state"] ? schools : []}
                                                onChange={(e) => {
                                                    fields["school"] = e;
                                                    this.setState({fields: fields});
                                                }}
                                                value={fields["school"] || ""}
                                            />
                                        </div>
                                        : <div id={'school-other'} className="user-detail school-other">
                                            <span className={'user-detail-input-mobile-title'}>{t('school')}</span>
                                            <TextInput
                                                contentClassName={`user-detail-input-change`}
                                                className={`big-input name`}
                                                hasError={errors["school"]}
                                                placeholder={`${t('school')} *`}
                                                onChange={handleChange.bind(this, "school")}
                                                value={fields["school"]?.label || fields["school"] || ""}
                                            />
                                        </div>)
                                }
                                {user.speciality.text === "Student" &&
                                    fields['country']?.value === 11 && (
                                        <div className="user-detail-mobile underline-none">
                                            <span className={'user-detail-input-mobile-title'}>{t('class')}</span>
                                            <SelectBox
                                                hasError={errors["grade"]}
                                                placeholder={`${t('class')} *`}
                                                contentClassName={`user-detail-input-change`}
                                                dropDownStyles={dropDownCustomAccountStylesForLittleScreens}
                                                className={`user-input select`}
                                                options={grades}
                                                onChange={(e) => {
                                                    fields["grade"] = e;
                                                    this.setState({fields: fields});
                                                }}
                                                value={fields["grade"] || ""}
                                            />
                                        </div>
                                    )}
                                {user.speciality.text === "Teacher" &&
                                    fields['country']?.value === 11 && (
                                        <div className="user-detail-mobile underline-none">
                                            <span className={'user-detail-input-mobile-title'}>{t('subject')}</span>
                                            <SelectBox
                                                hasError={errors["subject"]}
                                                placeholder={`${t('subject')} *`}
                                                contentClassName={`user-detail-input-change`}
                                                dropDownStyles={dropDownCustomAccountStyles}
                                                className={`user-input select`}
                                                options={subjects}
                                                isMulti
                                                onChange={(e) => {
                                                    fields["subject"] = e;
                                                    this.setState({fields: fields});
                                                }}
                                                value={fields["subject"] || ""}
                                            />
                                        </div>
                                    )}
                                {user.speciality.text === "Other" && (
                                    <div className="user-detail-mobile underline-none">
                                        <span className={'user-detail-input-mobile-title'}>{t('activity')}</span>
                                        <div
                                            style={{width: '65%', alignItems: 'flex-end'}}>
                                            <TextInput
                                                hasError={errors['occupation']}
                                                contentClassName={'input'}
                                                onChange={handleChange.bind(this, 'occupation')}
                                                className={errors['occupation'] ? 'big-input name red-border' : 'big-input name'}
                                                value={fields.occupation}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="user-form-btns">
                                    <Button
                                        className="user-form-btn save"
                                        action={additionalSubmit.bind(this)}
                                        text={t('save')}
                                    />
                                    <Button
                                        className="user-form-btn link"
                                        action={action}
                                        text={t('cancel')}
                                    />
                                </div>
                            </form>
                            {isPopupOpen && (
                                <div className="popup-overlay">
                                    <div className="popup-content">
                                        <p
                                            className={'popup-header'}
                                        >{t('enterVerificationCode')}</p>
                                        <div
                                            style={{width: '80%'}}>
                                            <TextInput
                                                hasError={confirmeError}
                                                onChange={handleChange.bind(this, 'code')}
                                                className='account-input black-input'
                                                placeholder={`${t('verificationCode')} *`}
                                            />
                                        </div>
                                        <div
                                            className='popup-button-container'>
                                            <Button
                                                className="user-form-btn save"
                                                action={change ? confirmChangeCode.bind(this) : confirmCode.bind(this)}
                                                text={t('save')}
                                            />
                                            <Button
                                                className="user-form-btn link"
                                                action={handlePopupClose}
                                                text={t('cancel')}
                                            />
                                        </div>
                                        <ResendCode
                                            action={change ? resendChangeCode : resendCode}
                                            black={true}/>
                                    </div>
                                </div>
                            )}
                            {isChangePopupOpen && (
                                <div className="popup-overlay">
                                    <div className="popup-content">
                                        <p
                                            className={'popup-header'}
                                        >{fields.type === 'Email' ? t('enterNewEmail') : t('enterNewPhoneNumber')}</p>
                                        <div
                                            style={{width: '80%'}}>
                                            {fields.type === 'Email' ?
                                                <TextInput
                                                    hasError={(apiErrorMsg === 'Էլ. հասցեն արդեն գրանցված է' &&
                                                        t('errors.usedEmail')) || errors['email']}
                                                    onChange={handleChange.bind (this, 'changeEmail')}
                                                    className='account-input black-input'
                                                    placeholder={`${t('signInEmailPlaceholder')} *`}
                                                /> :
                                                <div
                                                    style={{
                                                        alignItems: 'flex-end',
                                                        display: 'flex',
                                                        marginTop: '-10px',
                                                        flexDirection: 'column'}}>
                                                    <PhoneInput
                                                        inputProps={{
                                                            disabled: true,
                                                            style: { pointerEvents: 'none'}
                                                        }}
                                                        countryCodeEditable={false}
                                                        containerClass='phoneContainer'
                                                        inputClass={(errors["phoneNumber"] ||
                                                            apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է') ?
                                                            "phoneInputError" :
                                                            "phone-edit"}
                                                        buttonClass='flagButton'
                                                        country={'am'}
                                                        value=""
                                                        onChange={handleChange.bind(this, "changePhoneCode")}
                                                    />
                                                    {(apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ?
                                                        <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                                                        errors["phoneNumber"]) ?
                                                        <span className={'error-text'}>{errors["phoneNumber"]} *</span> : null}
                                                    <input
                                                        onInput={(e) => e.target.value =
                                                            fields.changePhoneCode === undefined ||
                                                            fields.changePhoneCode == '374' ? e.target.value.slice(0, 8) :
                                                                e.target.value.slice(0, 15)}
                                                        ref={this.phoneNumberInputRef}
                                                        value={fields['phoneNumber']}
                                                        type="number"
                                                        placeholder={fields['phoneCode'] == '374' ?
                                                            `${t('signInPhoneNumberPlaceholder')} *` :
                                                            `${t('phoneNumber')} *`}
                                                        onChange={handleChange.bind(this, "changePhoneNumber")}
                                                        className={errors["phoneNumber"] || apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ? 'phoneNumberInputError black':'phoneNumberInput black'}/>
                                                </div>}
                                        </div>
                                        <div
                                            className='popup-button-container'>
                                            <Button
                                                className="user-form-btn save"
                                                action={changeEmailPhone.bind(this)}
                                                text={t('save')}
                                            />
                                            <Button
                                                className="user-form-btn link"
                                                action={handlePopupClose}
                                                text={t('cancel')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                );
            }
            return ( <>
                {isChangePopupOpen && (
                    <div className="popup-overlay mobile">
                        <div className="popup-content">
                            <p
                                className={'popup-header'}
                            >{fields.type === 'Email' ? t('enterNewEmail') : t('enterNewPhoneNumber')}</p>
                            <div className={'popup-input-box'}>
                                {fields.type === 'Email' ?
                                    <TextInput
                                        hasError={(apiErrorMsg === 'Էլ. հասցեն արդեն գրանցված է' &&
                                            t('errors.usedEmail')) || errors['email']}
                                        onChange={handleChange.bind(this, 'changeEmail')}
                                        className='account-input black-input'
                                        placeholder={`${t('signInEmailPlaceholder')} *`}
                                    /> :
                                    <div
                                        style={{
                                            alignItems: 'flex-end',
                                            display: 'flex',
                                            marginTop: '-10px',
                                            flexDirection: 'column'}}>
                                        <PhoneInput
                                            inputProps={{
                                                disabled: true,
                                                style: { pointerEvents: 'none'}
                                            }}
                                            countryCodeEditable={false}
                                            containerClass='phoneContainer'
                                            inputClass={(errors["phoneNumber"] ||
                                                apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է')
                                                ?
                                                "phoneInputError" :
                                                "phone-edit"}
                                            buttonClass='flagButton'
                                            country={'am'}
                                            value=""
                                            onChange={handleChange.bind(this, "changePhoneCode")}
                                        />
                                        {apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ?
                                            <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                                            errors["phoneNumber"] ?
                                                <span className={'error-text'}>{errors["phoneNumber"]} *</span> : null}
                                        <input
                                            onInput={(e) => e.target.value =
                                                fields.changePhoneCode === undefined ||
                                                fields.changePhoneCode == '374' ? e.target.value.slice(0, 8) :
                                                    e.target.value.slice(0, 15)}
                                            ref={this.phoneNumberInputRef}
                                            value={fields['phoneNumber']}
                                            type="number"
                                            placeholder={fields['phoneCode'] == '374' ?
                                                `${t('signInPhoneNumberPlaceholder')} *` :
                                                `${t('phoneNumber')} *`}
                                            onChange={handleChange.bind(this, "changePhoneNumber")}
                                            className={errors["phoneNumber"] || apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ? 'phoneNumberInputError black':'phoneNumberInput black'}/>
                                    </div>}
                            </div>
                            <div
                                className='popup-button-container'>
                                <Button
                                    className="user-form-btn save"
                                    action={changeEmailPhone.bind(this)}
                                    text={t('save')}
                                />
                                <Button
                                    className="user-form-btn link"
                                    action={handlePopupClose}
                                    text={t('cancel')}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="user-right-header mobile">
                    <p className="user-additional">{t('profilePage')}</p>
                </div>
                <div className="user-additional-content mobile">
                    <PhoneEmailContainer
                        fields={fields}
                        handleChangePopupOpen={handleChangePopupOpen}
                        confirm={confirm}
                        registrationMethod={user.registeredMethod}
                    />
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('language')}</span>
                        <div className="user-input border-none">
                            <p>{+this.state.regLng === 1 ? <img src={ArmFlag}/> : <img src={UkFlag}/>}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('role')}</span>
                        <div className="user-input border-none">
                            <p>{t(`userType.${fields['speciality'].text.toLowerCase()}`)}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{`${t('userName')}, ${t('userLastName')}`}</span>
                        <div className="user-input border-none">
                            <p>{fields['firstName']} {fields['lastName']}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('gender')}</span>
                        <div className="user-input border-none">
                            <p>{fields["gender"]?.label}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('dateOfBirth')}</span>
                        <div className="user-input border-none">
                            <p>{this.handleformatDate(new Date(user.birthDate))}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('country')}</span>
                        <div className="user-input border-none">
                            <p>{fields["country"]?.label}</p>
                        </div>
                    </div>
                    <div className="user-detail-mobile">
                        <span className="user-detail-mobile-title">{t('region')}</span>
                        <div className="user-input border-none">
                            <p>{fields["region"]?.label}</p>
                        </div>
                    </div>
                    {states && states.length > 1 && (
                        <div className="user-detail-mobile">
                            <span className="user-detail-mobile-title">{t('commune')}</span>
                            <div className="user-input border-none">
                                <p>{fields["state"]?.label}</p>
                            </div>
                        </div>
                    )}

                    {user.speciality.text !== "Other" &&
                        <div className="user-detail-mobile">
                            <span className="user-detail-mobile-title">{t('school')}</span>
                            <div className="user-input border-none">
                                <p>{fields["school"]?.label || fields["school"]}</p>
                            </div>
                        </div>}
                    {user.speciality.text === "Other" &&
                        <div className="user-detail-mobile">
                            <span className="user-detail-mobile-title">{t('activity')}</span>
                            <div className="user-input border-none">
                                <p>{fields["occupation"]}</p>
                            </div>
                        </div>}

                    {
                        fields["country"]?.value === 11 &&
                        (
                            user.speciality.text === "Teacher" ? (
                                <div className="user-detail-mobile">
                                    <span className="user-detail-mobile-title">{t('subject')}</span>
                                    <div className="user-input border-none">
                                        <p>
                                            {fields["subject"]?.map((item, index) => {
                                                return (
                                                    <span key={index}>
                                {item.label}{" "}
                                                        {index < fields["subject"].length - 1 ? ", " : null}
                              </span>
                                                );
                                            })}
                                        </p>
                                    </div>
                                </div>
                            ) : user.speciality.text === "Student" ? (
                                <div className="user-detail-mobile">
                                    <span className="user-detail-mobile-title">{t('class')}</span>
                                    <div className="user-input border-none">
                                        <p>{fields["grade"]?.label}</p>
                                    </div>
                                </div>
                            ) : null
                        )
                    }
                    <div className="user-form-btns">
                        <Button
                            className="user-edit-btn link"
                            action={action}
                            text={t('edit')}
                        />
                    </div>
                    {isPopupOpen && (
                        <div className="popup-overlay mobile">
                            <div className="popup-content">
                                <p
                                    className={'popup-header'}
                                >{t('enterVerificationCode')}</p>
                                <div className={'popup-input-box'}>
                                    <TextInput
                                        hasError={confirmeError}
                                        onChange={handleChange.bind(this, 'code')}
                                        className='account-input black-input'
                                        placeholder={`${t('verificationCode')} *`}
                                    />
                                </div>
                                <div
                                    className='popup-button-container'>
                                    <Button
                                        className="user-form-btn save"
                                        action={change ? confirmChangeCode.bind(this) : confirmCode.bind(this)}
                                        text={t('save')}
                                    />
                                    <Button
                                        className="user-form-btn link"
                                        action={handlePopupClose}
                                        text={t('cancel')}
                                    />
                                </div>
                                <ResendCode
                                    action={change ? resendChangeCode : resendCode}
                                    black={true}/>
                            </div>
                        </div>
                    )}
                </div>
            </>)

        }
        if (edit) {
            return (
                <>
                    <div className="user-right-header">
                        <p className="user-additional">{t('profilePage')}</p>
                    </div>
                    <div className="user-additional-content">
                        <PhoneEmailContainer
                            fields={fields}
                            handleChangePopupOpen={handleChangePopupOpen}
                            confirm={confirm}
                            registrationMethod={user.registeredMethod}
                        />
                        {!fields.isEmailVerified &&
                            <div
                                onClick={() => confirm('Email')}
                                className={'notVerified'}>
                                {t('confirm')}
                            </div>}
                        <form>
                            <div
                                style={{margin: '40px 0 0'}}
                                className={'user-detail'}>
                                <div className="user-detail">
                                    <p>{+this.state.regLng === 1 ? <img src={ArmFlag}/> : <img src={UkFlag}/>}</p>
                                </div>
                            </div>
                            <span style={{margin: '40px 0 20px'}}
                                  className={'user-detail'}>
                              {t(`userType.${fields['speciality'].text.toLowerCase()}`)}
                          </span>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                <div
                                    style={{width: '48%'}}>
                                    <TextInput
                                        hasError={errors['firstName']}
                                        contentClassName={'input'}
                                        onChange={handleChange.bind(this, 'firstName')}
                                        className={errors['firstName'] ? 'big-input name red-border' : 'big-input name'}
                                        value={fields.firstName}
                                    />
                                </div>
                                <div
                                    style={{width: '48%', alignItems: 'flex-end'}}>
                                    <TextInput
                                        hasError={errors['lastName']}
                                        contentClassName={'input'}
                                        onChange={handleChange.bind(this, 'lastName')}
                                        className={errors['lastName'] ? 'big-input name red-border' : 'big-input name'}
                                        value={fields.lastName}
                                    />
                                </div>
                            </div>
                            <div className="user-detail">
                                <span>{t('gender')}</span>
                                <SelectBox
                                    hasError={errors["gender"]}
                                    placeholder={`${t('gender')} *`}
                                    contentClassName={`user-detail-input-change`}
                                    dropDownStyles={dropDownCustomAccountStyles}
                                    className={`user-input select`}
                                    options={genderOptions}
                                    onChange={(e) => {
                                        fields["gender"] = e;
                                        this.setState({fields: fields});
                                    }}
                                    value={fields["gender"] || ""}
                                />
                            </div>
                            <div className="user-detail">
                                <span>{t('dateOfBirth')}</span>
                                <MyDatePicker
                                    contentClassName={`user-input user-detail-input-change`}
                                    className={""}
                                    hasError={errors["birthDate"]}
                                    placeholder={`${t('dateOfBirth')} *`}
                                    onChange={(e) => {
                                        fields["birthDate"] = e;
                                        this.setState({fields});
                                    }}
                                    value={new Date(fields["birthDate"]) || null}
                                />
                            </div>
                            <div className="user-detail">
                                <span>{t('country')}</span>
                                <SelectBox
                                    hasError={errors["country"]}
                                    placeholder={`${t('country')} *`}
                                    contentClassName={`user-detail-input-change`}
                                    dropDownStyles={dropDownCustomAccountStyles}
                                    className={`user-input select`}
                                    options={countries}
                                    onChange={(e) => {
                                        fields["country"] = e;
                                        this.setState({fields: fields});
                                        handleSelectOptions("region");
                                    }}
                                    value={fields["country"] || ""}
                                />
                            </div>
                            <div className="user-detail">
                                <span>{t('region')}</span>
                                <SelectBox
                                    hasError={errors["region"]}
                                    placeholder={`${t('region')} *`}
                                    contentClassName={`user-detail-input-change`}
                                    dropDownStyles={dropDownCustomAccountStylesForRegions}
                                    className={`user-input select`}
                                    options={regions}
                                    onChange={(e) => {
                                        fields["region"] = e;
                                        this.setState({fields: fields});
                                        handleSelectOptions("state");
                                    }}
                                    value={fields["region"] || ""}
                                />
                            </div>
                            {states && states.length > 1 ? (
                                <div className="user-detail">
                                    <span>{t('commune')}</span>
                                    <SelectBox
                                        hasError={errors["state"]}
                                        placeholder={`${t('commune')} *`}
                                        contentClassName={`user-detail-input-change`}
                                        dropDownStyles={dropDownCustomAccountStylesForLittleScreens}
                                        className={`user-input select`}
                                        options={fields["region"] ? states : []}
                                        onChange={(e) => {
                                            fields["state"] = e;
                                            this.setState({fields: fields});
                                            handleSelectOptions("school");
                                        }}
                                        value={fields["state"] || ""}
                                    />
                                </div>
                            ) : null}

                            {user.speciality.text !== 'Other' &&
                                (fields['country']?.value === 11 && schools && schools.length ?
                                    <div className="user-detail">
                                        <span>{t('school')}</span>
                                        <SelectBox
                                            hasError={errors["school"]}
                                            placeholder={`${t('school')} *`}
                                            contentClassName={`user-detail-input-change`}
                                            dropDownStyles={dropDownCustomAccountStylesForLittleScreens}
                                            className={`user-input select`}
                                            options={fields["state"] ? schools : []}
                                            onChange={(e) => {
                                                fields["school"] = e;
                                                this.setState({fields: fields});
                                            }}
                                            value={fields["school"] || ""}
                                        />
                                    </div>
                                    : <div id={'school-other'} className="user-detail school-other">
                                        <span>{t('school')}</span>
                                        <TextInput
                                            contentClassName={`user-detail-input-change`}
                                            className={`big-input name`}
                                            hasError={errors["school"]}
                                            placeholder={`${t('school')} *`}
                                            onChange={handleChange.bind(this, "school")}
                                            value={fields["school"]?.label || fields["school"] || ""}
                                        />
                                    </div>)
                            }
                            {user.speciality.text === "Student" &&
                                fields['country']?.value === 11 && (
                                    <div className="user-detail">
                                        <span>{t('class')}</span>
                                        <SelectBox
                                            hasError={errors["grade"]}
                                            placeholder={`${t('class')} *`}
                                            contentClassName={`user-detail-input-change`}
                                            dropDownStyles={dropDownCustomAccountStylesForLittleScreens}
                                            className={`user-input select`}
                                            options={grades}
                                            onChange={(e) => {
                                                fields["grade"] = e;
                                                this.setState({fields: fields});
                                            }}
                                            value={fields["grade"] || ""}
                                        />
                                    </div>
                                )}
                            {user.speciality.text === "Teacher" &&
                                fields['country']?.value === 11 && (
                                    <div className="user-detail">
                                        <span>{t('subject')}</span>
                                        <SelectBox
                                            hasError={errors["subject"]}
                                            placeholder={`${t('subject')} *`}
                                            contentClassName={`user-detail-input-change`}
                                            dropDownStyles={dropDownCustomAccountStyles}
                                            className={`user-input select`}
                                            options={subjects}
                                            isMulti
                                            onChange={(e) => {
                                                fields["subject"] = e;
                                                this.setState({fields: fields});
                                            }}
                                            value={fields["subject"] || ""}
                                        />
                                    </div>
                                )}
                            {user.speciality.text === "Other" && (
                                <div className="user-detail">
                                    <span>{t('activity')}</span>
                                    <div
                                        style={{width: '65%', alignItems: 'flex-end'}}>
                                        <TextInput
                                            hasError={errors['occupation']}
                                            contentClassName={'input'}
                                            onChange={handleChange.bind(this, 'occupation')}
                                            className={errors['occupation'] ? 'big-input name red-border' : 'big-input name'}
                                            value={fields.occupation}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="user-form-btns">
                                <Button
                                    className="user-form-btn save"
                                    action={additionalSubmit.bind(this)}
                                    text={t('save')}
                                />
                                <Button
                                    className="user-form-btn link"
                                    action={cancelEditing}
                                    text={t('cancel')}
                                />
                            </div>
                        </form>
                        {isPopupOpen && (
                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <p
                                        className={'popup-header'}
                                    >{t('enterVerificationCode')}</p>
                                    <div
                                        style={{width: '80%'}}>
                                        <TextInput
                                            hasError={confirmeError}
                                            onChange={handleChange.bind(this, 'code')}
                                            className='account-input black-input'
                                            placeholder={`${t('verificationCode')} *`}
                                        />
                                    </div>
                                    <div
                                        className='popup-button-container'>
                                        <Button
                                            className="user-form-btn save"
                                            action={change ? confirmChangeCode.bind(this) : confirmCode.bind(this)}
                                            text={t('save')}
                                        />
                                        <Button
                                            className="user-form-btn link"
                                            action={handlePopupClose}
                                            text={t('cancel')}
                                        />
                                    </div>
                                    <ResendCode
                                        action={change ? resendChangeCode : resendCode}
                                        black={true}/>
                                </div>
                            </div>
                        )}
                        {isChangePopupOpen && (
                            <div className="popup-overlay">
                                <div className="popup-content">
                                    <p
                                        className={'popup-header'}
                                    >{fields.type === 'Email' ? t('enterNewEmail') : t('enterNewPhoneNumber')}</p>
                                    <div
                                        style={{width: '80%'}}>
                                        {fields.type === 'Email' ?
                                            <TextInput
                                            hasError={(apiErrorMsg === 'Էլ. հասցեն արդեն գրանցված է' &&
                                                t('errors.usedEmail')) || errors['email']}
                                            onChange={handleChange.bind (this, 'changeEmail')}
                                            className='account-input black-input'
                                            placeholder={`${t('email')} *`}
                                        /> :
                                            <div
                                                style={{
                                                    alignItems: 'flex-end',
                                                    display: 'flex',
                                                    marginTop: '-10px',
                                                    flexDirection: 'column'}}>
                                                <PhoneInput
                                                    inputProps={{
                                                        disabled: true,
                                                        style: { pointerEvents: 'none'}
                                                    }}
                                                    countryCodeEditable={false}
                                                    containerClass='phoneContainer'
                                                    inputClass={(errors["phoneNumber"] ||
                                                        apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է') ?
                                                        "phoneInputError" :
                                                        "phone-edit"}
                                                    buttonClass='flagButton'
                                                    country={'am'}
                                                    value=""
                                                    onChange={handleChange.bind(this, "changePhoneCode")}
                                                />
                                                {(apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ?
                                                    <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                                                    errors["phoneNumber"]) ?
                                                    <span className={'error-text'}>{errors["phoneNumber"]} *</span> : null}
                                                <input
                                                    onInput={(e) => e.target.value =
                                                        fields.changePhoneCode === undefined ||
                                                        fields.changePhoneCode == '374' ? e.target.value.slice(0, 8) :
                                                            e.target.value.slice(0, 15)}
                                                    ref={this.phoneNumberInputRef}
                                                    value={fields['phoneNumber']}
                                                    type="number"
                                                    placeholder={fields['phoneCode'] == '374' ?
                                                        `${t('signInPhoneNumberPlaceholder')} *` :
                                                        `${t('phoneNumber')} *`}
                                                    onChange={handleChange.bind(this, "changePhoneNumber")}
                                                    className={errors["phoneNumber"] || apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ? 'phoneNumberInputError black':'phoneNumberInput black'}/>
                                            </div>}
                                    </div>
                                    <div
                                        className='popup-button-container'>
                                        <Button
                                            className="user-form-btn save"
                                            action={changeEmailPhone.bind(this)}
                                            text="Պահպանել"
                                        />
                                        <Button
                                            className="user-form-btn link"
                                            action={handlePopupClose}
                                            text="Չեղարկել"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return (
            <>
                {isChangePopupOpen && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <p
                                className={'popup-header'}
                            >{fields.type === 'Email' ? t('enterNewEmail') : t('enterNewPhoneNumber')}</p>
                            <div
                                style={{width: '80%'}}>
                                {fields.type === 'Email' ?
                                    <TextInput
                                        hasError={(apiErrorMsg === 'Էլ. հասցեն արդեն գրանցված է' &&
                                            t('errors.usedEmail')) || errors['email']}
                                        onChange={handleChange.bind(this, 'changeEmail')}
                                        className='account-input black-input'
                                        placeholder={`${t("signInEmailPlaceholder")} *`}
                                    /> :
                                    <div
                                        style={{
                                            alignItems: 'flex-end',
                                            display: 'flex',
                                            marginTop: '-10px',
                                            flexDirection: 'column'}}>
                                        <PhoneInput
                                            inputProps={{
                                                disabled: true,
                                                style: { pointerEvents: 'none'}
                                            }}
                                            countryCodeEditable={false}
                                            containerClass='phoneContainer'
                                            inputClass={(errors["phoneNumber"] ||
                                            apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է')
                                                ?
                                                "phoneInputError" :
                                                "phone-edit"}
                                            buttonClass='flagButton'
                                            country={'am'}
                                            value=""
                                            onChange={handleChange.bind(this, "changePhoneCode")}
                                        />
                                        {apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ?
                                            <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                                            errors["phoneNumber"] ?
                                            <span className={'error-text'}>{errors["phoneNumber"]} *</span> : null}
                                        <input
                                            onInput={(e) => e.target.value =
                                                fields.changePhoneCode === undefined ||
                                                fields.changePhoneCode == '374' ? e.target.value.slice(0, 8) :
                                                    e.target.value.slice(0, 15)}
                                            ref={this.phoneNumberInputRef}
                                            value={fields['phoneNumber']}
                                            type="number"
                                            placeholder={`${t('signInPhoneNumberPlaceholder')} *`}
                                            onChange={handleChange.bind(this, "changePhoneNumber")}
                                            className={errors["phoneNumber"] || apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է' ? 'phoneNumberInputError black':'phoneNumberInput black'}/>
                                    </div>}
                            </div>
                            <div
                                className='popup-button-container'>
                                <Button
                                    className="user-form-btn save"
                                    action={changeEmailPhone.bind(this)}
                                    text={t('save')}
                                />
                                <Button
                                    className="user-form-btn link"
                                    action={handlePopupClose}
                                    text={t('cancel')}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="user-right-header">
                    <p className="user-additional">{t('profilePage')}</p>
                </div>
                <div className="user-additional-content">
                    <PhoneEmailContainer
                        fields={fields}
                        handleChangePopupOpen={handleChangePopupOpen}
                        confirm={confirm}
                        registrationMethod={user.registeredMethod}
                    />
                    <div
                        style={{margin: '40px 0 0'}}
                        className={'user-detail'}>
                        <span
                            className="user-detail-title">{t('language')}</span>
                        <div className="user-input border-none">
                            <p>{+this.state.regLng === 1 ? <img src={ArmFlag}/> : <img src={UkFlag}/>}</p>
                        </div>
                    </div>
                    <div
                        style={{margin: '40px 0 0'}}
                        className={'user-detail'}>
                        <span
                            className="user-detail-title">{t(`userType.${fields['speciality'].text.toLowerCase()}`)}</span>
                        <div className="user-input border-none">
                            <p> {fields['firstName']} {fields['lastName']}</p>
                        </div>
                    </div>
                    <div className="user-detail">
                        <span className="user-detail-title">{t('gender')}</span>
                        <div className="user-input border-none">
                            <p>{getTranslation(fields["gender"]?.value.toLowerCase(),getLocaleKey(this.state.regLng))}</p>
                        </div>
                    </div>
                    <div className="user-detail">
                        <span className="user-detail-title">{t('dateOfBirth')}</span>
                        <div className="user-input border-none">
                            <p>{this.handleformatDate(new Date(fields['birthDate']))}</p>
                        </div>
                    </div>
                    <div className="user-detail">
                        <span className="user-detail-title">{t('country')}</span>
                        <div className="user-input border-none">
                            <p>{fields["country"]?.label}</p>
                        </div>
                    </div>
                    <div className="user-detail">
                        <span className="user-detail-title">{t('region')}</span>
                        <div className="user-input border-none">
                            <p>{fields["region"]?.label}</p>
                        </div>
                    </div>
                    {states?.length > 1 && (
                        <div className="user-detail">
                            <span className="user-detail-title">{t('commune')}</span>
                            <div className="user-input border-none">
                                <p>{fields["state"]?.label}</p>
                            </div>
                        </div>
                    )}

                    {user.speciality.text !== "Other" &&
                        <div className="user-detail">
                            <span className="user-detail-title">{t('school')}</span>
                            <div className="user-input border-none">
                                <p>{fields["school"]?.label || fields["school"]}</p>
                            </div>
                        </div>}
                    {user.speciality.text === "Other" &&
                        <div className="user-detail">
                            <span className="user-detail-title">{t('activity')}</span>
                            <div className="user-input border-none">
                                <p>{fields["occupation"]}</p>
                            </div>
                        </div>}

                    {
                        fields["country"]?.value === 11 &&
                        (
                            user.speciality.text === "Teacher" ? (
                                <div className="user-detail">
                                    <span className="user-detail-title">{t('subject')}</span>
                                    <div className="user-input border-none">
                                        <p>
                                            {fields["subject"]?.map((item, index) => {
                                                return (
                                                    <span key={index}>
                                {item.label}{" "}
                                                        {index < fields["subject"].length - 1 ? ", " : null}
                              </span>
                                                );
                                            })}
                                        </p>
                                    </div>
                                </div>
                            ) : user.speciality.text === "Student" ? (
                                <div className="user-detail">
                                    <span className="user-detail-title">{t('class')}</span>
                                    <div className="user-input border-none">
                                        <p>{fields["grade"]?.label}</p>
                                    </div>
                                </div>
                            ) : null
                        )
                    }
                    <div className="user-form-btns">
                        <Button
                            className="user-edit-btn link"
                            action={action}
                            text={t('edit')}
                        />
                    </div>
                    {isPopupOpen && (
                        <div className="popup-overlay">
                            <div className="popup-content">
                                <p
                                    className={'popup-header'}
                                >{t('enterVerificationCode')}</p>
                                <div
                                    style={{width: '80%'}}>
                                    <TextInput
                                        hasError={confirmeError}
                                        onChange={handleChange.bind(this, 'code')}
                                        className='account-input black-input'
                                        placeholder={`${t('verificationCode')} *`}
                                    />
                                </div>
                                <div
                                    className='popup-button-container'>
                                    <Button
                                        className="user-form-btn save"
                                        action={change ? confirmChangeCode.bind(this) : confirmCode.bind(this)}
                                        text={t('save')}
                                    />
                                    <Button
                                        className="user-form-btn link"
                                        action={handlePopupClose}
                                        text={t('cancel')}
                                    />
                                </div>
                                <ResendCode
                                    action={change ? resendChangeCode : resendCode}
                                    black={true}/>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: getUserData(state),
    subjects: getSubjectsData(state),
    grades: getGradesData(state),
    countries: getCountriesData(state),
    states: getStatesData(state),
    regions: getRegionsData(state),
    schools: getSchoolsData(state),
});

const mapDispatchToProps = (dispatch) => ({
    getUserFromAPI: () => dispatch(getUserFromAPI()),
    setRegions: (val) => dispatch(setRegions(val)),
    setStates: (val) => dispatch(setStates(val)),
    setSchools: (val) => dispatch(setSchools(val)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(RightSideBar));
