import React from "react";

const CategoryFilterActive = () => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"  width="39px" height="45px" viewBox="0 0 39 45" version="1.1">
        <title>filter copy 2</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
          <g id="Seach-Filter" transform="translate(-3382.000000, -54.000000)" strokeWidth="3">
            <g id="filter-copy-2" transform="translate(3384.000000, 55.935002)">
              <path d="M30.9302327,0 C33.1686048,0 35,1.86392277 35,4.14205072 L35,8.6983071 C35,10.3551274 33.9825582,12.4261531 32.9651164,13.4616658 L24.2151144,21.3315596 C22.9941842,22.3670723 22.1802308,24.4381 22.1802308,26.0949203 L22.1802308,35.0003278 C22.1802308,36.242943 21.3662781,37.8997648 20.3488363,38.5210725 L17.5,40.3849945 C14.8546514,42.0418148 11.191859,40.1778904 11.191859,36.8642498 L11.191859,25.887817 C11.191859,24.4380993 10.3779071,22.5741756 9.56395365,21.5386629 L1.83139546,13.2545625 C0.813953646,12.2190498 0,10.355127 0,9.11251177 L0,4.34915306 C0,1.86392253 1.83139522,0 4.06976726,0 L30.9302327,0 Z" id="Vector" stroke="#2C476C" fill="#2C476C" fillRule="nonzero"/>
              <line x1="15.3226247" y1="0" x2="5.29064776" y2="16.3611005" id="Vector" stroke="#FFFFFF"/>
            </g>
          </g>
        </g>
      </svg>
  );
};
export default CategoryFilterActive;
