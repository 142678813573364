import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import hy from './hy.json';
import en from './en.json';

const resources = {
    hy: { translation: hy },
    en: { translation: en },
};

const storedLanguage = localStorage.getItem('language');
const defaultLanguage = storedLanguage || 'hy';

i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
        escapeValue: false,
    },
});

i18n.on('languageChanged', (lng) => {
    const urlParams = new URLSearchParams(window.location.search); // Parse the current URL parameters
    const hasScreenshotProp = urlParams.has('screenShot'); // Check if 'screenshot' exists in the URL

    if (!hasScreenshotProp) { // Only set language if 'screenshot' is NOT in the URL
        localStorage.setItem('language', lng);
    }
});

export default i18n;
