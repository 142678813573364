import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import SuccessCheck from "../../components/UI/SuccessCheck";
import {Button} from "../../components/UI/Button";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";

export const withNavigation = (Component: Component) => {
    return (props) => {
        return (
            <Component {...props} params={useParams()} navigate={useNavigate()} />
        );
    };
};

class EmailSend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signUp: null,
        };
    }
    componentDidMount() {
        let body = localStorage.getItem("signUpForm");
        if (JSON.parse(body) === null || JSON.parse(body) === undefined) {
            window.location.href = "/signup";
        }else{
            this.setState({
                signUp: JSON.parse(body),
            });
        }
    }
  render() {
        const {t} = this.props
    return (
      <Account onlyBG={true}>
        <div className="account-content-email-send">
          <SuccessCheck />
          <p className="check-text">{t('thankYou')}</p>
          <p className="check-text text-description">
              {t('successfullyRegistered')}<br/>
              {t('completeAccount')}
          </p>
            <div
                className={'complete-btn-box'}>
                <Button
                    action={() => this.props.navigate('/account-info')}
                    text={t('complete')}/>
            </div>
        </div>
      </Account>
    );
  }
}
export default withTranslation()(withNavigation(EmailSend))
