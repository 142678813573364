import {connect} from "react-redux";
import CloseDark from "../../CloseDark";
import './style.css'
const CloseBtn = (props) => {
    const { onClose = ()=>{}, styles= {} } = props
    return (
        <div style={styles} className="card-body__dynamic-close-btn-box dynamic-close-btn-box">
            <button className='dynamic__close-btn' style={{ height: 'max-content', maxHeight: '25px'}} onClick={onClose}>
                <CloseDark />
            </button>
        </div>
    )
}
export default connect(null, null)(CloseBtn);
