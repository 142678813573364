export const svgToURL = (svgString) => {
    const uri = window.btoa(unescape(encodeURIComponent(svgString)));
    return "data:image/svg+xml;base64," + uri;
}


export const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
}


export const updateSvgColors = (svgString, fillColor, strokeColor, width, height) => {
    if (!svgString) {
        return svgString;
    }
    // Use DOMParser to parse the SVG string into a document
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

   // update svg width and height by mapScale
    if (svgDoc.querySelector('svg')) {
        width && svgDoc.querySelector('svg').setAttribute('width', `${width}`);
        height && svgDoc.querySelector('svg').setAttribute('height', `${height}`);
        // svgDoc.querySelector('svg').setAttribute('viewBox', width / 6 + '-2 ' + width + ' ' + height);
        svgDoc.querySelector('svg').setAttribute('preserveAspectRatio', 'xMidYMid slice');
        svgDoc.querySelector('svg').setAttribute('overflow', 'visible');
    }

    // Get all elements that might have fill or stroke attributes
    const elements = svgDoc.querySelectorAll('[fill], [stroke]');

    elements.forEach(element => {
        // Update the fill color if it exists and is not 'none'
        if (element.hasAttribute('fill') && element.getAttribute('fill') !== 'none' && fillColor) {
            element.setAttribute('fill', fillColor);
        }

        // Update the stroke color if it exists and is not 'none'
        if (element.hasAttribute('stroke') && element.getAttribute('stroke') !== 'none' && strokeColor) {
            element.setAttribute('stroke', strokeColor);
        }

        if (element.tagName === 'path') {
            if (width) {
                // element.setAttribute('width', width);
            }

            if (height) {
                // element.setAttribute('height', height);
            }
        }



    });

    // Serialize the updated SVG document back into a string
    const serializer = new XMLSerializer();
    return serializer.serializeToString(svgDoc);
}

export const getShapeMarkerTransform = (e, targetRef, isEditMode) => {
    if (isEditMode) {
        return;
    }
    const transformString = e.drag.transform; // e.drag.transform is the complete transform string
    const { target } = e;

    // Parse the transform string
    const translateMatch = transformString.match(/translate\((-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px\)/);
    const rotateMatch = transformString.match(/rotate\((-?\d+(?:\.\d+)?)deg\)/);
    const scaleMatch = transformString.match(/scale\((-?\d+(?:\.\d+)?), (-?\d+(?:\.\d+)?)\)/);

    // Default values
    let translateX = 0;
    let translateY = 0;
    let rotate = 0;
    let scaleX = 1;
    let scaleY = 1;

    // Extract values if they exist
    if (translateMatch) {
        translateX = parseFloat(translateMatch[1]);
        translateY = parseFloat(translateMatch[2]);
    }
    if (rotateMatch) {
        rotate = parseFloat(rotateMatch[1]);
    }
    if (scaleMatch) {
        scaleX = parseFloat(scaleMatch[1]);
        scaleY = parseFloat(scaleMatch[2]);
    }

    // Calculate new scale (obtaining scale values from transform string directly)
    const newScaleX = scaleX; // Scale X from the drag transform
    const newScaleY = scaleY; // Scale Y from the drag transform

    // Calculate offsets to maintain center position
    const width = targetRef.offsetWidth * newScaleX; // Get new width based on scaling
    const height = targetRef.offsetHeight * newScaleY; // Get new height based on scaling

    const offsetX = (targetRef.offsetWidth * (newScaleX - scaleX)) / 2;
    const offsetY = (targetRef.offsetHeight * (newScaleY - scaleY)) / 2;

    // Construct the new transform string
    const newTransform = `translate(${offsetX}px, ${offsetY}px) rotate(${rotate}deg) scale(${newScaleX}, ${newScaleY})`;

    // Apply the new transform
    target.style.transform = newTransform;

    // Call the onTransform callback if exists
    return newTransform;
};


export const rgbaToHexAndOpacity = (color) => {
    // Regex for rgba/rgb
    const rgbaRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,?\s*(0|1|0?\.\d+)?\s*\)$/;
    // Regex for hex
    const hexRegex = /^#?([a-fA-F0-9]{6})$/;

    const rgbaResult = rgbaRegex.exec(color);
    const hexResult = hexRegex.exec(color);

    if (rgbaResult) {
        // Parse RGBA components
        const r = parseInt(rgbaResult[1]);
        const g = parseInt(rgbaResult[2]);
        const b = parseInt(rgbaResult[3]);
        const a = rgbaResult[4] !== undefined ? parseFloat(rgbaResult[4]) : 1;

        if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
            throw new Error("RGB values must be in the range 0-255.");
        }

        const toHex = (component) => component.toString(16).padStart(2, '0').toUpperCase();
        const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

        return { hex, opacity: a };
    } else if (hexResult) {
        // Return the hex color with default opacity of 1
        return { hex: `#${hexResult[1].toUpperCase()}`, opacity: 1 };
    } else {
        return { hex: "#95d4f3", opacity: 1 };
    }
}


export const getSvgChildrenDimensions = (svgString) => {
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.style.position = 'absolute';
    svgElement.style.visibility = 'hidden';

    svgElement.innerHTML = svgString;

    document.body.appendChild(svgElement);

    // Initialize our bounding box
    const bbox = { x: Infinity, y: Infinity, width: 0, height: 0 };

    // Get all child nodes (e.g., paths, groups)
    const childElements = svgElement.querySelectorAll('*');

    // Calculate bounding box for each child element
    childElements.forEach(element => {
        if (element.tagName.toLowerCase() !== 'svg' && element.tagName.toLowerCase() !== 'rect' && typeof element.getBBox === 'function') {
            const elBbox = element.getBBox();

            // Update our bounding box only if we have a valid bbox
            if (elBbox) {
                bbox.x = Math.min(bbox.x, elBbox.x);
                bbox.y = Math.min(bbox.y, elBbox.y);
                bbox.width = Math.max(bbox.width, elBbox.x + elBbox.width - bbox.x);
                bbox.height = Math.max(bbox.height, elBbox.y + elBbox.height - bbox.y);
            }
        }
    });
    // Clean up the temporary element
    document.body.removeChild(svgElement);

    // Return dimensions and aspect ratio
    return {
        width: bbox.width,
        height: bbox.height,
        aspectRatio: bbox.width / bbox.height
    };
}


export const replaceTranslate = (transform) => {
    return transform.replace(/translate\([^,]+, [^)]+\)/, 'translate(-50%, -50%)');
}

export const replaceMatrix3d = (transform) => {
    return transform.replace(/matrix3d\([^)]+\)/, 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)');
}

export const replaceShapeTransform = (transform) => {
    return replaceTranslate(replaceMatrix3d(transform))
}

export const getTransformRotate = (transform) => {
// Match the rotate function and extract the angle value
    const rotateMatch = transform.match(/rotate\(([^)]+)\)/);

    // If rotate is found, parse and return the angle
    if (rotateMatch) {
        return parseFloat(rotateMatch[1]);
    }

    // If no rotation is found, return 0 or handle as needed
    return 0;
}

export const calculatePixelSizeFromMeters = (marker, meters, map) => {
    const centerCoordinates = [marker.getLngLat().lng, marker.getLngLat().lat];
    const centerPixel = map.project(centerCoordinates);

    // Use trigonometry to calculate the adjusted latitude for the distance in the map's current rotation
    const bearing = map.getBearing() * (Math.PI / 180); // Convert bearing to radians
    const deltaLat = (meters / 111320) * Math.cos(bearing);
    const deltaLng = (meters / (111320 * Math.cos(centerCoordinates[1] * (Math.PI / 180)))) * Math.sin(bearing);

    const adjustedCoordinates = [centerCoordinates[0] + deltaLng, centerCoordinates[1] + deltaLat];
    const adjustedPixel = map.project(adjustedCoordinates);

    return Math.abs(adjustedPixel.y - centerPixel.y);
};


export const getLngLatValue = (mapZoomLevel) => {
    const value = mapZoomLevel >= 4 ? 1 : mapZoomLevel < 4 && mapZoomLevel >= 3 ? 5 : 8;
    return getRandomNumber(value / mapZoomLevel, value / mapZoomLevel +  value/ mapZoomLevel)
}


export const getTransformedDimensions = (width, height, angle) => {
    const angleRad = (angle * Math.PI) / 180;
    const cosAngle = Math.abs(Math.cos(angleRad));
    const sinAngle = Math.abs(Math.sin(angleRad));

    const newWidth = width * cosAngle + height * sinAngle;
    const newHeight = width * sinAngle + height * cosAngle;

    return { newWidth, newHeight };
}

export const getDemiDimensions = (width, height, scale) => {
    const xScale = scale[0];
    const yScale = scale[1];

    const newWidth = width * xScale;
    const newHeight = height * yScale;
    return { newWidth, newHeight };
}

export const parseToFloat = (value) => {
    // Check if the value is a string and not null or undefined
    if (typeof value !== 'string') return NaN;

    // Trim whitespace and use regex to extract the numeric part
    const match = value.trim().match(/-?\d+(\.\d+)?/);

    // If there's a match, parse it to float; otherwise, return NaN
    return match ? parseFloat(match[0]) : NaN;
}


export const getRealSize = (element) => {
    if (!element) {
        return {
            newWidth: 0,
            newHeight: 0
        };
    }
    // Get the computed style and transform matrix of the element
    const computedStyle = window.getComputedStyle(element);
    const transformMatrix = computedStyle.transform;

    // If no transform is applied, return the offset size directly
    if (transformMatrix === 'none') {
        return {
            newWidth: element.offsetWidth,
            newHeight: element.offsetHeight
        };
    }

    // Parse the transform matrix (expected format: matrix(a, b, c, d, e, f))
    const matrixValues = transformMatrix.match(/matrix\(([^)]+)\)/)[1].split(', ');
    const a = parseFloat(matrixValues[0]);
    const b = parseFloat(matrixValues[1]);
    const c = parseFloat(matrixValues[2]);
    const d = parseFloat(matrixValues[3]);

    // Calculate the scale factors
    const scaleX = Math.sqrt(a * a + c * c);
    const scaleY = Math.sqrt(b * b + d * d);

    // Calculate the real size by applying the scale factors to the natural size
    const realWidth = element.offsetWidth / scaleX;
    const realHeight = element.offsetHeight / scaleY;

    return {
        newWidth: realWidth,
        newHeight: realHeight
    };
}

export const isLightColor = (hexColor)  => {
    // Remove the "#" if present

    if (!hexColor) {
        hexColor = '#95d4f3'
    }
    hexColor = hexColor.replace('#', '');

    // Convert shorthand hex to full hex (e.g., "abc" to "aabbcc")
    if (hexColor.length === 3) {
        hexColor = hexColor.split('').map(char => char + char).join('');
    }

    // Extract the red, green, and blue components
    const red = parseInt(hexColor.slice(0, 2), 16);
    const green = parseInt(hexColor.slice(2, 4), 16);
    const blue = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the luminance
    const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

    // Define a threshold for light colors (using 128 as a mid-point threshold)
    return luminance > 230;
}

export const ifHasAlpha = (color) => {
    return color && color.indexOf("rgba") !== -1 && color.split(',')[3] !== '1)'
}

export const getScaleValuesFromTransform = (transform) => {
    if (!transform) {
        return [1, 1];
    }
    const scaleValues = transform.match(/scale\(([^)]+)\)/)[1].split(', ');
    return scaleValues;
}
