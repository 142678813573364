const TimeLineExtend = ({ expandLevel }) => {
  return expandLevel === 1
      ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="31" viewBox="0 0 22 31" fill="none">
              <path d="M2 12L11 2L20 12" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2 29L11 19L20 29" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ) : expandLevel === 2
      ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="31" viewBox="0 0 22 31" fill="none">
                  <path d="M2 12L11 2L20 12" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M20 19.0001L11 29.0001L2 19.0001" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
        )
      :(
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="31" viewBox="0 0 22 31" fill="none">
              <path d="M20 19L11 29L2.00002 19" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M20 2L11 12L2.00002 2" stroke="#FEFEFE" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      );
};
export default TimeLineExtend;
