import React from "react";

const PresentationSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
            <g clipPath="url(#clip0_607_15691)">
                <path d="M50.5585 2.40586H28.9059C28.9059 1.07718 27.8289 0 26.5002 0C25.1715 0 24.0943 1.07718 24.0943 2.40586H2.44175C1.11306 2.40586 0.0358887 3.48304 0.0358887 4.81173V7.21759C0.0358887 8.54628 1.11306 9.62345 2.44175 9.62345V38.4935C2.44175 39.8222 3.51893 40.8994 4.84761 40.8994H25.2972V44.0149L18.4285 50.8836C17.9239 51.3157 17.8651 52.0751 18.2973 52.5797C18.7294 53.0843 19.4888 53.1431 19.9934 52.7109C20.0404 52.6705 20.0844 52.6267 20.1246 52.5797L26.5001 46.2042L32.8635 52.5676C33.2956 53.0722 34.055 53.131 34.5595 52.6988C35.0641 52.2667 35.1229 51.5073 34.6907 51.0027C34.6504 50.9556 34.6066 50.9117 34.5595 50.8715L27.7029 44.0149V40.8994H48.1525C49.4812 40.8994 50.5584 39.8222 50.5584 38.4935V9.62335C51.8871 9.62335 52.9642 8.54617 52.9642 7.21749V4.81162C52.9642 3.48293 51.8872 2.40586 50.5585 2.40586ZM48.1526 38.4934H4.84761V9.62335H48.1526V38.4934ZM50.5585 7.21749H2.44175V4.81162H50.5585V7.21749Z" fill="#2c476c"/>
            </g>
            <defs>
                <clipPath id="clip0_607_15691">
                    <rect width="53" height="53" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
export default PresentationSVG;
