import Popup from "../Popup";
import './style.css'
import NoLanguage from "../../../assets/imgs/no_language";
const NoLanguageContent = ({title}) => {
    const toHomePage = () => {
        window.location.href = "/account-boards";
    }
    return (
        <Popup
            isOpened={true}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
        >
            <div
                className="no-language-content"
            >
                <div className={"no-language-content-title"}>
                     <span className={'no-language-icon'}>
                       <NoLanguage size={64}/>
                    </span>
                </div>
                <div className={"no-language-content-description"}>
                    <h3>{title}</h3>
                </div>
                <div>
                    <button
                        className="no-language-content-btn"
                        onClick={toHomePage}
                    >
                        OK
                    </button>
                </div>
            </div>
        </Popup>
    );
};
export  default NoLanguageContent